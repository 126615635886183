import AuthRepository from "./AuthRepository";
import BlogsRespository from "./BlogsRespository";
import CategoriesRepository from "./CategoriesRepository";
import CertificationRepository from "./CertificationRepository";
import ChatRepository from "./ChatRepository";
import DashboardRepository from "./DashboardRepository";
import DisputesRepository from "./DisputesRepository";
import OrderRepository from "./OrderRepository";
import PolicyRepository from "./PolicyRepository";
import ReportRepository from "./ReportRepository";
import ResolutionTeamRespository from "./ResolutionTeamRespository";
import SupportPersonRespository from "./SupportPersonRespository";
import TicketsRespository from "./TicketsRespository";
import UsersRepository from "./UsersRepository";
import deletedJobRepository from "./deletedJobRepository";
import taxRepository from "./taxRepository";
import adminsRepository from "./AdminRepository";
import CheckrReportRepository from "./CheckrReportRepository";
import HelpArticleRepository from "./HelpArticleRepository";
import SkillsRepository from "./SkillsRepository";
import NameRequestRepository from "./NameRequestRepository";

const repositories = {
  auth: AuthRepository,
  support: SupportPersonRespository,
  resolution: ResolutionTeamRespository,
  blogs: BlogsRespository,
  users: UsersRepository,
  stats: DashboardRepository,
  tickets: TicketsRespository,
  policy: PolicyRepository,
  category: CategoriesRepository,
  certification: CertificationRepository,
  tax: taxRepository,
  orders: OrderRepository,
  disputes: DisputesRepository,
  reports: ReportRepository,
  chats: ChatRepository,
  deletedJobs: deletedJobRepository,
  admins: adminsRepository,
  checkrReports: CheckrReportRepository,
  helpArticles: HelpArticleRepository,
  skills: SkillsRepository,
  nameRequests: NameRequestRepository,
};

export const RepositoryFactory = {
  get: (name) => repositories[name],
};

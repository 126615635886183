import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const checkrReports = RepositoryFactory.get("checkrReports");

export const getAllCheckrReports = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "CHECKR_REPORTS_LOADER",
      payload: true,
    });
    const { data } = await checkrReports.getReports(page, limit);
    // console.log("Hamza test checkr", data);

    dispatch({
      type: "GET_CHECKR_REPORTS",
      payload: data,
    });
    dispatch({
      type: "CHECKR_REPORTS_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "CHECKR_REPORTS_LOADER",
      payload: false,
    });
  }
};

export const engagedCheckrReport = (userId) => async (dispatch) => {
  try {
    dispatch({
      type: "ENGAGED_CHECKR_REPORT_LOADER",
      payload: true,
    });
    const { data } = await checkrReports.engagedCheckrReport(userId);

    if (data?.success) {
      dispatch(getAllCheckrReports(1, 10));
    }

    dispatch({
      type: "ENGAGED_CHECKR_REPORT_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "ENGAGED_CHECKR_REPORT_LOADER",
      payload: false,
    });
  }
};

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const blogs = RepositoryFactory.get("blogs");

export const getAllBlogs = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: "GET_BLOGS_LOADER", payload: true });
    const { data } = await blogs.getBlogs(page, limit);

    dispatch({
      type: "GET_BLOGS",
      payload: data.data,
    });
    dispatch({ type: "GET_BLOGS_LOADER", payload: false });
  } catch (err) {
    dispatch({ type: "GET_BLOGS_LOADER", payload: false });

    toast.error(err.response.data.message);
  }
};

export const BlogSearchByTitleAction = (title, page) => async (dispatch) => {
  console.log("BlogSearchByTitleAction_title: ", title);
  try {
    dispatch({
      type: "GET_BLOGS_LOADER",
      payload: true,
    });
    const { data } = await blogs.blogSearchByTitle(title, page);

    if (data?.success) {
      dispatch({
        type: "GET_BLOG_SEARCH_BY_Title",
        payload: data.data,
      });
      dispatch({
        type: "GET_BLOGS_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    // toast.error(error.response.data.message);
    dispatch({
      type: "GET_BLOGS_LOADER",
      payload: false,
    });
  }
};

export const getSingleBlogs = (id) => async (dispatch) => {
  try {
    const { data } = await blogs.singleBlog(id);

    dispatch({
      type: "GET_SINGLE_BLOG",
      payload: data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const deleteBlog =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_BLOG_LOADER",
        payload: true,
      });
      const { data } = await blogs.deleteBlog(id);

      dispatch({
        type: "DELETE_BLOG_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "BLOG_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "DELETE_BLOG_LOADER",
        payload: false,
      });
    }
  };
export const addNewBlog =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_BLOG_LOADER",
        payload: true,
      });

      const { data } = await blogs.addBlog(payload);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "ADD_BLOG_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "ADD_BLOG_LOADER",
        payload: false,
      });
    }
  };

export const updateBlog =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log("id, payload: ", id, payload);
    try {
      dispatch({
        type: "UPDATE_BLOG_LOADER",
        payload: true,
      });

      const { data } = await blogs.updateBlog(id, payload);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "UPDATE_BLOG_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "UPDATE_BLOG_LOADER",
        payload: false,
      });
    }
  };

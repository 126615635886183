import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const users = RepositoryFactory.get("users");

export const getUsers = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: "GET_USERS_LOADER", payload: true });

    const { data } = await users.getAllUsers(page, limit);
    dispatch({
      type: "GET_USERS",
      payload: data.data,
    });
    dispatch({ type: "GET_USERS_LOADER", payload: false });
  } catch (err) {
    toast.error(err.response.data.message);

    dispatch({ type: "GET_USERS_LOADER", payload: false });
  }
};

export const userSearch = (query, page) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_USERS_LOADER",
      payload: true,
    });
    const { data } = await users.searchUser(query, page);
    if (data?.success) {
      dispatch({
        type: "USER_SEARCH",
        payload: data.data,
      });
      dispatch({
        type: "GET_USERS_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    // toast.error(error.response.data.message);
    dispatch({
      type: "GET_USERS_LOADER",
      payload: false,
    });
  }
};

export const userSearchSupportChat =
  (data, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "GET_USERS_LOADER",
        payload: true,
      });
      const response = await users.searchUserInSupportChat(data);
      if (response.data?.success) {
        dispatch({
          type: "GET_USERS_SUPPORT_CHAT",
          payload: response.data,
        });
        dispatch({
          type: "GET_USERS_LOADER",
          payload: false,
        });
      }
      onSuccess();
    } catch (error) {
      dispatch({
        type: "GET_USERS_LOADER",
        payload: false,
      });
      onSuccess();
    }
  };
export const updateserSearchSupportChat = () => async (dispatch) => {
  try {
    console.log("entered");
    dispatch({
      type: "REMOVE_USERS_SUPPORT_CHAT",
      payload: null,
    });
  } catch (error) {}
};
export const getSingleUserAction = (id) => async (dispatch) => {
  try {
    const { data } = await users.getSingleUser(id);

    dispatch({
      type: "GET_SINGLE_USER",
      payload: data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
export const suspendUserAccount =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: "ACCOUNT_SUSPENDED", payload: true });
      const formattedPayload = {
        suspendAccountTill: payload,
      };

      const { data } = await users.suspendUser(id, formattedPayload);
      toast.success(data?.message);
      onSuccess();
      dispatch({ type: "ACCOUNT_SUSPENDED", payload: false });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({ type: "ACCOUNT_SUSPENDED", payload: false });
    }
  };
export const activateUserAccount =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      let formatPayload = {
        status: payload,
      };
      const { data } = await users.activateUser(id, formatPayload);
      toast.success(data?.message);
      onSuccess();

      dispatch({
        type: "ACC_ACTIVATED",
        payload: {
          data: data?.data,
          id: id,
        },
      });
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

export const accountPasswordUpdate =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: "PASSWORD_UPDATE_LOADER", payload: true });

      const { data } = await users.updatePassword({
        oldPassword: payload.currentPassword,
        password: payload.newPassword,
      });

      toast.success(data?.message);
      onSuccess();
      dispatch({ type: "PASSWORD_UPDATE_LOADER", payload: false });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({ type: "PASSWORD_UPDATE_LOADER", payload: false });
    }
  };

export const exportUsers =
  (payload, onSuccess, onError) => async (dispatch) => {
    try {
      const { data } = await users.exportUsers(payload);
      onSuccess(data);
    } catch (err) {
      onError();
      toast.error(err.response.message);
    }
  };

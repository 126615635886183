import Repository from "./Repository";
const GET = "/policy/all";
const DECISION = "/policy/decision/";
export default {
  getPolicies(page, limit) {
    return Repository.get(`${GET}?page=${page}&limit=${limit}`);
  },
  PolicyDecision(id, payload) {
    return Repository.post(`${DECISION}${id}`, payload);
  },
};

const initState = {
  allHelpArticles: [],
  singleHelpArticle: {},
  getHelpArticlesLoader: false,
  addHelpArticlesLoader: false,
  updateHelpArticlesLoader: false,
  deleteHelpArticlesLoader: false,
};

const helpArticlesReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_HELP_ARTICLES":
      return {
        ...state,
        allHelpArticles: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_SINGLE_HELP_ARTICLE": {
      return {
        ...state,
        singleHelpArticle: payload,
      };
    }
    case "GET_HELP_ARTICLES_LOADER":
      return {
        ...state,
        getHelpArticlesLoader: payload,
      };
    case "ADD_HELP_ARTICLES_LOADER":
      return {
        ...state,
        addHelpArticlesLoader: payload,
      };
    case "UPDATE_HELP_ARTICLES_LOADER":
      return {
        ...state,
        updateHelpArticlesLoader: payload,
      };
    case "DELETE_HELP_ARTICLES_LOADER":
      return {
        ...state,
        deleteHelpArticlesLoader: payload,
      };
    case "HELP_ARTICLE_DELETED":
      return {
        ...state,
        allHelpArticles: state.allHelpArticles?.filter(
          (article) => article.id !== payload
        ),
      };
    case "SEARCH_HELP_ARTICLE_BY_Title":
      return {
        ...state,
        allHelpArticles: payload.data,
        totalPages: payload?.totalPages,
      };
    default:
      return {
        ...state,
      };
  }
};
export default helpArticlesReducer;

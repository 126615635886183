import { useState } from "react";
import { Link, NavLink as NavLinkRRD } from "react-router-dom";
// nodejs library to set properties for components
import { PropTypes } from "prop-types";

// reactstrap components
import {
  Col,
  Collapse,
  Container,
  DropdownItem,
  DropdownMenu,
  Nav,
  NavItem,
  NavLink,
  Navbar,
  Row,
  UncontrolledDropdown,
} from "reactstrap";
import { logout } from "store/actions/authAction";
import { useDispatch, useSelector } from "react-redux";

var ps;

const Sidebar = (props) => {
  const [collapseOpen, setCollapseOpen] = useState();
  // verifies if routeName is the one active (in browser input)
  const activeRoute = (routeName) => {
    return props.location.pathname.indexOf(routeName) > -1 ? "active" : "";
  };
  // toggles collapse between opened and closed (true/false)
  const toggleCollapse = () => {
    setCollapseOpen((data) => !data);
  };
  // closes the collapse
  const closeCollapse = () => {
    setCollapseOpen(false);
  };
  // creates the links that appear in the left menu / Sidebar
  // const createLinks = (routes) => {
  //   return routes.map((prop, key) => {
  //     if (prop.isMenu != false)
  //       return (
  //         <NavItem key={key}>
  //           <NavLink
  //             to={prop.layout + prop.path}
  //             tag={NavLinkRRD}
  //             onClick={closeCollapse}
  //             activeClassName="active"
  //           >
  //             <i className={`sidebarIcon   ${prop.icon}`} />
  //             {prop.name}
  //           </NavLink>
  //         </NavItem>
  //       );
  //   });
  // };

  const { user } = useSelector((state) => state.auth);
  const createLinks = (routes) => {
    return routes.map((prop, key) => {
      // Check if the user's role allows displaying this link
      if (
        (user?.role === "resolutionTeam" &&
          ["Dashboard", "Orders", "Resolution"].includes(prop.name)) ||
        (user?.role === "supportPerson" &&
          ["Support Team", "Dashboard", "Tickets"].includes(prop.name)) ||
        (user?.role !== "resolutionTeam" && user?.role !== "supportPerson")
      ) {
        if (prop.isMenu !== false) {
          return (
            <NavItem key={key}>
              <NavLink
                to={prop.layout + prop.path}
                tag={NavLinkRRD}
                onClick={closeCollapse}
                activeClassName="active"
              >
                <i className={`sidebarIcon   ${prop.icon}`} />
                {prop.name}
              </NavLink>
            </NavItem>
          );
        }
      }
      return null;
    });
  };

  const { bgColor, routes, logo } = props;
  let navbarBrandProps;
  if (logo && logo.innerLink) {
    navbarBrandProps = {
      to: logo.innerLink,
      tag: Link,
    };
  } else if (logo && logo.outterLink) {
    navbarBrandProps = {
      href: logo.outterLink,
      target: "_blank",
    };
  }
  const dispatch = useDispatch();
  return (
    <Navbar
      className="navbar-vertical fixed-left navbar-light bg-white main-sidebar"
      expand="md"
      id="sidenav-main"
    >
      <Container fluid>
        {/* Toggler */}
        <div className="d-flex justify-content-end">
          <button
            className="navbar-toggler "
            type="button"
            onClick={toggleCollapse}
          >
            <span className="navbar-toggler-icon" />
          </button>
        </div>
        {/* Brand */}
        {logo ? (
          <img
            className="tealpotLogo"
            alt="..."
            src={require("../../assets/img/icons/common/tealpot.png").default}
          />
        ) : null}
        {/* User */}
        <Nav className="align-items-center d-md-none">
          <UncontrolledDropdown nav>
            <DropdownMenu className="dropdown-menu-arrow" right>
              <DropdownItem
                onClick={() => {
                  dispatch(logout());
                }}
              >
                <i className="ni ni-user-run" />
                <span>Logout</span>
              </DropdownItem>
            </DropdownMenu>
          </UncontrolledDropdown>
        </Nav>
        {/* Collapse */}
        <Collapse navbar isOpen={collapseOpen}>
          {/* Collapse header */}

          <div className="navbar-collapse-header d-md-none">
            <div className="d-flex justify-content-end align-items-right ">
              <Col className="collapse-close" xs="6">
                <button
                  className="navbar-toggler"
                  type="button"
                  onClick={toggleCollapse}
                >
                  <span />
                  <span />
                </button>
              </Col>
            </div>
            <Row className="align-items-center justify-content-between d-flex mx-2">
              <div>
                {logo ? (
                  <img
                    className="tealpotLogombl"
                    alt="..."
                    src={
                      require("../../assets/img/icons/common/tealpot.png")
                        .default
                    }
                  />
                ) : null}
              </div>
              <div
                onClick={() => {
                  dispatch(logout());
                }}
              >
                Logout
              </div>
            </Row>
          </div>

          <Nav navbar>{createLinks(routes)}</Nav>
        </Collapse>
      </Container>
    </Navbar>
  );
};

Sidebar.defaultProps = {
  routes: [{}],
};

Sidebar.propTypes = {
  // links that will be displayed inside the component
  routes: PropTypes.arrayOf(PropTypes.object),
  logo: PropTypes.shape({
    // innerLink is for links that will direct the user within the app
    // it will be rendered as <Link to="...">...</Link> tag
    innerLink: PropTypes.string,
    // outterLink is for links that will direct the user outside the app
    // it will be rendered as simple <a href="...">...</a> tag
    outterLink: PropTypes.string,
    // the image src of the logo
    imgSrc: PropTypes.string.isRequired,
    // the alt for the img
    imgAlt: PropTypes.string.isRequired,
  }),
};

export default Sidebar;

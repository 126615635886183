import { addUserSocket } from "config/socketConfig";
import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const authRepo = RepositoryFactory.get("auth");

export const login =
  (payload, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "LOGIN_LOADER",
        payload: true,
      });
      const { data } = await authRepo.Login(payload);
      console.log("data: ", data);

      if (data?.success) {
        addUserSocket(data?.data?.id, data?.data?.role);
        dispatch({
          type: "LOGIN_SUCCESS",
          payload: {
            data: data?.data,
            id: data?.data?.id,
            token: data?.data?.token,
          },
        });
        dispatch({
          type: "EMAIL",
          payload: {
            email: data?.data?.email,
          },
        });
        dispatch({
          type: "LOGIN_LOADER",
          payload: false,
        });

        onSuccess();
      }
    } catch (err) {
      console.log("err: ", err);
      toast.error(err.response.data.message);
      dispatch({
        type: "LOGIN_LOADER",
        payload: false,
      });
      onError();
    }
  };

export const VerifyEmailAction =
  (token, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: "VERIFY_EMAIL_LOADER",
      payload: true,
    });
    try {
      const { data } = await authRepo.verifyEmail({ token: token });
      console.log("data: ", data);

      if (data?.success) {
        toast.success(data?.message);
      }
      dispatch({
        type: "VERIFY_EMAIL_LOADER",
        payload: false,
      });
      dispatch({
        type: "EMAIL",
        payload: {
          email: data?.data?.email,
        },
      });
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "VERIFY_EMAIL_LOADER",
        payload: false,
      });

      onError();
    }
  };

export const updateEmailAction =
  (email, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: "UPDATE_EMAIL_LOADER",
      payload: true,
    });

    try {
      const { data } = await authRepo.updateEmail({ email: email });
      if (data?.success) {
        toast.success(data?.message);

        dispatch({
          type: "EMAIL_UPDATE_SUCCESS",
        });

        dispatch({
          type: "LOGOUT",
        });

        dispatch({
          type: "UPDATE_EMAIL_LOADER",
          payload: email,
        });
        onSuccess();
      }
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "UPDATE_EMAIL_LOADER",
        payload: false,
      });

      onError();
    }
  };

export const forgetPasswordAction =
  (email, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "FORGET_PASSWORD_LOADER",
        payload: true,
      });

      const { data } = await authRepo.forgetPassword({ email: email });
      console.log("data: ", data);
      if (data?.success) {
        toast.success(data?.message);
      }
      dispatch({
        type: "FORGET_PASSWORD_LOADER",
        payload: false,
      });
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "FORGET_PASSWORD_LOADER",
        payload: false,
      });

      onError();
    }
  };

export const VerifyPasswordAction =
  (token, password, onSuccess = () => {}, onError = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: "VERIFY_PASSWORD_LOADER",
      payload: true,
    });

    let payload = {
      token: token,
      password: password,
    };
    try {
      const { data } = await authRepo.verifyPassword(payload);
      console.log("data: ", data);

      if (data?.success) {
        toast.success(data?.message);
      }
      dispatch({
        type: "VERIFY_PASSWORD_LOADER",
        payload: false,
      });
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "VERIFY_PASSWORD_LOADER",
        payload: false,
      });

      onError();
    }
  };
export const logout = () => async (dispatch) => {
  localStorage.clear();
  dispatch({
    type: "LOGOUT",
  });
};

import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { deleteResolution } from "store/actions/resolutionAction";

const DeleteResolutionModal = ({ id, modal, toggle }) => {
  console.log("id: ", id);
  const { deleteResolutionLoader } = useSelector((state) => state.resolution);
  const dispatch = useDispatch();
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Delete Team</ModalHeader>
        <ModalBody>Are you sure you want to delete ?</ModalBody>
        <ModalFooter>
          <Button
            color="danger"
            disabled={deleteResolutionLoader}
            onClick={() => {
              dispatch(
                deleteResolution(id, () => {
                  toggle();
                })
              );
            }}
          >
            {deleteResolutionLoader ? <Spinner size="sm" /> : "Delete"}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteResolutionModal;

import DisputesTab from "components/DisputesTab";
import OnlyHeader from "components/Headers/OnlyHeader";
import RefundToBuyerModal from "components/Modals/disputesModal/RefundToBuyerModal";
import ReleaseSellerAmountModal from "components/Modals/disputesModal/ReleaseSellerAmountModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardHeader, Container, Row, Spinner } from "reactstrap";
import { getDisputeChat } from "store/actions/disputesAction";
import { getSingleDispute } from "store/actions/disputesAction";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const DisputesDetails = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();
  const { singleDispute, getDisputesLoader, disputeChat } = useSelector(
    (state) => state.disputes
  );
  const [page, setPage] = useState(1);
  const [dispute, setDispute] = useState(null);
  const [releaseSellerAmount, setReleaseSellerAmount] = useState(false);
  const [refundToBuyer, setRefundToBuyer] = useState(false);

  useEffect(() => {
    dispatch(getSingleDispute(id));
  }, [id, dispatch]);
  useEffect(() => {
    if (singleDispute) {
      setDispute(singleDispute);
    }
  }, [singleDispute]);
  useEffect(() => {
    if (singleDispute) {
      let payload = {
        page: page,
        limit: 10,
        sellerID: singleDispute?.seller?.id,
        buyerID: singleDispute?.buyer?.id,
      };
      dispatch(getDisputeChat(payload));
    }
  }, [singleDispute, dispatch, page]);
  const handleGoBack = () => {
    history.goBack();
  };

  const refundModal = () => setRefundToBuyer(!refundToBuyer);
  const releaseModal = () => setReleaseSellerAmount(!releaseSellerAmount);

  return (
    <>
      <ReleaseSellerAmountModal
        dispute={dispute}
        modal={releaseSellerAmount}
        toggle={releaseModal}
      />
      <RefundToBuyerModal
        modal={refundToBuyer}
        toggle={refundModal}
        dispute={dispute}
      />
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-lg-flex justify-content-between align-items-center">
                <h3
                  className="mb-0 cursor-pointer text-sm"
                  onClick={handleGoBack}
                >
                  <i class="fas fa-chevron-left mr-1"></i>Disputes Details
                </h3>
                <div className="d-lg-flex justify-content-end align-items-center ">
                  <Button
                    size="sm"
                    color="primary"
                    className=""
                    disabled={singleDispute?.isResolved === true || getDisputesLoader}
                    onClick={() => {
                      refundModal();
                    }}
                  >
                    Refund To Buyer
                  </Button>
                  <Button
                    size="sm"
                    color="primary"
                    className="my-2 my-lg-0 "
                    disabled={singleDispute?.isResolved === true || getDisputesLoader }
                    onClick={() => {
                      releaseModal();
                    }}
                  >
                    Release Funds to Seller
                  </Button>
                </div>
              </CardHeader>
              <div className="ml-2 ml-lg-4 d-flex align-items-center "></div>
              <div className="p-2 p-lg-5">
                {getDisputesLoader ? (
                  <Row className="d-flex justify-content-center">
                    <Spinner />
                  </Row>
                ) : (
                  <DisputesTab
                    dispute={dispute}
                    disputeChat={disputeChat}
                    page={page}
                    setPage={setPage}
                  />
                )}
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default DisputesDetails;

const initState = {
  allOrders: [],
  allOrdersLoader: false,
  totalPages: 0,
};
const ordersReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_ORDERS":
      return {
        ...state,
        allOrders: payload.orders,
        totalPages: payload?.totalPages,
      };
    case "GET_ORDER_SEARCH_BY_ID":
      return {
        ...state,
        allOrders: payload?.data,
        totalPages: payload?.totalPages,
      };
    case "GET_ORDERS_LOADER":
      return {
        ...state,
        allOrdersLoader: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default ordersReducer;

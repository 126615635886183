import Repository from "./Repository";
const GET_ALL = "admin/";
const GET_ADMIN_SEARCH_BY_NAME = "/admin/search/";
const ADD_ADMIN = "admin/";
const UPDATE_ADMIN = "admin/";

export default {
  getAdmins(page, limit) {
    return Repository.get(`${GET_ALL}?page=${page}&limit=${limit}`);
  },
  adminSearchByName(name) {
    const requestBody = {
        text: name,
      };
    return Repository.post(`${GET_ADMIN_SEARCH_BY_NAME}`, requestBody);
  },
  addAdmin(payload) {
    return Repository.post(`${ADD_ADMIN}`, payload);
  },
  updateAdmin(id, payload) {
    return Repository.put(`${UPDATE_ADMIN}/${id}`, payload);
  },
};

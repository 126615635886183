import React, { useState, useEffect } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";

function GoogleMap(props) {
  console.log("props: ", props);
  const [center, setCenter] = useState({
    lat: 37.7749,
    lng: -122.4194,
  });

  useEffect(() => {
    if (props?.order) {
      setCenter({
        lat: Number(props?.order?.latitude),
        lng: Number(props?.order?.longitude),
      });
    }
  }, [props?.order]);

  const mapStyles = {
    width: "100%",
    height: "100%",
  };

  // console.log({ center });

  return (
    <Map
      google={props.google}
      zoom={14}
      style={mapStyles}
      initialCenter={{
        lat: center?.lat,
        lng: center?.lng,
      }}
      center={{
        lat: center?.lat,
        lng: center?.lng,
      }}
    >
      <Marker
        position={{
          lat: center?.lat || 37.7749,
          lng: center?.lng || -122.4194,
        }}
      />

      {props?.order?.checkinLatitude && props?.order?.checkinLongitude && (
        <Marker
          position={{
            lat: Number(props?.order?.checkinLatitude),
            lng: Number(props?.order?.checkinLongitude),
          }}
        />
      )}
    </Map>
  );
}

export default GoogleApiWrapper({
  apiKey: "AIzaSyC2y-y__8WQ8eAxTkLWc4sur7j5-4cT_uw",
  // apiKey: process.env.REACT_APP_GOOGLE_API_KEY,
})(GoogleMap);

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const admins = RepositoryFactory.get("admins");

export const getAdmins = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: "GET_ADMINS_LOADER", payload: true });
    const { data } = await admins.getAdmins(page, limit);

    dispatch({
      type: "GET_ADMINS",
      payload: data,
    });
    dispatch({ type: "GET_ADMINS_LOADER", payload: false });
  } catch (err) {
    toast.error(err.response.data.message);

    dispatch({ type: "GET_ADMINS_LOADER", payload: false });
  }
};

export const adminSearchByName = (name) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ADMINS_LOADER",
      payload: true,
    });
    const { data } = await admins.adminSearchByName(name);
    console.log('adminSearchByName_data: ', data);
      dispatch({
        type: "GET_ADMIN_BY_NAME",
        payload: data,
      });
      dispatch({
        type: "GET_ADMINS_LOADER",
        payload: false,
      });

  } catch (error) {
    // toast.error(error.response.data.message);
    dispatch({
      type: "GET_ADMINS_LOADER",
      payload: false,
    });
  }
};

export const addAdmin =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_ADMIN_LOADER",
        payload: true,
      });

      const { data } = await admins.addAdmin(payload);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "ADD_ADMIN_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "ADD_ADMIN_LOADER",
        payload: false,
      });
    }
  };

export const updateAdmin =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log("id, payload: ", id, payload);
    try {
      dispatch({
        type: "UPDATE_ADMIN_LOADER",
        payload: true,
      });

      const { data } = await admins.updateAdmin(id, payload);
      

    //   toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "UPDATE_ADMIN_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "UPDATE_ADMIN_LOADER",
        payload: false,
      });
    }
  };

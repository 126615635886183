import OnlyHeader from "components/Headers/OnlyHeader";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import SuspendAccountModal from "components/Modals/usersModals/SuspendAccountModal";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { userSearch } from "store/actions/usersAction";
import { activateUserAccount, getUsers } from "store/actions/usersAction";
import UserCSVModal from "components/Modals/usersModals/UserCSVModal";
const Users = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const { allUsers, totalPages, getUsersLoader } = useSelector(
    (state) => state.users
  );
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [userId, setUserID] = useState("");
  const [message, setMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [userSearchByName, setUserSearchByName] = useState("");
  const [csvmodal, setCSVModal] = useState(false);

  // Function to toggle the modal
  const toggle = () => setCSVModal(!csvmodal);
  const itemsPerPage = 10;

  const handleOnSubmit = () => {
    setCurrentPage(1);
    dispatch(userSearch(userSearchByName, currentPage));
  };
  const handleClearSearch = () => {
    setUserSearchByName("");
    dispatch(getUsers());
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleActivateModal = (id) => {
    setUserID(id);
    setMessage("Are you sure you want to activate this account?");
    toggleConfirmationModal();
  };

  const handleConfirmationOk = () => {
    dispatch(activateUserAccount(userId, "active"));
    toggleConfirmationModal();
  };

  const handleConfirmationCancel = () => {
    toggleConfirmationModal();
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const modalGetaction = () => {
    if (userSearchByName !== "") {
      setCurrentPage(1);
      dispatch(userSearch(userSearchByName, currentPage));
    } else {
      dispatch(getUsers(currentPage, itemsPerPage));
    }
  };

  // format the date and time

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };
  useEffect(() => {
    dispatch(getUsers(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <>
      <SuspendAccountModal
        modal={modal}
        toggle={toggleModal}
        userId={userId}
        page={currentPage}
        limit={itemsPerPage}
        modalGetaction={modalGetaction}
      />
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Users</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="userSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={userSearchByName}
                          onChange={(e) => setUserSearchByName(e.target.value)}
                          // bsSize="sm"
                          // className="py-3"
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by ID, Email & Name
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        disabled={getUsersLoader}
                        onClick={handleOnSubmit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        disabled={getUsersLoader}
                        onClick={handleClearSearch}
                        size="sm"
                      >
                        Clear Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2  my-lg-2"
                        disabled={getUsersLoader}
                        onClick={() => setCSVModal(true)}
                        size="sm"
                      >
                        Export Users
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">User ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">User Type</th>
                    <th scope="col">SSN/EIN</th>
                    {/* <th scope="col">Tax ID</th> */}
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Email</th>
                    <th scope="col">Action</th>
                  </tr>
                </thead>
                {!getUsersLoader ? (
                  <tbody>
                    {allUsers && allUsers.length > 0 ? (
                      allUsers?.map((user, index) => {
                        return (
                          <tr key={index}>
                            <td>{user?.userId}</td>
                            <td>
                              {user?.firstName} {user?.lastName}
                            </td>
                            <td>
                              {user?.organization
                                ? "Organization"
                                : "Individual"}
                            </td>
                            <td>{user?.organization ? "SSN" : "EIN"}</td>
                            {/* <td>
                              {user?.businessId ? user.businessId : "N/A"}
                            </td> */}
                            <td>{formatTimestamp(user?.createdAt)}</td>
                            <td>{formatTimestamp(user?.updatedAt)}</td>
                            <td>{user?.email}</td>
                            <td>
                              <Button
                                size="sm"
                                color="primary"
                                onClick={() => {
                                  history.push(
                                    `/admin/single-user-details/${user?.id}`
                                  );
                                }}
                              >
                                View
                              </Button>

                              {user?.status === "active" ? (
                                <Button
                                  size="sm"
                                  color="primary"
                                  style={{ width: "130px" }}
                                  onClick={() => {
                                    setUserID(user?.id);
                                    toggleModal();
                                  }}
                                >
                                  Suspend Account
                                </Button>
                              ) : (
                                <Button
                                  size="sm"
                                  color="primary"
                                  style={{ width: "130px" }}
                                  onClick={() => handleActivateModal(user?.id)}
                                >
                                  Activate Account
                                </Button>
                              )}
                            </td>
                          </tr>
                        );
                      })
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <p className="mb-0 text-sm">No Users Found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>

      <ConfirmationModal
        modal={confirmationModal}
        toggle={toggleConfirmationModal}
        userId={userId}
        message={message}
        onConfirm={handleConfirmationOk}
        onCancel={handleConfirmationCancel}
      />
      <UserCSVModal modal={csvmodal} toggle={toggle} />
    </>
  );
};

export default Users;

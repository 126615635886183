import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { updateCategory } from "store/actions/categoriesAction";
import { getCategoriesAction } from "store/actions/categoriesAction";

const EditCategoryModal = ({
  modal,
  toggle,
  category,
  currentPage,
  itemsPerPage,
}) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { updateCategoryLoader } = useSelector((state) => state.category);
  const [singleCategory, setSingleCategory] = useState(null);
  const [codes, setCodes] = useState([""]);
  const [imagePreview, setImagePreview] = useState(category?.image || null);

  const handleChange = (e) => {
    setSingleCategory({
      ...singleCategory,
      name: e.target.value,
    });
  };

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSingleCategory({
      ...singleCategory,
      image: file,
    });
    setImagePreview(URL.createObjectURL(file));
  };

  const handleDeleteImage = () => {
    setSingleCategory({
      ...singleCategory,
      image: null,
    });
    setImagePreview(null);
  };

  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!singleCategory?.name.trim()) {
      alert("Add category name");
      return;
    }
    const finalTaxCodes = codes.filter((code) => code.trim());
    if (finalTaxCodes.length === 0) {
      alert("Please enter at least one  tax code");
      return;
    }

    const formData = new FormData();
    formData.append("name", singleCategory.name);
    formData.append("file", singleCategory?.image);
    finalTaxCodes?.forEach((str, index) => {
      formData.append(`taxCodes[${index}]`, str);
    });

    dispatch(
      updateCategory(singleCategory._id, formData, () => {
        toggle();
        setImagePreview(null);
        setSingleCategory(null);
        dispatch(getCategoriesAction(currentPage, itemsPerPage));
      })
    );
  };

  useEffect(() => {
    if (category) {
      const { taxCodes, ...rest } = category;
      setSingleCategory(rest);
      setCodes(taxCodes && taxCodes?.length > 0 ? taxCodes : [""]);
    } else {
      setSingleCategory(null);
    }
  }, [category]);

  const addTaxField = () => {
    setCodes((prev) => [...prev, ""]);
  };
  const removeField = (index) => {
    setCodes((prev) => prev.filter((_, i) => i !== index));
  };
  const handleTaxCodeChange = (index, newValue) => {
    setCodes((prev) => {
      const updatedTaxCodes = [...prev];
      updatedTaxCodes[index] = newValue;
      return updatedTaxCodes;
    });
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Update Category</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Category</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter Category"
                    defaultValue={singleCategory?.name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Tax Codes</Label>
                {codes?.map((item, index) => {
                  return (
                    <>
                      <FormGroup className="mb-2">
                        <div className="d-flex justify-content-around align-items-center">
                          <Input
                            value={item}
                            className="w-100"
                            type="text"
                            placeholder="Tax Code"
                            onChange={(e) =>
                              handleTaxCodeChange(index, e.target.value)
                            }
                          ></Input>
                        </div>
                      </FormGroup>
                      {index === codes.length - 1 && (
                        <Button
                          color="primary"
                          size="md"
                          onClick={addTaxField}
                          className="ml-2 mb-3"
                        >
                          +
                        </Button>
                      )}
                      {index !== 0 && (
                        <Button
                          type="button"
                          color="danger"
                          size="md"
                          className="ml-2 mb-3"
                          onClick={() => removeField(index)}
                        >
                          -
                        </Button>
                      )}
                    </>
                  );
                })}
              </Col>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Category Image</Label>
                {imagePreview || singleCategory?.image ? (
                  <div className="position-relative">
                    <button
                      type="button"
                      className="my-5 position-absolute border-0 bg-transparent"
                      style={{ top: "-50px", right: "143px" }}
                      onClick={handleDeleteImage}
                    >
                      <i className="fas fa-times text-danger"></i>
                    </button>
                    <img
                      src={imagePreview || singleCategory?.image}
                      alt="Category Preview"
                      style={{ maxWidth: "50%" }}
                    />
                  </div>
                ) : (
                  <FormGroup>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      height="80px"
                      viewBox="0 0 24 24"
                      width="80px"
                      fill="#008080"
                      onClick={handleIconClick}
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 8c0 .55.45 1 1 1s1-.45 1-1V6h2c.55 0 1-.45 1-1s-.45-1-1-1H5V2c0-.55-.45-1-1-1s-1 .45-1 1v2H1c-.55 0-1 .45-1 1s.45 1 1 1h2v2z" />
                      <circle cx="13" cy="14" r="3" />
                      <path d="M21 6h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65h-6.4c.17.3.28.63.28 1 0 1.1-.9 2-2 2H6v1c0 1.1-.9 2-2 2-.37 0-.7-.11-1-.28V20c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                    </svg>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                color="primary"
                type="submit"
                disabled={updateCategoryLoader}
              >
                {updateCategoryLoader ? <Spinner size="sm" /> : "Update"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditCategoryModal;

import Repository from "./Repository";
const GET_DELETED_JOBS = "/job/deleted-job";
const SEARCH_DELETED_JOBS = "/job/deleted-job";

export default {
  getDeletedJobs(page, limit) {
    return Repository.get(`${GET_DELETED_JOBS}?page=${page}&limit=${limit}`);
  },
  jobSearchByName(name) {
    console.log(name)
    const requestBody = {
      text: name,
    };
    return Repository.post(`${SEARCH_DELETED_JOBS}`, requestBody);
  },
};

const initState = {
  nameRequests: [],
  totalPages: null,
  allNameRequestsLoader: false,
  updateNameRequestLoader: false,
};
const nameRequestReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_ALL_NAME_REQUESTS":
      return {
        ...state,
        nameRequests: payload.data,
        totalPages: payload.pages,
      };
    case "GET_NAME_REQUEST_LOADER":
      return {
        ...state,
        allNameRequestsLoader: payload,
      };
    case "UPDATE_NAME_REQUEST_LOADER":
      return {
        ...state,
        updateNameRequestLoader: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default nameRequestReducer;

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const tickets = RepositoryFactory.get("tickets");

export const getAllTickets =
  (page, limit, status) => async (dispatch, getState) => {
    try {
      dispatch({ type: "GET_TICKETS_LOADER", payload: true });
      const { role } = getState().auth.user;
      const response =
        role === "admin"
          ? await tickets.getTickets(page, limit, status)
          : await tickets.getAssignedTickets(page, limit, status);
      const data = response.data;
      dispatch({
        type: "GET_ALL_TICKETS",
        payload: data,
      });
      dispatch({
        type: "GET_TICKETS_LOADER",
        payload: false,
      });
    } catch (err) {
      console.log(err.message);
      dispatch({
        type: "GET_TICKETS_LOADER",
        payload: false,
      });
    }
  };

export const ticketsSearchByIdAction = (id, page) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_TICKETS_LOADER",
      payload: true,
    });
    const { data } = await tickets.ticketsSearchById(id, page);
    console.log(data, "data")

    if (data?.success) {
      dispatch({
        type: "GET_TICKETS_SEARCH_BY_ID",
        payload: [data.data],
      });
      dispatch({
        type: "GET_TICKETS_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    // toast.error(error.response.data.message);
    dispatch({
      type: "GET_TICKETS_LOADER",
      payload: false,
    });
  }
};

export const getSingleTicket = (id) => async (dispatch) => {
  dispatch({ type: "GET_TICKETS_LOADER", payload: true });

  try {
    const { data } = await tickets.getSingleTicket(id);

    dispatch({
      type: "GET_SINGLE_TICKET",
      payload: data.data,
    });
    dispatch({ type: "GET_TICKETS_LOADER", payload: false });
  } catch (err) {
    dispatch({ type: "GET_TICKETS_LOADER", payload: false });
    console.log(err.message);
  }
};

export const assignTicketAction =
  (adminId, ticketId, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      let assignData = {
        adminId: adminId,
        ticketId: ticketId,
      };
      const { data } = await tickets.assignTicket(assignData);

      if (data?.success) {
        const updatedTicket = data.data;
        console.log(">>>>updatedTicket: ", updatedTicket);

        dispatch({
          type: "ASSIGN_TICKET_SUCCESS",
          payload: { updatedTicket },
        });

        toast.success(data?.message);
        onSuccess();
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

export const changeTicketStatusAction =
  (ticketId, status, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      let statusData = {
        status: status,
      };
      const { data } = await tickets.changeTicketStatus(ticketId, statusData);

      if (data?.success) {
        dispatch({
          type: "STATUS_UPDATED",
          payload: data.data,
        });
        toast.success(data?.message);
        onSuccess();
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

export const supportAddCommentAction =
  (id, comment, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const { data } = await tickets.supportAddComment(id, comment);
      console.log("data: ", data?.data);

      if (data?.success) {
        dispatch({ type: "ADD_COMMENT", payload: data?.data });
        // toast.success(data?.message);
        onSuccess();
      }
    } catch (err) {
      toast.error(err.response.data.message);
    }
  };

import React, { useEffect } from "react";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import AdminLayout from "layouts/Admin.js";
import AuthLayout from "layouts/Auth.js";
import { Provider, useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { logout } from "store/actions/authAction";

const App = () => {
  const { uid } = useSelector((state) => state.auth);
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    // console.log("In useEffect");
    // console.log(window.location,'location')
  //   window.addEventListener('storage', e => {
  //     if(e.key === 'access_token' && e.oldValue && !e.newValue) {
  //        store.dispatch(userSignOut());
  //      }
  //  });

    if (uid) {
      const searchParams = new URLSearchParams(window.location.search);
      console.log('searchParams : ', searchParams );
      if (searchParams.has("email")) {
        console.log("i am in ifff");
        const userConfirmed = window.confirm(
          "Do you want to Proceed with this Email?"
        );
        if (userConfirmed) {
          console.log("You Clicked Ok");
          dispatch(logout());
        } else {
          console.log("You clicked Cancle");
          // history.push('/admin/index');
        }
      }
    }
  }, [uid]);

  return (
    <>
      <ToastContainer />
      <BrowserRouter>
        <Switch>
          {/* Route for /auth/verifyEmail */}
          <Route
            path="/auth/verifyEmail"
            render={(props) => <AuthLayout {...props} />}
          />

          {/* Routes based on authentication status */}
          {uid ? (
            <Route
              path="/admin"
              render={(props) => <AdminLayout {...props} />}
            />
          ) : (
            <Route path="/auth" render={(props) => <AuthLayout {...props} />} />
          )}

          {/* Redirects based on authentication status */}
          {uid ? (
            <Redirect from="/" to="/admin/index" />
          ) : (
            <Redirect from="/" to="/auth/login" />
          )}
        </Switch>
      </BrowserRouter>
    </>
  );
};

export default App;

import {
  Button,
  CardFooter,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Table,
} from "reactstrap";
import { useEffect, useState } from "react";
import EditSubCategoryModal from "./EditSubCategoryModal";
import CreateSubCategoryModal from "./CreateSubCategoryModal";
import DeleteSubCategoryModal from "./DeleteSubCategoryModal";
import { useDispatch, useSelector } from "react-redux";
import { getSubCategoriesAction } from "store/actions/categoriesAction";
import ReactPaginate from "react-paginate";

const SubCategoryModal = ({ modal, toggle, categoryId }) => {
  const dispatch = useDispatch();
  const { allSubCategories, getSubCategoriesLoader } = useSelector(
    (state) => state.category
  );

  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [selectedSubCategory, setSelectedSubCategory] = useState("");
  const [isDeleteModalOpen, setDeleteSubModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const toggleEditModal = (category) => {
    setSelectedSubCategory(category);
    setEditModalOpen(!isEditModalOpen);
  };
  const handleDeleteModal = (id) => {
    setSelectedSubCategory(id);
    setDeleteSubModalOpen(!isDeleteModalOpen);
  };

  useEffect(() => {
    if (categoryId) {
      dispatch(getSubCategoriesAction(categoryId));
    }
  }, [dispatch, categoryId]);

  return (
    <div>
      <CreateSubCategoryModal
        modal={isModalOpen}
        toggle={toggleModal}
        id={categoryId}
      />

      <EditSubCategoryModal
        modal={isEditModalOpen}
        toggle={toggleEditModal}
        subCategory={selectedSubCategory}
        categoryId={categoryId}
      />

      <DeleteSubCategoryModal
        modal={isDeleteModalOpen}
        toggle={handleDeleteModal}
        id={selectedSubCategory}
        categoryId={categoryId}
      />

      <Modal isOpen={modal} toggle={toggle} centered size="md">
        <ModalHeader toggle={toggle} className="pb-0">
          <div className="d-flex align-items-center">
            <h4>Sub Category</h4>
            <Button
              color="primary"
              className="p-2 ml-5"
              size="sm"
              onClick={toggleModal}
            >
              Add Sub Category
            </Button>
          </div>
        </ModalHeader>
        <ModalBody>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Sr. No.</th>
                <th scope="col">Sub Category</th>
                <th scope="col">Actions</th>
              </tr>
            </thead>
            {!getSubCategoriesLoader ? (
              <tbody>
                {allSubCategories && allSubCategories.length > 0 ? (
                  allSubCategories?.map((category, index) => (
                    <tr key={index}>
                      <td scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">{index + 1}</span>
                          </Media>
                        </Media>
                      </td>
                      <td scope="row">
                        <Media className="align-items-center">
                          <Media>
                            <span className="mb-0 text-sm">
                              {category?.name}
                            </span>
                          </Media>
                        </Media>
                      </td>
                      <td>
                        <div className="d-flex align-items-center">
                          <Button
                            color="success"
                            size="sm"
                            onClick={() => toggleEditModal(category)}
                          >
                            <i className="fas fa-pencil-alt" />
                          </Button>
                          <Button
                            color="danger"
                            className="mx-2"
                            size="sm"
                            onClick={() => handleDeleteModal(category._id)}
                          >
                            <i className="fa fa-trash"></i>
                          </Button>
                        </div>
                      </td>
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="6" style={{ textAlign: "center" }}>
                      {allSubCategories && allSubCategories.length === 0 ? (
                        <p className="mb-0 text-sm">No Sub Categories Found.</p>
                      ) : (
                        <Spinner
                          size="lg"
                          style={{
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                      )}
                    </td>
                  </tr>
                )}
              </tbody>
            ) : (
              <tbody>
                <tr>
                  <td colSpan="6" style={{ textAlign: "center" }}>
                    <Spinner
                      size="lg"
                      style={{
                        display: "block",
                        margin: "0 auto",
                      }}
                    />
                  </td>
                </tr>
              </tbody>
            )}
          </Table>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default SubCategoryModal;

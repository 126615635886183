import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { deleteReport } from "store/actions/reportAction";
import { getAllReports } from "store/actions/reportAction";

const DeleteReportModal = ({ modal, toggle, id, currentPage, itemsPerPage }) => {
  const dispatch = useDispatch();
  const { deleteReportLoader } = useSelector((state) => state.reports);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Discard Report</ModalHeader>
        <ModalBody>Are you sure you want to discard ?</ModalBody>
        <ModalFooter>
        <Button
            color="primary"
            onClick={()=>toggle()
            }
          >
            Cancel
          </Button>
          <Button
            color="danger"
            disabled={deleteReportLoader}
            onClick={()=>{
              dispatch(deleteReport(id,() => {
                toggle();
                dispatch(getAllReports(currentPage, itemsPerPage));
              }))  
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteReportModal;

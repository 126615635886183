import Avatar from "react-avatar";
import DefaultAvatar_2 from "../../assets/img/brand/ticketdetailavatar_2.png";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

const ChatHeader = ({ profileImage, name }) => {
  console.log('>>>>profileImage: ', profileImage);
  console.log('>>>>name: ', name);
  const history = useHistory();

  const handleGoBack = () => {
    history.goBack();
  };

  return (
    <div className="d-flex bg-primary p-2 align-items-center rounded-lg ">
      <i
        role="button"
        onClick={handleGoBack}
        class="fas fa-chevron-left text-white fa-1x mr-3 ml-3"
      ></i>

      <Avatar alt="" src={profileImage || DefaultAvatar_2 } size="40" round />
      <div className="flex flex-col">
        <h5 className="m-0 mx-2 fs--20 fw--700 ml-3 text-white">{name}</h5>
      </div>
    </div>
  );
};

export default ChatHeader;

import { Col, Container, Row } from "reactstrap";
import Img from "../../assets/img/brand/favicon.png";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import dayjs from "dayjs";
import { getChatMessages } from "store/actions/chatAction";
import { updateActiveConvercation } from "store/actions/chatAction";
import { scrollingMode } from "store/actions/chatAction";

const ConversationItem = ({ conversation, index, toggle, setTotalUnread }) => {
  const dispatch = useDispatch();
  const { chatMessages } = useSelector((state) => state.chats);
  const { user } = useSelector((state) => state.auth);
  const {
    participant_1_profile,
    participant_2_profile,
    participant_1_unread,
    participant_2_unread,
    participant_1_type,
    participant_2_type,
  } = conversation;
  const [chatDetail, setChatDetail] = useState({});
  const getMatchedUsername = (participant1Id, participant2Id) => {
    if (user?.id != participant1Id) {
      setChatDetail({
        username:
          participant_1_profile?.firstName +
            " " +
            participant_1_profile?.lastName ||
          participant_1_profile?.firstname +
            " " +
            participant_1_profile?.lastname,
        unreadMessage: participant_2_unread,
        profileImage: participant_1_profile?.profileImage,
        participantId: participant_1_profile?.id,
        participant_type: participant_1_type,
        conversationId: conversation?._id,
        isOnline: participant_1_profile?.isOnline,
        isBlocked: conversation?.isBlocked,
        blockedBy: conversation?.isBlocked ? conversation?.blockedBy : null,
        lastMessage: conversation?.lastMessage,
        participant_1_unread: participant_2_unread,
        participant_2_unread: participant_2_unread,
        active: true,
        from: "conversation",
      });
      if (participant_2_unread > 0) {
        setTotalUnread((prevTotalUnread) => prevTotalUnread + 1);
      }
    } else if (user?.id != participant2Id) {
      setChatDetail({
        username:
          participant_2_profile?.firstName +
            " " +
            participant_2_profile?.lastName ||
          participant_2_profile?.firstname +
            " " +
            participant_2_profile?.lastname,
        unreadMessage: participant_1_unread,
        profileImage: participant_2_profile?.profileImage,
        participantId: participant_2_profile?.id,
        participant_type: participant_2_type,
        conversationId: conversation._id,
        isOnline: participant_2_profile?.isOnline,
        isBlocked: conversation?.isBlocked,
        blockedBy: conversation?.isBlocked ? conversation?.blockedBy : null,
        lastMessage: conversation?.lastMessage,
        participant_1_unread: participant_1_unread,
        participant_2_unread: participant_2_unread,
        active: true,
        from: "conversation",
      });
      if (participant_1_unread > 0) {
        setTotalUnread((prevTotalUnread) => prevTotalUnread + 1);
      }
    }
    return null;
  };
  const formatMessageDate = (lastMessageAt) => {
    const isToday = dayjs().isSame(dayjs(lastMessageAt), "day");
    return isToday
      ? dayjs(lastMessageAt).format("HH:mm")
      : dayjs(lastMessageAt).format("YYYY-MM-DD");
  };
  const flagChecker = (currentConversation) => {
    if (currentConversation == chatMessages[0]?.conversationId) {
      return false;
    } else {
      return true;
    }
  };
  const handledClicked = async () => {
    setChatDetail({ ...chatDetail, unreadMessage: 0 });

    let flag = flagChecker(conversation._id);
    if (flag == true) {
      dispatch(scrollingMode(true));
      dispatch(
        getChatMessages({
          id: conversation._id,
          page: 1,
          previousChat: [],
        })
      );
    }
    dispatch(updateActiveConvercation(chatDetail));
  };
  useEffect(() => {
    getMatchedUsername(participant_1_profile?.id, participant_2_profile?.id);
  }, [conversation]);
  console.log("conversation-->", conversation);
  return (
    <div className="mt-1 w-100 w-lg-100">
      <div
        className={`border rounded p-1 cursor-pointer ${
          chatDetail.unreadMessage !== 0 ? "blue" : "bg-light"
        }
        `}
        onClick={() => {
          handledClicked();
          toggle();
        }}
      >
        <Row className="align-items-center ">
          <Col xs="3" md="2" lg="1" xl="2" className="position-relative">
            <img
              alt="img"
              src={chatDetail?.profileImage ? chatDetail?.profileImage : Img}
              className="rounded-circle"
              width="40px"
              height="40px"
              style={{ objectFit: "cover" }}
            />
            <div
              className={`position-absolute online_status rounded-circle ${
                chatDetail?.isOnline
                  ? "online_status_online"
                  : "online_status_offline"
              }`}
            ></div>
          </Col>
          <Col xs="9" md="10" lg="11" xl="10">
            <div className="d-flex justify-content-between">
              <h5
                className="font-semibold text-md"
                id={`username_${index}`}
                title={chatDetail.username}
              >
                {chatDetail.username?.length > 10
                  ? `${chatDetail.username.slice(0, 10)}...`
                  : chatDetail.username}
              </h5>
              <p className="d-lg-block d-flex m-0 w-50 justify-content-end text-overflow text-small p-0 w-auto">
                {formatMessageDate(conversation.updatedAt)}
              </p>
            </div>
            <div className="d-flex justify-content-between">
              <p className="m-0 text-overflow text-small">
                <small
                  className="m-0 text-overflow text-small"
                  title={
                    conversation?.lastMessage?.message != ""
                      ? conversation.lastMessage?.message
                      : conversation.lastMessage?.attachment != "" &&
                        conversation.lastMessage?.message == ""
                      ? conversation?.lastMessage?.fileName
                      : conversation?.lastMessage?.messageType == "location"
                      ? "Message is related to location"
                      : "Message is related to offer"
                  }
                >
                  {" "}
                  {conversation?.lastMessage?.message != ""
                    ? `${conversation?.lastMessage?.message?.slice(0, 25)}`
                    : conversation?.lastMessage?.attachment != "" &&
                      conversation?.lastMessage?.attachment != undefined &&
                      conversation?.lastMessage?.message == "" &&
                      conversation?.lastMessage?.messageType == "normal"
                    ? conversation?.lastMessage?.fileName
                        ?.split(".")[0]
                        ?.slice(0, 30) +
                      "." +
                      conversation?.lastMessage?.fileName?.split(".")[1]
                    : conversation.lastMessage?.message == "" &&
                      conversation?.lastMessage?.messageType == "location"
                    ? "Message is related to location"
                    : conversation.lastMessage?.message == "" &&
                      conversation?.lastMessage?.messageType == "normal"
                    ? "Message is related to offer"
                    : ""}
                </small>
              </p>
              {chatDetail.unreadMessage !== 0 ? (
                <span className="w-max dark-blue pr-2 pl-2 mr-3 rounded-pill h-5  flex align-items-center ml-auto justify-content-end text-sm rounded-full text-white">
                  {chatDetail.unreadMessage}
                </span>
              ) : null}
            </div>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default ConversationItem;

import Repository from "./Repository";
const GET_ALL = "resolution/";
const GET_RESOLUTION_SEARCH_BY_NAME = "/resolution/resolution-person/";
const ADD_RESOLUTION_TEAM = "resolution/";
const DELETE_RESOLUTION_TEAM = "resolution/";
const UPDATE_RESOLUTION_TEAM = "resolution/";

export default {
  getResolutionTeam(page, limit) {
    return Repository.get(`${GET_ALL}?page=${page}&limit=${limit}`);
  },
  resolutionSearchByName(name) {
    return Repository.get(`${GET_RESOLUTION_SEARCH_BY_NAME}?name=${name}`);
  },
  addResolutionTeam(payload) {
    return Repository.post(`${ADD_RESOLUTION_TEAM}`, payload);
  },
  updateResolutionTeam(id, payload) {
    return Repository.put(`${UPDATE_RESOLUTION_TEAM}/${id}`, payload);
  },
  deleteResolutionTeam(id) {
    return Repository.delete(`${DELETE_RESOLUTION_TEAM}/${id}`);
  },
};

import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { getAdmins } from "store/actions/adminsAction";
import { addAdmin } from "store/actions/adminsAction";

const CreateAdminModal = ({
  modal,
  toggle,
  currentPage,
  itemsPerPage,
}) => {
  const dispatch = useDispatch();
  const { addAdminLoader } = useSelector((state) => state.admins);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    role: "admin"
  });
  const [showPassword, setShowPassword] = useState(false);

  const handlePasswordToggle = () => {
    setShowPassword(!showPassword);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!user?.firstName || !user?.lastName || !user?.email || !user?.password) {
      toast.error("Please fill in all fields before submitting");
      return;
    }
    if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(user.email)) {
      toast.error("Email must be a valid email address");
      return
    }
    dispatch(
      addAdmin(user, () => {
        toggle();
        setUser({});
        dispatch(getAdmins(currentPage, itemsPerPage));
      })
    );
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Create Admin</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">First Name</Label>
                <FormGroup>
                  <Input
                    value={user?.firstName}
                    onChange={(e) => handleChange(e)}
                    name="firstName"
                    placeholder="First Name"
                  />
                </FormGroup>
              </Col>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Last Name</Label>
                <FormGroup>
                  <Input
                    value={user?.lastName}
                    onChange={(e) => handleChange(e)}
                    name="lastName"
                    placeholder="Last Name"
                  />
                </FormGroup>
              </Col>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Email</Label>
                <FormGroup>
                  <Input
                    value={user?.email}
                    onChange={(e) => handleChange(e)}
                    name="email"
                    placeholder="Email"
                    type="email"
                  />
                </FormGroup>
              </Col>
              <Col md="8" className="m-auto">
                <div>
                  <Label className="font-weight-bold">Password</Label>
                  <div className="input-group">
                    <Input
                      type={showPassword ? "text" : "password"}
                      name="password"
                      value={user?.password}
                      onChange={(e) => handleChange(e)}
                      placeholder="Password"
                      className="pr-10"
                    />
                    <div className="input-group-append">
                      <span
                        onClick={handlePasswordToggle}
                        className="input-group-text cursor-pointer"
                      >
                        {showPassword ? (
                          <i className="fas fa-eye text-gray-500"></i>
                        ) : (
                          <i className="fas fa-eye-slash text-gray-500"></i>
                        )}
                      </span>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>

            <div className="d-flex justify-content-end align-items-center">
              <Button color="primary" type="submit" disabled={addAdminLoader}>
                {addAdminLoader ? <Spinner size="sm" /> : "Create"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateAdminModal;

import { Button, Modal, ModalBody, ModalFooter } from "reactstrap";

const ConfirmationModal = ({ modal, toggle, userId, message, onConfirm }) => {
  const handleConfirm = () => {
    console.log(`Confirming for user ID: ${userId}`);
    onConfirm(userId);
    toggle();
  };

  const handleReject = () => {
    console.log(`Rejected for user ID: ${userId}`);
    toggle();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        {/* <ModalHeader toggle={toggle}></ModalHeader> */}
        <ModalBody>
          <p className="mb-0 text-center">{message}</p>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="md" onClick={handleConfirm}>
            Ok
          </Button>
          <Button
            color="light"
            size="md"
            className="text-dark"
            onClick={handleReject}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ConfirmationModal;

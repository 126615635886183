import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { getAllTaxesAction } from "store/actions/taxActions";
import { submitTaxFileAction } from "store/actions/taxActions";

const SubmitTaxFileModal = ({ modal, toggle, year }) => {
  const { submitTaxLoader } = useSelector((state) => state.tax);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [totalAmount, setTotalAmount] = useState("");
  const [taxFile, setTaxFile] = useState(null);

  const handleTotalAmountChange = (event) => {
    setTotalAmount(event.target.value);
  };

  const handleTaxFileChange = (event) => {
    const selectedFile = event.target.files[0];

    if (selectedFile) {
      const fileExtension = selectedFile.name.split(".").pop().toLowerCase();

      const allowedFileTypes = ["doc", "docx", "pdf"];

      if (!allowedFileTypes.includes(fileExtension)) {
        event.target.value = null;
        toast.error("Please select a valid file (doc, docx, or pdf).");
        setTaxFile(null);
        return;
      }
    }

    setTaxFile(selectedFile);
  };

  const date = new Date().getFullYear();

  const handleSubmit = () => {
    if (!totalAmount || !taxFile) {
      toast.error(
        "Please fill the both Fields Total Amount and upload a Tax file."
      );
      return;
    }

    const formData = new FormData();
    formData.append("amount", totalAmount);
    formData.append("year", date);
    formData.append("file", taxFile);
    formData.append("userId", id);

    dispatch(
      submitTaxFileAction(formData, () => {
        setTotalAmount("");
        setTaxFile(null);
        dispatch(getAllTaxesAction(id, year));
        toggle();
      })
    );
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          Upload Tax For Year <b>{date}</b>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label htmlFor="totalAmount" className="form-label">
              Total Amount
            </label>
            <input
              id="totalAmount"
              className="form-control"
              type="number"
              placeholder="Total amount"
              value={totalAmount}
              onChange={handleTotalAmountChange}
            />
          </div>
          <div>
            <label htmlFor="taxFile" className="form-label">
              Upload the current year Tax file
            </label>
            <input
              id="taxFile"
              className="form-control"
              type="file"
              onChange={handleTaxFileChange}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="success"
            onClick={handleSubmit}
            disabled={submitTaxLoader}
          >
            {submitTaxLoader ? <Spinner size="sm" /> : "Save"}
          </Button>
          <Button color="danger" onClick={toggle}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SubmitTaxFileModal;

import OnlyHeader from "components/Headers/OnlyHeader";
import ViewDisputeActivity from "components/Modals/disputesModal/ViewDisputeActivity";
import { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Nav,
  NavItem,
  NavLink,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
  TabContent,
  TabPane,
  Table,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import {
  getAllPolicies,
  policyDecisionAction,
} from "store/actions/policyAction";
import ReactPaginate from "react-paginate";

const Policies = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const [singleDispute, setSingleDispute] = useState(null);
  const {
    pending,
    accepted,
    rejected,
    totalPages,
    allPoliciesLoader,
    updatePoliciesLoader,
  } = useSelector((state) => state.policies);

  const toggleModal = () => {
    setModal(!modal);
  };

  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const handleDownload = (url) => {
    const pdfUrl = url;
    window.open(pdfUrl, "_blank");
  };
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    dispatch(getAllPolicies(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage, activeTab]);

  return (
    <>
      <ViewDisputeActivity
        modal={modal}
        toggle={toggleModal}
        singleDispute={singleDispute}
      />

      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-lg-flex justify-content-between align-items-center">
                  <h3>Policies</h3>
                </div>
              </CardHeader>
              <CardBody>
                <Nav tabs>
                  <NavItem>
                    <NavLink
                      className={`cursor-pointer ${
                        activeTab === "1" ? "active" : ""
                      }`}
                      onClick={() => toggle("1")}
                    >
                      Accepted Policies
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={`cursor-pointer ${
                        activeTab === "2" ? "active" : ""
                      }`}
                      onClick={() => toggle("2")}
                    >
                      Rejected Policies
                    </NavLink>
                  </NavItem>
                  <NavItem className="cursor-pointer">
                    <NavLink
                      className={`cursor-pointer ${
                        activeTab === "3" ? "active" : ""
                      }`}
                      onClick={() => toggle("3")}
                    >
                      Pending Policies
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1">
                    <Row>
                      <Col sm="12">
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Username</th>
                              <th scope="col">Policy Document</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          {!allPoliciesLoader ? (
                            <tbody>
                              {accepted && accepted?.items?.length > 0 ? (
                                accepted.items?.map((policy, index) => (
                                  <tr key={index}>
                                    <td>
                                      {policy?.user?.firstName}{" "}
                                      {policy?.user?.lastName}
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        onClick={() =>
                                          handleDownload(policy?.url)
                                        }
                                      >
                                        Download Policy Doc{" "}
                                        <i className="fa fa-download"></i>
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        color="success"
                                        disabled
                                        size="sm"
                                      >
                                        Accepted
                                      </Button>

                                      <Button
                                        size="sm"
                                        color="primary"
                                        disabled={updatePoliciesLoader}
                                        onClick={() => {
                                          dispatch(
                                            policyDecisionAction(
                                              policy?._id,
                                              "rejected",
                                              "acceptedArray"
                                            )
                                          );
                                        }}
                                      >
                                        Reject
                                      </Button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="3"
                                    style={{ textAlign: "center" }}
                                  >
                                    {accepted &&
                                    accepted?.items?.length === 0 ? (
                                      <p className="mb-0 text-sm">
                                        No policies found
                                      </p>
                                    ) : (
                                      <Spinner
                                        size="lg"
                                        style={{
                                          display: "block",
                                          margin: "0 auto",
                                        }}
                                      />
                                    )}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan="3" style={{ textAlign: "center" }}>
                                  <Spinner
                                    size="lg"
                                    style={{
                                      display: "block",
                                      margin: "0 auto",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                        {accepted?.count > 0 && (
                          <CardFooter className="py-4">
                            <nav aria-label="...">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={totalPages}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageChange}
                                containerClassName={
                                  "pagination px-1 justify-content-end mb-0"
                                }
                                activeClassName={"active"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                nextClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                              />
                            </nav>
                          </CardFooter>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="2">
                    <Row>
                      <Col sm="12">
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Username</th>
                              <th scope="col">Policy Document</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          {!allPoliciesLoader ? (
                            <tbody>
                              {rejected && rejected?.items?.length > 0 ? (
                                rejected.items?.map((policy, index) => (
                                  <tr key={index}>
                                    <td>
                                      {policy?.user?.firstName}{" "}
                                      {policy?.user?.lastName}
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        onClick={() =>
                                          handleDownload(policy?.url)
                                        }
                                      >
                                        Download Policy Doc{" "}
                                        <i className="fa fa-download"></i>
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        disabled={updatePoliciesLoader}
                                        onClick={() => {
                                          dispatch(
                                            policyDecisionAction(
                                              policy?._id,
                                              "accepted",
                                              "rejectedArray"
                                            )
                                          );
                                        }}
                                      >
                                        Accept
                                      </Button>
                                      <Button color="danger" disabled size="sm">
                                        Rejected
                                      </Button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="3"
                                    style={{ textAlign: "center" }}
                                  >
                                    {rejected &&
                                    rejected?.items?.length === 0 ? (
                                      <p className="mb-0 text-sm">
                                        No rejected policies found
                                      </p>
                                    ) : (
                                      <Spinner
                                        size="lg"
                                        style={{
                                          display: "block",
                                          margin: "0 auto",
                                        }}
                                      />
                                    )}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan="3" style={{ textAlign: "center" }}>
                                  <Spinner
                                    size="lg"
                                    style={{
                                      display: "block",
                                      margin: "0 auto",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                        {rejected?.count > 0 && (
                          <CardFooter className="py-4">
                            <nav aria-label="...">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={totalPages}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageChange}
                                containerClassName={
                                  "pagination px-1 justify-content-end mb-0"
                                }
                                activeClassName={"active"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                nextClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                              />
                            </nav>
                          </CardFooter>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                  <TabPane tabId="3">
                    <Row>
                      <Col sm="12">
                        <Table
                          className="align-items-center table-flush"
                          responsive
                        >
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">Username</th>
                              <th scope="col">Policy Document</th>

                              <th>Action</th>
                            </tr>
                          </thead>
                          {!allPoliciesLoader ? (
                            <tbody>
                              {pending && pending?.items?.length > 0 ? (
                                pending.items?.map((policy, index) => (
                                  <tr key={index}>
                                    <td>
                                      {policy?.user?.firstName}{" "}
                                      {policy?.user?.lastName}
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        onClick={() =>
                                          handleDownload(policy?.url)
                                        }
                                      >
                                        Download Policy Doc{" "}
                                        <i className="fa fa-download"></i>
                                      </Button>
                                    </td>
                                    <td>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        disabled={updatePoliciesLoader}
                                        onClick={() => {
                                          dispatch(
                                            policyDecisionAction(
                                              policy?._id,
                                              "accepted",
                                              "pendingArray"
                                            )
                                          );
                                        }}
                                      >
                                        Accept
                                      </Button>
                                      <Button
                                        size="sm"
                                        color="primary"
                                        disabled={updatePoliciesLoader}
                                        onClick={() => {
                                          dispatch(
                                            policyDecisionAction(
                                              policy?._id,
                                              "rejected",
                                              "pendingArray"
                                            )
                                          );
                                        }}
                                      >
                                        Reject
                                      </Button>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan="3"
                                    style={{ textAlign: "center" }}
                                  >
                                    {pending && pending?.items?.length === 0 ? (
                                      <p className="mb-0 text-sm">
                                        No pending policies found
                                      </p>
                                    ) : (
                                      <Spinner
                                        size="lg"
                                        style={{
                                          display: "block",
                                          margin: "0 auto",
                                        }}
                                      />
                                    )}
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          ) : (
                            <tbody>
                              <tr>
                                <td colSpan="3" style={{ textAlign: "center" }}>
                                  <Spinner
                                    size="lg"
                                    style={{
                                      display: "block",
                                      margin: "0 auto",
                                    }}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          )}
                        </Table>
                        {pending?.count > 0 && (
                          <CardFooter className="py-4">
                            <nav aria-label="...">
                              <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={totalPages}
                                marginPagesDisplayed={1}
                                pageRangeDisplayed={2}
                                onPageChange={handlePageChange}
                                containerClassName={
                                  "pagination px-1 justify-content-end mb-0"
                                }
                                activeClassName={"active"}
                                pageClassName={"page-item"}
                                pageLinkClassName={"page-link"}
                                previousClassName={"page-item"}
                                nextClassName={"page-item"}
                                previousLinkClassName={"page-link"}
                                nextLinkClassName={"page-link"}
                              />
                            </nav>
                          </CardFooter>
                        )}
                      </Col>
                    </Row>
                  </TabPane>
                </TabContent>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Policies;

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
import { getActiveConversation } from "store/reducers/chatReducer";

const chats = RepositoryFactory.get("chats");

export const sendMessage =
  (data, onSuccess = (data) => {}, onError = (data) => {}) =>
  async (dispatch) => {
    try {
      const form = new FormData();
      form.append("message", data.message);
      form.append("participant", data.participant);

      const response = await chats.sendMessage(form);
      if (response && response.data) {
        dispatch({
          type: "ADMIN_SEND_MESSAGE",
          payload: response.data.data,
        });
      } else {
        console.error("No response data received or error occurred");
      }
      onSuccess(response.data.data);
    } catch (error) {
      console.error("Error sending message:", error);
      onError(error);
    }
  };

export const getConversation = () => async (dispatch) => {
  try {
    dispatch({
      type: "ADMIN_CONVERSATION_LOADER",
      payload: true,
    });
    const response = await chats.getConversation();
    if (response && response.data) {
      dispatch({
        type: "ADMIN_GET_CONVERSATION",
        payload: response.data.data,
      });
    } else {
      dispatch({
        type: "ADMIN_CONVERSATION_LOADER",
        payload: true,
      });
      console.error("No response data received or error occurred");
    }
  } catch (error) {
    dispatch({
      type: "ADMIN_CONVERSATION_LOADER",
      payload: true,
    });
    console.error("Error getting message:", error);
  }
};
export const updateAllConversation =
  (onSuccess = (response) => {}) =>
  async (dispatch) => {
    try {
      const response = await chats.getConversation();
      if (response && response.data) {
        dispatch({
          type: "ADMIN_GET_CONVERSATION",
          payload: response.data.data,
        });
        onSuccess(response.data.data);
      } else {
        console.error("No response data received or error occurred");
      }
    } catch (error) {
      console.error("Error getting message:", error);
    }
  };
export const getChatMessages = (detail) => async (dispatch) => {
  try {
    dispatch({
      type: "CHAT_MESSAGES_LOADER",
      payload: true,
    });
    const response = await chats.getChatMessages(detail);
    await chats.unreadMessages(detail);
    if (response.data) {
      let updateMessages = [...detail.previousChat, ...response.data.data];
      dispatch({
        type: "GET_CHAT_MESSAGES",
        payload: {
          messages: updateMessages,
          paginationDetail: response.data,
        },
      });
    } else {
      dispatch({
        type: "CHAT_MESSAGES_LOADER",
        payload: true,
      });
      toast.error(response.response.data.message);
    }
  } catch (error) {
    dispatch({
      type: "CHAT_MESSAGES_LOADER",
      payload: true,
    });
  }
};
export const updateActiveConvercation = (data) => (dispatch, getState) => {
  try {
    dispatch({
      type: "ACTIVE_CONVERSATION",
      payload: data,
    });
  } catch (error) {
    console.error("Error updating active conversation:", error);
  }
};

export const scrollingMode = (data) => (dispatch) => {
  try {
    dispatch({
      type: "SET_SCROLLING_MODE",
      payload: data,
    });
  } catch (error) {
    console.error("Error updating active conversation:", error);
  }
};

export const sendConversationMessage =
  (data, onSuccess = (data) => {}, onError = (data) => {}) =>
  async (dispatch) => {
    try {
      const form = new FormData();
      console.log("message data", data.message);

      form.append("message", data.message);
      form.append("file", data.media);
      form.append("participant_type", data.participant_type);
      form.append("participant", data.participant);
      form.append("messageType", data.messageType);

      const response = await chats.sendMessage(form);
      if (response && response.data) {
        onSuccess(response.data.data);
        dispatch({
          type: "ADMIN_SEND_MESSAGE",
          payload: response.data.data,
        });
      } else {
        onError();
        console.error("No response data received or error occurred");
      }
    } catch (error) {
      onError();
      console.error("Error sending message:", error);
    }
  };

export const updateConversation =
  (currentConversation) => async (dispatch, getState) => {
    try {
      console.log("at ere");
      const chats = getState().chats.allConversations;
      // Find the index of the conversation to update
      const conversationIndex = chats.findIndex(
        (chat) => chat._id === currentConversation.conversationId
      );
      if (conversationIndex !== -1) {
        // Update the lastMessage in the conversation object
        const updatedChat = {
          ...chats[conversationIndex],
          participant_1_unread: currentConversation?.participant_1_unread,
          participant_2_unread: currentConversation?.participant_2_unread,
          lastMessage: currentConversation.lastMessage,
          updatedAt: currentConversation.lastMessage.createdAt,
          isBlocked: currentConversation?.isBlocked,
          blockedBy: currentConversation?.blockedBy,
        };
        // Remove the updated chat from the chats array
        const filteredChats = chats.filter(
          (chat) => chat._id !== currentConversation.conversationId
        );
        // Add the updated chat to the beginning of the filtered chats array
        const updatedChats = [updatedChat, ...filteredChats];
        // Dispatch the updated chats array using setChats action
        dispatch({
          type: "UPDATE_CONVERSATION",
          payload: updatedChats,
        });
      }
      return;
    } catch (error) {
      console.log("error", error);
    }
  };

export const updateChatMesssages =
  (currentMessage) => async (dispatch, getState) => {
    try {
      const chatMessages = getState().chats.chatMessages;

      let updatedChatMessages = [...chatMessages];
      if (
        currentMessage.conversationId == chatMessages[0]?.conversationId &&
        (currentMessage.offer ||
          (!currentMessage.offer && currentMessage._id != chatMessages[0]?._id))
      ) {
        if (currentMessage.offer) {
          const indexToUpdate = updatedChatMessages.findIndex(
            (message) => message._id === currentMessage._id
          );
          if (indexToUpdate !== -1) {
            updatedChatMessages[indexToUpdate] = currentMessage;
          } else {
            updatedChatMessages = [currentMessage, ...chatMessages];
          }
        } else {
          updatedChatMessages = [currentMessage, ...chatMessages];
        }
      }
      dispatch({
        type: "UPDATE_CHAT_MESSAGES",
        payload: updatedChatMessages,
      });
      return updatedChatMessages;
    } catch (error) {
      console.log("error", error);
    }
  };

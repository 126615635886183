import OnlyHeader from "components/Headers/OnlyHeader";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import SuspendAccountModal from "components/Modals/usersModals/SuspendAccountModal";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Badge,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { covidCertificationSearch } from "store/actions/certificationAction";
import { updateCertificateStatus } from "store/actions/certificationAction";
import { getAllCovidCertificationAction } from "store/actions/certificationAction";
import { userSearch } from "store/actions/usersAction";
import { activateUserAccount, getUsers } from "store/actions/usersAction";

const CovidTests = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { allCovidCertification, totalPages, getCertificationLoader } =
    useSelector((state) => state.certification);

  const [currentPage, setCurrentPage] = useState(1);
  const [covidCertificateSearchByName, setCovidCertificateSearchByName] =
    useState("");
  const itemsPerPage = 10;

  const handleOnSubmit = () => {
    setCurrentPage(1);
    dispatch(
      covidCertificationSearch(covidCertificateSearchByName, currentPage)
    );
  };
  const handleClearSearch = () => {
    setCovidCertificateSearchByName("");
    dispatch(getAllCovidCertificationAction());
  };
  console.log("allCovidCertification-->", allCovidCertification);
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    dispatch(getAllCovidCertificationAction(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Covid Tests</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="userSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={covidCertificateSearchByName}
                          onChange={(e) =>
                            setCovidCertificateSearchByName(e.target.value)
                          }
                          // bsSize="sm"
                          // className="py-3"
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by ID & Name
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        disabled={getCertificationLoader}
                        onClick={handleOnSubmit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        disabled={getCertificationLoader}
                        onClick={handleClearSearch}
                        size="sm"
                      >
                        Clear Search
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Name</th>
                    <th scope="col">License/Certification</th>
                    <th scope="col">Type</th>
                    <th scope="col">Issued Date</th>
                    <th scope="col">Indefinite</th>
                    <th scope="col">Status</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {!getCertificationLoader ? (
                  <tbody>
                    {allCovidCertification &&
                    allCovidCertification.length > 0 ? (
                      allCovidCertification?.map((certificate, index) => (
                        <tr key={index}>
                          <td>{certificate?.name}</td>
                          <td>
                            <a target="_blank" href={certificate?.file}>
                              Preview
                            </a>
                          </td>
                          <td className="text-capitalize">
                            {certificate?.type}
                          </td>
                          <td>{certificate?.issuedDate.split("T")[0]}</td>
                          <th>{certificate?.indefinite ? "True" : "False"}</th>
                          <td>
                            {certificate?.type === "covid" ? (
                              certificate?.status === "pending" ? (
                                <Badge color="warning">Pending</Badge>
                              ) : certificate?.status === "accept" ? (
                                <Badge color="success">Accepted</Badge>
                              ) : certificate?.status === "reject" ? (
                                <Badge color="danger">Rejected</Badge>
                              ) : null
                            ) : new Date(
                                certificate?.expiryDate.split("T")[0]
                              ) < new Date() ? (
                              <Badge color="danger">Expired</Badge>
                            ) : (
                              <Badge color="success">Active</Badge>
                            )}
                          </td>
                          <td>
                            <>
                              <Button
                                size="sm"
                                color="primary"
                                disabled={
                                  certificate?.status === "accept" ||
                                  certificate?.status === "reject"
                                }
                                onClick={() =>
                                  dispatch(
                                    updateCertificateStatus(
                                      certificate?.userId,
                                      certificate?._id,
                                      "accept",
                                      () => {
                                        dispatch(
                                          getAllCovidCertificationAction(
                                            currentPage,
                                            itemsPerPage
                                          )
                                        );
                                      }
                                    )
                                  )
                                }
                              >
                                Accept
                              </Button>
                              <Button
                                size="sm"
                                color="danger"
                                disabled={
                                  certificate?.status === "accept" ||
                                  certificate?.status === "reject"
                                }
                                onClick={() =>
                                  dispatch(
                                    updateCertificateStatus(
                                      certificate?.userId,
                                      certificate?._id,
                                      "reject",
                                      () => {
                                        dispatch(
                                          getAllCovidCertificationAction(
                                            currentPage,
                                            itemsPerPage
                                          )
                                        );
                                      }
                                    )
                                  )
                                }
                              >
                                Reject
                              </Button>
                            </>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          <p>No Covid Tests found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CovidTests;

import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Media,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import SubCategoryModal from "components/Modals/categoriesModal/SubCategoryModal";
import CreateCategoryModal from "components/Modals/categoriesModal/CreateCategoryModal";
import EditCategoryModal from "components/Modals/categoriesModal/EditCategoryModal";
import DeleteCategoryModal from "components/Modals/categoriesModal/DeleteCategoryModal";
import { getCategoriesAction } from "store/actions/categoriesAction";
import { CategorySearchByTitleAction } from "store/actions/categoriesAction";

const Categories = () => {
  const { allCategories, getCategoriesLoader, totalPages } = useSelector(
    (state) => state.category
  );
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [isModalOpen, setModalOpen] = useState(false);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [isSubModalOpen, setSubModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteSubModalOpen] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [CategorySearchTitle, setCategorySearchTitle] = useState("");

  const itemsPerPage = 10;
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
  };

  const toggleEditModal = (category) => {
    setSelectedCategory(category);
    setEditModalOpen(!isEditModalOpen);
  };

  const toggleSubCategoryModal = (categoryId) => {
    setSelectedCategory(categoryId);
    console.log(selectedCategory);
    setSubModalOpen(!isSubModalOpen);
  };

  const handleDeleteModal = (id) => {
    setSelectedCategory(id);
    setDeleteSubModalOpen(!isDeleteModalOpen);
  };

  const handleOnSumbit = () => {
    dispatch(CategorySearchByTitleAction(CategorySearchTitle));
  };

  const handleClearSearch = () => {
    setCategorySearchTitle("");
    dispatch(getCategoriesAction(currentPage, itemsPerPage));
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  useEffect(() => {
    dispatch(getCategoriesAction(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Categories</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="ResolutionSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={CategorySearchTitle}
                          onChange={(e) =>
                            setCategorySearchTitle(e.target.value)
                          }
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Category
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-10 py-2 mx-1 my-2 my-lg-0"
                        disabled={getCategoriesLoader}
                        onClick={handleOnSumbit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-1 my-2 my-lg-0"
                        size="sm"
                        onClick={handleClearSearch}
                      >
                        Clear Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-1 my-2 my-lg-0"
                        size="sm"
                        onClick={toggleModal}
                      >
                        Add Category
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">CreatedAt</th>
                    <th scope="col">Category</th>
                    <th scope="col">Image</th>
                    <th scope="col">View Sub Category</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {!getCategoriesLoader ? (
                  <tbody>
                    {allCategories && allCategories.length > 0 ? (
                      allCategories?.map((category, index) => (
                        <tr key={index}>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {formatTimestamp(category?.createdAt)}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {category?.name}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td>
                            <img
                              src={category.image}
                              style={{
                                width: "100px",
                                height: "80px",
                                cursor: "pointer",
                              }}
                              onClick={() => handleImageClick(category.image)}
                            />
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Button
                                color="primary"
                                className="mx-2"
                                size="sm"
                                onClick={() =>
                                  toggleSubCategoryModal(category._id)
                                }
                              >
                                View Sub Category
                              </Button>
                            </div>
                          </td>
                          <td>
                            <div className="d-flex align-items-center">
                              <Button
                                color="success"
                                size="sm"
                                onClick={() => toggleEditModal(category)}
                              >
                                <i className="fas fa-pencil-alt" />
                              </Button>
                              <Button
                                color="danger"
                                className="mx-2"
                                size="sm"
                                onClick={() => handleDeleteModal(category._id)}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          {allCategories && allCategories.length === 0 ? (
                            <p className="mb-0 text-sm">No Categories Found.</p>
                          ) : (
                            <Spinner
                              size="lg"
                              style={{
                                display: "block",
                                margin: "0 auto",
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <Modal
        isOpen={isImageModalOpen}
        toggle={() => setImageModalOpen(!isImageModalOpen)}
      >
        <ModalHeader toggle={() => setImageModalOpen(!isImageModalOpen)}>
          Image Preview
        </ModalHeader>
        <ModalBody>
          <img src={selectedImage} alt="Preview" style={{ width: "100%" }} />
        </ModalBody>
      </Modal>

      <CreateCategoryModal
        modal={isModalOpen}
        toggle={toggleModal}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />

      <EditCategoryModal
        modal={isEditModalOpen}
        toggle={() => toggleEditModal()}
        category={selectedCategory}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />

      <SubCategoryModal
        modal={isSubModalOpen}
        toggle={() => toggleSubCategoryModal(null)}
        categoryId={selectedCategory}
      />

      <DeleteCategoryModal
        modal={isDeleteModalOpen}
        toggle={() => handleDeleteModal()}
        id={selectedCategory}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
    </>
  );
};

export default Categories;

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const category = RepositoryFactory.get("category");

export const getCategoriesAction = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CATEGORIES_LOADER",
      payload: true,
    });
    const { data } = await category.getCategories(page, limit);

    dispatch({
      type: "GET_CATEGORIES",
      payload: data,
    });
    dispatch({
      type: "GET_CATEGORIES_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_CATEGORIES_LOADER",
      payload: false,
    });
  }
};

export const addNewCategory =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_CATEGORY_LOADER",
        payload: true,
      });

      const { data } = await category.addCategory(payload);
      console.log(data);
      toast.success(data?.message);
      dispatch({
        type: "ADD_CATEGORY_LOADER",
        payload: false,
      });
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "ADD_CATEGORY_LOADER",
        payload: false,
      });
    }
  };

export const deleteCategory =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_CATEGORY_LOADER",
        payload: true,
      });
      const { data } = await category.deleteCategory(id);

      dispatch({
        type: "DELETE_CATEGORY_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "CATEGORY_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "DELETE_CATEGORY_LOADER",
        payload: false,
      });
    }
  };

export const updateCategory =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_CATEGORY_LOADER",
        payload: true,
      });

      const { data } = await category.updateCategory(id, payload);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "UPDATE_CATEGORY_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "UPDATE_CATEGORY_LOADER",
        payload: false,
      });
    }
  };

export const CategorySearchByTitleAction = (title) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CATEGORIES_LOADER",
      payload: true,
    });
    const { data } = await category.categorySearchByTitle(title);

    if (data?.success) {
      dispatch({
        type: "GET_CATEGORY_SEARCH_BY_TITLE",
        payload: data,
      });
      dispatch({
        type: "GET_CATEGORIES_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "GET_CATEGORIES_LOADER",
      payload: false,
    });
  }
};

//Sub Categories

export const getSubCategoriesAction = (categoryId) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SUB_CATEGORIES_LOADER",
      payload: true,
    });
    const { data } = await category.getSubCategories(categoryId);
    const subCategories = data.data.filter(
      (cat) => cat.category === categoryId
    );
    dispatch({
      type: "GET_SUB_CATEGORIES",
      payload: subCategories,
    });
    dispatch({
      type: "GET_SUB_CATEGORIES_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_SUB_CATEGORIES_LOADER",
      payload: false,
    });
  }
};

export const addNewSubCategory =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_SUB_CATEGORY_LOADER",
        payload: true,
      });
      const { data } = await category.addSubCategory(payload);
      console.log(data);
      toast.success(data?.message);
      dispatch({
        type: "ADD_SUB_CATEGORY_LOADER",
        payload: false,
      });
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "ADD_SUB_CATEGORY_LOADER",
        payload: false,
      });
    }
  };

export const deleteSubCategory =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_SUB_CATEGORY_LOADER",
        payload: true,
      });
      const { data } = await category.deleteSubCategory(id);

      dispatch({
        type: "DELETE_SUB_CATEGORY_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "SUB_CATEGORY_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "DELETE_SUB_CATEGORY_LOADER",
        payload: false,
      });
    }
  };

export const updateSubCategory =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_SUB_CATEGORY_LOADER",
        payload: true,
      });
      const { data } = await category.updateSubCategory(id, payload);
      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "UPDATE_SUB_CATEGORY_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "UPDATE_SUB_CATEGORY_LOADER",
        payload: false,
      });
    }
  };

//Tax codes

// export const addCategoryTaxCodes =
//   (payload, onSuccess = () => {}) =>
//   async (dispatch) => {
//     const { data } = await category.addTaxCode(payload);
//     console.log(data, "data");
//   };
// export const getTaxCodes =
//   (ID, onSuccess = () => {}) =>
//   async (dispatch) => {
//     const { data } = await category.getCodes(ID);
//     console.log(data, "data");
//   };

import { convertToHTML } from "draft-convert";
import { EditorState, convertToRaw, ContentState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import OnlyHeader from "components/Headers/OnlyHeader";
import MediaPicker from "components/MediaPicker";
import { toast } from "react-toastify";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import { getSingleHelpArticle } from "store/actions/helpArticleAction";
import { updateHelpArticle } from "store/actions/helpArticleAction";

const EditArticle = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  console.log("id: ", id);

  const { singleHelpArticle, updateHelpArticlesLoader } = useSelector(
    (state) => state.helpArticles
  );

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [selectedValues, setSelectedValues] = useState([]);
  const [newImage, setNewImage] = useState(null);
  console.log(newImage);

  const [articleData, setArticleData] = useState({
    title: "",
    description: "",
    content: "",
    file: "",
  });
  console.log("articleData: ", articleData);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setArticleData((prevData) => ({
      ...prevData,
      content: currentContentAsHTML,
    }));
  };

  const handleArticleDataChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "featuredImgURL" || name === "getMediaURL") {
      setArticleData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setArticleData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    if (!articleData.title || !articleData.content || !articleData.file) {
      toast.error("Please fill all the required fields");
      return;
    }

    const temp = {
      ...articleData,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    };

    const payload = {
      title: temp.title,
      description: temp.description,
      content: temp.content,
      file: temp.file,
    };
    console.log({ payload });

    dispatch(
      updateHelpArticle(id, payload, () => {
        history.push("/admin/help-articles");
      })
    );
  };

  useEffect(() => {
    if (singleHelpArticle?.content) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            htmlToDraft(singleHelpArticle?.content)
          )
        )
      );
      setArticleData({
        title: singleHelpArticle?.title,
        description: singleHelpArticle?.description,
        content: singleHelpArticle?.content,
        file: singleHelpArticle?.image,
      });
    }
  }, [singleHelpArticle]);

  const embedVideoCallBack = (iframeHtml) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = iframeHtml.trim();
    const iframeElement = tempDiv.querySelector("iframe");
    if (iframeElement) {
      const src = iframeElement.getAttribute("src");
      const cleanSrc = src.split("?")[0];
      return cleanSrc;
    } else {
      return null;
    }
  };

  useEffect(() => {
    dispatch(getSingleHelpArticle(id));
  }, [id]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row className="d-flex">
                  <Col md="6" className="my-2">
                    <Label className="mb-0 my-auto">
                      Article Title <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="title"
                      className="form-controll_title"
                      value={articleData.title}
                      required
                      onChange={(e) => {
                        handleArticleDataChange(e);
                      }}
                    />
                  </Col>
                  <Col md="6" className="my-2">
                    <Label className="mb-0 my-auto">
                      Article Description <span className="text-danger">*</span>
                    </Label>
                    <Input
                      name="description"
                      className="form-controll_title"
                      value={articleData.description}
                      required
                      onChange={(e) => {
                        handleArticleDataChange(e);
                      }}
                    />
                  </Col>

                  <div className="mx-3 article_media mt-2 mb-2">
                    <MediaPicker
                      media={articleData.file}
                      onImageChange={(newFile) => {
                        setArticleData((prevData) => ({
                          ...prevData,
                          file: newFile,
                        }));
                      }}
                    />
                  </div>
                  <Col md={12} className="mt-3">
                    <Label className="mb-0 my-auto">
                      Article Content <span className="text-danger">*</span>
                    </Label>
                    <Editor
                      editorState={editorState}
                      editorStyle={{
                        border: "2px solid #C0C0C0",
                      }}
                      toolbar={{
                        embedded: {
                          embedCallback: embedVideoCallBack,
                          defaultSize: {
                            height: "auto",
                            width: "auto",
                          },
                        },
                        fontFamily: {
                          options: [
                            "Arial",
                            "Georgia",
                            "Impact",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                            "Barlow",
                          ],
                        },
                      }}
                      onEditorStateChange={handleEditorChange}
                    />
                  </Col>
                </Row>
                <div className="d-flex justify-content-end align-items-center mt-3">
                  <div>
                    <Button
                      type="button"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={updateHelpArticlesLoader || !articleData.title}
                    >
                      {updateHelpArticlesLoader ? (
                        <Spinner
                          className="d-flex mx-auto align-items-center justify-content-center overflow-hidden"
                          size="sm"
                          color="white"
                          disabled={updateHelpArticlesLoader}
                        />
                      ) : (
                        "UPDATE ARTICLE"
                      )}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default EditArticle;

import { formatDate } from "Contants";
import Avatar from "react-avatar";
import { useLocation } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";
import ChatOfferCard from "./ChatOfferCard";
import googleImg from "../assets/img/googleImg.jpg";

const ChatMessagesDisputes = ({ dispute, disputeChat, page, setPage }) => {
  return (
    <Container className="chatApp">
      <Row className="border border-1 chatApp__contents">
        <Col md={12} className="px-2 py-2 chatAppMessages">
          {dispute && (
            <div className="d-flex justify-content-between align-items-center site-muted p-3 chatAppMessages__topbar">
              <div className="d-flex align-items-center">
                <Avatar
                  src={
                    dispute?.type === "buyer"
                      ? dispute?.buyer?.profileImage
                      : dispute?.seller?.profileImage
                  }
                  size="50"
                  round={true}
                />
                <div className="mx-2">
                  <h3 className="mb-0">
                    {dispute?.type === "buyer"
                      ? dispute?.buyer?.firstName +
                        " " +
                        dispute?.buyer?.lastName
                      : dispute?.seller?.firstName +
                        " " +
                        dispute?.seller?.lastName}
                  </h3>
                  <small className="text-muted">
                    {formatDate(dispute?.createdAt)}
                  </small>
                </div>
              </div>
              <div>....</div>
            </div>
          )}
          <div className="chatAppMessages__contents">
            {disputeChat &&
              disputeChat?.map((message) => {
                return (
                  <Row
                    className={`m-0 flex-column ${
                      message?.senderType == "seller"
                        ? "align-items-end"
                        : "align-items-start"
                    }`}
                    key={message?.id}
                  >
                    {message?.messageType == "location" ? (
                      <div className=" w-50">
                        <div className="bg-white border border-1 shadow-md px-3 py-3 my-2 rounded">
                          <img src={googleImg} alt="" className="w-100" />
                        </div>
                        <div className="d-flex justify-content-end">
                          <small className="mb-0">
                            {formatDate(message?.createdAt)}
                          </small>
                        </div>
                      </div>
                    ) : (
                      // </Col>
                      <>
                        {message?.offer ? (
                          <div className="chat_offer_card_width bg-white border border-1 shadow-md py-2 px-2 my-2 rounded">
                            <ChatOfferCard message={message} />
                          </div>
                        ) : (
                          <div className="chatAppMessages__contents-message">
                            <div className=" d-flex p-3 chatAppMessages__contents-textImg">
                              <div className="message-avatar">
                                <Avatar
                                  src={
                                    message?.senderId?.profileImage &&
                                    message?.senderId?.profileImage
                                  }
                                  size="50"
                                  round={true}
                                />
                              </div>
                              <div className="chatAppMessages__contents-message-text mt-4 mx-2">
                                {message?.message}
                              </div>
                            </div>
                            <div className="d-flex justify-content-end">
                              <small className="mb-0">
                                {formatDate(message?.createdAt)}
                              </small>
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </Row>
                );
              })}
            <div className="text-center">
              {disputeChat.length > 9 && disputeChat?.length % 10 === 0 && (
                <button
                  className="cursor-pointer my-2 btn-sm btn-primary"
                  onClick={(e) => {
                    e.preventDefault();
                    setPage(page + 1);
                  }}
                >
                  Load More
                </button>
              )}
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ChatMessagesDisputes;

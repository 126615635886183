import OnlyHeader from "components/Headers/OnlyHeader";
import CreateResolutionModal from "components/Modals/resolutionModals/CreateResolutionModal";
import DeleteResolutionModal from "components/Modals/resolutionModals/DeleteResolutionModal";
import UpdateResolutionModal from "components/Modals/resolutionModals/UpdateResolutionModal";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import {
  getResolutions,
  resolutionSearchByName,
} from "store/actions/resolutionAction";
const Resolution = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { resolutionPersons, totalPages, getResolutionLoader } = useSelector(
    (state) => state.resolution
  );
  const [teamID, setTeamID] = useState("");
  const [update, setUpdate] = useState(null);
  //ADD MODAL
  const [addModal, setAddModal] = useState(false);
  //DELETE MODAL
  const [deleteModal, setDeleteModal] = useState(false);
  const deleteResolutionModal = () => setDeleteModal(!deleteModal);
  //UPDATE MODAL
  const [updateModal, setUpdateModal] = useState(false);
  const updateResolutionModal = () => setUpdateModal(!updateModal);
  const [editData, setEditData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [resolutionByName, setResolutionByName] = useState("");
  console.log("resolutionByName: ", resolutionByName);

  const itemsPerPage = 5;

  const handleOnSumbit = () => {
    setCurrentPage(1);
    dispatch(resolutionSearchByName(resolutionByName, currentPage));
  };

  const handleClearSearch = () => {
    setResolutionByName("");
    dispatch(getResolutions(currentPage));
  };

  const addResolutionModal = () => {
    setAddModal(!addModal);
    setUpdate(null);
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // format the date and time
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  useEffect(() => {
    dispatch(getResolutions(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <>
      <CreateResolutionModal
        modal={addModal}
        toggle={addResolutionModal}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />

      <DeleteResolutionModal
        modal={deleteModal}
        id={teamID}
        toggle={deleteResolutionModal}
      />

      <UpdateResolutionModal
        modal={updateModal}
        toggle={updateResolutionModal}
        data={editData}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              {/* <CardHeader className="border-0">
                <div className="border-0 d-lg-flex w-100 justify-content-between">
                  <h3>Resolution Member</h3>
                  <Row className="align-items-center">
                    <Col lg={5} xs={4} className="pr-lg-0">
                      <FormGroup className="mb-lg-0">
                        <Input
                          id="ResolutionSearch"
                          name="Search"
                          placeholder="Search...."
                          type="text"
                          value={resolutionByName}
                          onChange={(e) => setResolutionByName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      lg={3}
                      xs={4}
                      className="px-lg-0 text-lg-center text-right"
                    >
                      <Button
                        color="primary"
                        className="mb-lg-0 mb-4 px-3"
                        onClick={handleOnSumbit}
                      >
                        Search
                      </Button>
                    </Col>
                    <Col lg={3} xs={4} className="mb-lg-0 mb-4 px-3">
                      {user?.role === "resolutionTeam" ? (
                        ""
                      ) : (
                        <Button color="primary" onClick={addResolutionModal}>
                          Create
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              </CardHeader> */}

              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">
                      Resolution Member
                    </h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="ResolutionSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={resolutionByName}
                          onChange={(e) => setResolutionByName(e.target.value)}
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Name
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        disabled={getResolutionLoader}
                        onClick={handleOnSumbit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        disabled={getResolutionLoader}
                        onClick={handleClearSearch}
                        size="sm"
                      >
                        Clear Search
                      </Button>

                      {user?.role === "resolutionTeam" ? (
                        ""
                      ) : (
                        <Button
                          color="primary"
                          className="p-2 mx-2 my-2 my-lg-0"
                          size="sm"
                          onClick={addResolutionModal}
                        >
                          Create
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Email</th>
                    {user?.role === "resolutionTeam" ? (
                      ""
                    ) : (
                      <th scope="col">Actions</th>
                    )}
                  </tr>
                </thead>

                {getResolutionLoader === false ? (
                  <tbody>
                    {resolutionPersons && resolutionPersons.length > 0 ? (
                      resolutionPersons?.map((team, index) => (
                        <tr key={index}>
                          <td>{team?.firstName}</td>
                          <td>{team?.lastName}</td>
                          <td>{formatTimestamp(team?.createdAt)}</td>
                          <td>{formatTimestamp(team?.updatedAt)}</td>
                          <td>{team?.email}</td>
                          {user?.role === "resolutionTeam" ? (
                            ""
                          ) : (
                            <td>
                              <Button color="primary" size="sm">
                                <i
                                  className="fa fa-edit"
                                  onClick={() => {
                                    updateResolutionModal();
                                    setEditData(team);
                                  }}
                                ></i>
                              </Button>
                              <Button color="danger" size="sm">
                                <i
                                  className="fa fa-trash"
                                  onClick={() => {
                                    deleteResolutionModal();
                                    setTeamID(team?.id);
                                  }}
                                ></i>
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <p className="mb-0 text-sm">
                            No Resolution Members Found.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>

              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Resolution;

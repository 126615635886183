import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const skill = RepositoryFactory.get("skills");

export const getSkillsAction = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SKILLS_LOADER",
      payload: true,
    });
    const { data } = await skill.getSkills(page, limit);
    dispatch({
      type: "GET_SKILLS",
      payload: data,
    });
    dispatch({
      type: "GET_SKILLS_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_SKILLS_LOADER",
      payload: false,
    });
  }
};

export const addNewSkill =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_SKILL_LOADER",
        payload: true,
      });

      const { data } = await skill.addSkill(payload);
      toast.success(data?.message);
      dispatch({
        type: "ADD_SKILL_LOADER",
        payload: false,
      });
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "ADD_SKILL_LOADER",
        payload: false,
      });
    }
  };

export const deleteSkill =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_SKILL_LOADER",
        payload: true,
      });
      const { data } = await skill.deleteSkill(id);

      dispatch({
        type: "DELETE_SKILL_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "SKILL_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "DELETE_SKILL_LOADER",
        payload: false,
      });
    }
  };

export const updateSkill =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "UPDATE_SKILL_LOADER",
        payload: true,
      });

      const { data } = await skill.updateSkill(id, payload);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "UPDATE_SKILL_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "UPDATE_SKILL_LOADER",
        payload: false,
      });
    }
  };

export const skillSearchByTitleAction = (title) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SKILLS_LOADER",
      payload: true,
    });
    const { data } = await skill.skillSearchByTitle(title);

    if (data?.success) {
      dispatch({
        type: "GET_SKILL_SEARCH_BY_TITLE",
        payload: data,
      });
      dispatch({
        type: "GET_SKILLS_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "GET_SKILLS_LOADER",
      payload: false,
    });
  }
};

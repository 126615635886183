import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const policies = RepositoryFactory.get("policy");

export const getAllPolicies = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_POLICIES_LOADER",
      payload: true,
    });
    const { data } = await policies.getPolicies(page, limit);
    console.log("data: ", data);
    dispatch({
      type: "GET_ALL_POLICIES",
      payload: data.data,
    });
    dispatch({
      type: "GET_POLICIES_LOADER",
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: "GET_POLICIES_LOADER",
      payload: false,
    });
    toast.error(err.response.data.message);
  }
};

export const policyDecisionAction =
  (id, status, toRemove) => async (dispatch) => {
    dispatch({
      type: "UPDATE_POLICIES_LOADER",
      payload: true,
    });
    try {
      let payload = {
        decision: status,
      };
      const { data } = await policies.PolicyDecision(id, payload);
      if (data?.success) {
        toast.success(data?.message);
        dispatch({
          type: "UPDATE_ARRAY",
          payload: { id, toRemove },
        });
      }
      dispatch({
        type: "UPDATE_POLICIES_LOADER",
        payload: false,
      });
    } catch (err) {
      dispatch({
        type: "UPDATE_POLICIES_LOADER",
        payload: false,
      });
      toast.error(err.response.data.message);
    }
  };

import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { getSubCategoriesAction } from "store/actions/categoriesAction";
import { addNewSubCategory } from "store/actions/categoriesAction";

const CreateSubCategoryModal = ({ modal, toggle, id }) => {
  const { addSubCategoryLoader } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const [subcategories, setSubcategories] = useState([{ name: "" }]);

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const updatedSubcategories = [...subcategories];
    updatedSubcategories[index][name] = value;
    setSubcategories(updatedSubcategories);
  };

  const addSubcategory = () => {
    setSubcategories([...subcategories, { name: "" }]);
  };

  const removeSubcategory = (index) => {
    const updatedSubcategories = [...subcategories];
    updatedSubcategories.splice(index, 1);
    setSubcategories(updatedSubcategories);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(addNewSubCategory({ id, subcategories }));
    dispatch(getSubCategoriesAction(id));
    toggle();
    setSubcategories([{ name: "" }]);
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Add Sub Categories</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            {subcategories &&
              subcategories.map((subcategory, index) => (
                <div key={index}>
                  <Row className="mb-3">
                    <Col md="8" className="m-auto">
                      <Label className="font-weight-bold">Sub Category</Label>
                      <FormGroup>
                        <Input
                          placeholder="Enter Sub Category"
                          name="name"
                          required
                          value={subcategory.name}
                          onChange={(e) => handleChange(e, index)}
                        />
                      </FormGroup>
                      {index === subcategories.length - 1 && (
                        <Button
                          color="primary"
                          className="ml-2"
                          onClick={addSubcategory}
                        >
                          +
                        </Button>
                      )}
                      {index !== 0 && (
                        <Button
                          color="danger"
                          className="ml-2"
                          onClick={() => removeSubcategory(index)}
                        >
                          -
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div>
              ))}
            <div className="d-flex justify-content-end align-items-center">
              <Button
                color="primary"
                type="submit"
                disabled={addSubCategoryLoader}
              >
                Add
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateSubCategoryModal;

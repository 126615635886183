import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { getCategoriesAction } from "store/actions/categoriesAction";
import { deleteCategory } from "store/actions/categoriesAction";

const DeleteCategoryModal = ({ modal, toggle, id, currentPage, itemsPerPage }) => {
  const dispatch = useDispatch();
  const { deleteCategoryLoader } = useSelector((state) => state.category);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Delete Category</ModalHeader>
        <ModalBody>Are you sure you want to delete ?</ModalBody>
        <ModalFooter>
        <Button
            color="primary"
            onClick={()=>toggle()
            }
          >
            Cancel
          </Button>
          <Button
            color="danger"
            disabled={deleteCategoryLoader}
            onClick={()=>{
              dispatch(deleteCategory(id,() => {
                toggle();
                dispatch(getCategoriesAction(currentPage, itemsPerPage));
              }))  
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteCategoryModal;

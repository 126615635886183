import { Container } from "reactstrap";
import ChatBox from "./ChatBox";
import { useDispatch, useSelector } from "react-redux";
import { getChatMessages } from "store/actions/chatAction";
import { useEffect, useState } from "react";
import upArrow from "../../assets/img/upArrow.png";
import dayjs from "dayjs";
import { Image } from "react-bootstrap";
import { scrollingMode } from "store/actions/chatAction";
import { toast } from "react-toastify";
import { error } from "jquery";

const ChatBody = ({ chatMessage, activeConversation, searchActive }) => {
  const dispatch = useDispatch();
  const { chatMessages, page, totalPages, messagesLoading } = useSelector(
    (state) => state.chats
  );
  const [modifiedChatMessages, setModifiedChatMessages] = useState([]);
  useEffect(() => {
    const restructuredData = chatMessage?.reduce((acc, message) => {
      const messageDate = dayjs(message?.createdAt).format("YYYY-MM-DD");
      const existingDateIndex = acc?.findIndex(
        (item) => item.date === messageDate
      );

      if (existingDateIndex !== -1) {
        acc[existingDateIndex]?.messages.push(message);
      } else {
        acc.push({
          date: messageDate,
          messages: [message],
        });
      }

      return acc;
    }, []);

    const updatedMessages = restructuredData?.map((group) => {
      const sortedMessages = group.messages
        .slice()
        .sort(
          (a, b) => dayjs(a.createdAt).valueOf() - dayjs(b.createdAt).valueOf()
        )
        .map((message) => {
          const isSender =
            message?.senderId?.id != activeConversation?.participantId;
          return {
            ...message,
            isSent: isSender,
          };
        });

      return {
        date: group.date,
        messages: sortedMessages,
      };
    });

    const sortedMessagesByDate = updatedMessages?.sort(
      (a, b) => dayjs(a.date).valueOf() - dayjs(b.date).valueOf()
    );

    setModifiedChatMessages(sortedMessagesByDate);
  }, [chatMessage, activeConversation]);
  return (
    <Container
      className={`${
        searchActive
          ? "supportchat-container-custom_height"
          : "supportchat-container"
      }  chat-scrollbar  `}
    >
      <div className={`flex justify-center `}>
        <button
          className={`mt-2 ${page == totalPages ? "hidden" : "block"}`}
          onClick={() => {}}
        >
          {messagesLoading ? (
            <div class=" h-8 w-4 text-indigo-700">
              <div class="absolute z-10 -ml-0 h-8 w-8 animate-bounce">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  class="animate-spin"
                  fill="currentColor"
                  stroke="currentColor"
                  stroke-width="0"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 0c-4.418 0-8 3.582-8 8s3.582 8 8 8 8-3.582 8-8-3.582-8-8-8zM8 4c2.209 0 4 1.791 4 4s-1.791 4-4 4-4-1.791-4-4 1.791-4 4-4zM12.773 12.773c-1.275 1.275-2.97 1.977-4.773 1.977s-3.498-0.702-4.773-1.977-1.977-2.97-1.977-4.773c0-1.803 0.702-3.498 1.977-4.773l1.061 1.061c0 0 0 0 0 0-2.047 2.047-2.047 5.378 0 7.425 0.992 0.992 2.31 1.538 3.712 1.538s2.721-0.546 3.712-1.538c2.047-2.047 2.047-5.378 0-7.425l1.061-1.061c1.275 1.275 1.977 2.97 1.977 4.773s-0.702 3.498-1.977 4.773z"></path>
                </svg>
              </div>
              <div
                class=" h-5 w-4 animate-bounce border-l-2 border-gray-200"
                style={{ rotate: "-90deg" }}
              ></div>
              <div
                class=" h-5 w-4 animate-bounce border-r-2 border-gray-200"
                style={{ rotate: "90deg" }}
              ></div>
            </div>
          ) : (
            <Image
              className="cursor-pointer"
              alt="Up Arrow"
              width={28}
              src={upArrow}
              onClick={async () => {
                await dispatch(scrollingMode(false));
                if (activeConversation?.conversationId) {
                  await dispatch(
                    getChatMessages({
                      id: activeConversation?.conversationId,
                      page: page + 1,
                      previousChat: chatMessages,
                    })
                  );
                } else {
                  toast.error(
                    "Conversation id id undefined",
                    activeConversation?.conversationId
                  );
                }
              }}
            />
          )}
        </button>
      </div>
      {modifiedChatMessages?.map((item) => {
        return (
          <>
            <div className="d-flex justify-content-center sticky-top ">
              <div className="border bg-white border-primary mt-2 text-primary font-weight-bold rounded-3 p-2 px-3 rounded-pill">
                {dayjs(item.date).format("DD/MM/YYYY")}
              </div>
            </div>
            {item.messages.map((message, index) => {
              return (
                <ChatBox
                  message={message}
                  index={index}
                  activeConversation={activeConversation}
                />
              );
            })}
          </>
        );
      })}
    </Container>
  );
};

export default ChatBody;

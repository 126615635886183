const initState = {
  uid: "",
  user: null,
  loading: false,
  authToken: null,
  forgetPasswordLoader: false,
  emailUpdateSuccess: false,
  updateEmailLoader: false,
  verifyEmailLoader: false,
  verifyPasswordLoader: false,
  email: "",
};
const authReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "LOGIN_SUCCESS":
      console.log(payload);
      return {
        ...state,
        uid: payload?.id,
        user: payload?.data,
        authToken: payload?.token,
      };
    case "EMAIL": {
      return {
        ...state,
        email: payload?.email,
      };
    }
    case "FOREGET_PASSWORD_LOADER": {
      return {
        ...state,
        forgetPasswordLoader: payload,
      };
    }
    case "EMAIL_UPDATE_SUCCESS":
      return {
        ...state,
        emailUpdateSuccess: true,
      };
    
    case "VERIFY_EMAIL_LOADER": {
      return {
        ...state,
        verifyEmailLoader: payload,
      };
    }
    case "VERIFY_PASSWORD_LOADER": {
      return {
        ...state,
        verifyPasswordLoader: payload,
      };
    }
    case "LOGIN_LOADER":
      return {
        ...state,
        loading: payload,
      };
    case "LOGOUT": {
      return {
        ...state,
        uid: "",
        user: null,
        authToken: null,
      };
    }
    default:
      return {
        ...state,
      };
  }
};
export default authReducer;

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";
const disputes = RepositoryFactory.get("disputes");

export const getAllDisputes = (status, page, limit) => async (dispatch) => {
  try {
    dispatch(disuteLoader(true));
    const { data } = await disputes.getDisputes(status, page, limit);
    dispatch({
      type: "GET_DISPUTES",
      payload: data,
    });
    dispatch(disuteLoader(false));
  } catch (error) {
    dispatch(disuteLoader(false));
    toast.error(error.response.data.message);
  }
};

export const disputeSearchByIdAction = (id, page) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_DISPUTES_LOADER",
      payload: true,
    });
    const { data } = await disputes.disputeSearchById(id, page);
    console.log(data, "idd data");

    if (data?.success) {
      dispatch({
        type: "GET_DISPUTE_SEARCH_BY_ID",
        payload: data.data,
      });
      dispatch({
        type: "GET_DISPUTES_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    // toast.error(error.response.data.message);
    dispatch({
      type: "GET_DISPUTES_LOADER",
      payload: false,
    });
  }
};

export const getSingleDispute = (id) => async (dispatch) => {
  try {
    dispatch(disuteLoader(true));
    const { data } = await disputes.getSingleDispute(id);
    if (data?.success) {
      dispatch({
        type: "SINGLE_DISPUTE",
        payload: data.data,
      });
      dispatch(disuteLoader(false));
    }
  } catch (error) {
    dispatch(disuteLoader(false));
    toast.error(error.response.data.message);
  }
};
export const getDisputeChat = (payload) => async (dispatch) => {
  dispatch(Loader(true));
  try {
    const { data } = await disputes.getDisputeComments(payload);
    dispatch({
      type: "SINGLE_DISPUTE_CHAT",
      payload: data,
    });
    dispatch(Loader(false));
  } catch (error) {
    dispatch(Loader(false));
    console.log({ error });
    dispatch({
      type: "EMPTY_DISPUTE_CHAT",
    });
    // toast.error(error.response.data.message);
  }
};
export const releaseSellerFunds =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(disuteLoader(true));
      const { data } = await disputes.releaseSellerAmount(payload);
      if (data?.success) {
        toast.success(data?.message);
        onSuccess();
        dispatch(disuteLoader(false));
      }
    } catch (error) {
      dispatch(disuteLoader(false));
      console.log({ error });
      toast.error(error.response.data.message);
    }
  };
export const refundBuyerFunds =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(disuteLoader(true));
      const { data } = await disputes.refundBuyerAmount(payload);
      if (data?.success) {
        toast.success(data?.message);
        onSuccess();
        dispatch(disuteLoader(false));
      }
    } catch (error) {
      dispatch(disuteLoader(false));
      console.log({ error });
      toast.error(error.response.data.message);
    }
  };

const disuteLoader = (val) => async (dispatch) => {
  dispatch({ type: "GET_DISPUTES_LOADER", payload: val });
};
const Loader = (val) => async (dispatch) => {
  dispatch({ type: "CHAT_ORDER_LOADER", payload: val });
};

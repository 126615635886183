import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const support = RepositoryFactory.get("support");

export const getAllSupportPersons = (page, limit) => async (dispatch) => {
  try {
    dispatch({ type: "GET_SUPPORT_LOADER", payload: true });
    const { data } = await support.GetSupportPersons(page, limit);

    dispatch({
      type: "GET_SUPPORT_PERSONS",
      payload: data.data,
    });
    dispatch({ type: "GET_SUPPORT_LOADER", payload: false });
  } catch (err) {
    console.log(err.message);
    dispatch({ type: "GET_SUPPORT_LOADER", payload: false });
  }
};

export const supportSearchByNameAction = (name) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_SUPPORT_LOADER",
      payload: true,
    });
    const { data } = await support.supportSearchByName(name);
    console.log(data, "Data");
    dispatch({
      type: "GET_SUPPORT_BY_NAME",
      payload: data?.data,
    });
    dispatch({
      type: "GET_SUPPORT_LOADER",
      payload: false,
    });
  } catch (error) {
    // toast.error(error.response.data.message);
    dispatch({
      type: "GET_SUPPORT_LOADER",
      payload: false,
    });
  }
};

export const deleteSupportAction =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_SUPPORT_LOADER",
        payload: true,
      });
      const { data } = await support.deleteSupportPerson(id);

      dispatch({
        type: "DELETE_SUPPORT_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "SUPPORT_PERSON_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.message);
      dispatch({
        type: "DELETE_SUPPORT_LOADER",
        payload: false,
      });
    }
  };

export const addNewSupportPerson =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_PERSON_LOADER",
        payload: true,
      });

      const { data } = await support.addSupportPerson(payload);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "ADD_PERSON_LOADER",
        payload: false,
      });
    } catch (err) {
      console.log("err: ", err);
      toast.error(err.response.data.message);
      dispatch({
        type: "ADD_PERSON_LOADER",
        payload: false,
      });
    }
  };

export const updateSupportPerson =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    console.log("id, payload: ", id, payload);
    try {
      dispatch({
        type: "UPDATE_PERSON_LOADER",
        payload: true,
      });

      const { data } = await support.updateSupportPerson(id, payload);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "UPDATE_PERSON_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "UPDATE_PERSON_LOADER",
        payload: false,
      });
    }
  };

export const searchSupportPersonAction =
  (name, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "SEARCH_SUPPORT_LOADER",
        payload: true,
      });
      const { data } = await support.searchSupportPerson(name);
      console.log("data: ", data);

      dispatch({
        type: "SEARCH_SUPPORT_LOADER",
        payload: false,
      });
      dispatch({
        type: "SEARCH_SUPPORT_PERSONS",
        payload: data?.data,
      });

      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "SEARCH_SUPPORT_LOADER",
        payload: false,
      });
    }
  };

export const getAllCountries = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_SUPPORT_LOADER", payload: true });
    const { data } = await support.getCountires();
    if (data?.success) {
      dispatch({
        type: "ALL_COUNTRIES",
        payload: data?.data,
      });
      dispatch({ type: "GET_SUPPORT_LOADER", payload: false });
    }
  } catch (error) {
    dispatch({ type: "GET_SUPPORT_LOADER", payload: false });
    console.log({ error });
  }
};

export const updateCountry =
  (payload, id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const { data } = await support.enableDisabelCountry(id, payload);
      if (data?.success) {
        dispatch(getAllCountries());
        onSuccess();
      }
    } catch (error) {
      console.log({ error });
    }
  };

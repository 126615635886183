import CryptoJS from "crypto-js";
import axios from "axios";
import { toast } from "react-toastify";

const baseDomain = process.env.REACT_APP_BASE_URL;
export const baseURL = `${baseDomain}`;

const instance = axios.create({
  baseURL,
  headers: {
    "Cache-Control": "no-cache",
  },
});
instance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const logoutKey = error.response.data.logout;
      const unAuth = error.response.status
      if (logoutKey || unAuth== 401) {
        toast.error(error?.response?.data?.message);
        setTimeout(() => {
          localStorage.removeItem("auth");
          window.location.href = "/login";
        }, 3000);
        return Promise.reject(error);
      } else {
        return Promise.reject(error);
      }
    }
  }
);

instance.interceptors.request.use((config) => {
  const serializedUid = localStorage.getItem("auth");
  if (serializedUid !== null) {
    let auth = JSON.parse(
      CryptoJS.AES.decrypt(serializedUid, "my-secret-key").toString(
        CryptoJS.enc.Utf8
      )
    );

    config.headers.Authorization = `Bearer ${auth.authToken}`;
  }
  return config;
});

export default instance;

import ChatMessagesTickets from "components/ChatMessagesTickets";
import OnlyHeader from "components/Headers/OnlyHeader";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import AssignTicketModal from "components/Modals/ticketsModal/AssignTicketModal";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { Button, Card, CardHeader, Container, Row, Spinner } from "reactstrap";
import {
  changeTicketStatusAction,
  getSingleTicket,
} from "store/actions/ticketsAction";

const TicketsDetails = () => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const dispatch = useDispatch();
  const { id } = useParams();
  const { singleTicket, allTicketsLoader } = useSelector(
    (state) => state.tickets
  );
  console.log(">>>singleTicket: ", singleTicket);
  const [ticketChat, setTicketChat] = useState();
  const history = useHistory();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");
  const [userId, setUserId] = useState("");

  const handleGoBack = () => {
    history.goBack();
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleMarkAsResolveModal = (id) => {
    setUserId(id);
    setMessage("Are you sure you want to mark as resolve this ticket?");
    toggleConfirmationModal();
  };

  const handleReopenTicketModal = (id) => {
    setUserId(id);
    setMessage("Are you sure you want to reopen ticket this ticket?");
    toggleConfirmationModal();
  };

  const handleResolveConfirmationOk = () => {
    console.log("userId: ", userId);
    singleTicket?.status === "closed"
      ? dispatch(changeTicketStatusAction(userId, "in progress"))
      : dispatch(changeTicketStatusAction(userId, "closed"));
    toggleConfirmationModal();
  };

  const handleConfirmationCancel = () => {
    toggleConfirmationModal();
  };

  useEffect(() => {
    dispatch(getSingleTicket(id));
  }, []);

  useEffect(() => {
    if (singleTicket) {
      setTicketChat(singleTicket);
    }
  }, [singleTicket]);

  return (
    <>
      <AssignTicketModal
        modal={modal}
        toggle={toggle}
        singleTicket={singleTicket}
      />

      <ConfirmationModal
        modal={confirmationModal}
        toggle={toggleConfirmationModal}
        userId={userId}
        message={message}
        onConfirm={handleResolveConfirmationOk}
        onCancel={handleConfirmationCancel}
      />

      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0 d-lg-flex justify-content-between align-items-center">
                <h3
                  className="mb-0 cursor-pointer text-sm"
                  onClick={handleGoBack}
                >
                  <i class="fas fa-chevron-left mr-1"></i>Ticket Details
                </h3>
                <div className="d-flex justify-content-end align-items-center">
                  {singleTicket?.assignedTo === null ? (
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => {
                        toggle();
                      }}
                    >
                      Assign Ticket
                    </Button>
                  ) : (
                    <Button size="sm" color="primary" disabled>
                      Already Assigned
                    </Button>
                  )}

                  {singleTicket?.status === "closed" ? (
                    <Button size="sm" color="primary" disabled>
                      Closed
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => handleMarkAsResolveModal(id)}
                    >
                      Mark As Resolve
                    </Button>
                  )}
                  {singleTicket?.status === "in progress" ? (
                    <Button size="sm" color="primary" disabled>
                      In Progress
                    </Button>
                  ) : (
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => handleReopenTicketModal(id)}
                    >
                      Reopen Ticket
                    </Button>
                  )}
                </div>
              </CardHeader>

              <div className="p-2 p-lg-5">
                <div className="d-md-flex justify-content-between mb-3">
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0">Status :</h4>
                    <p className="mb-0 text-capitalize">{singleTicket?.status}</p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0 text-capitalize">Created By : </h4>
                    <p className="mb-0">
                      {singleTicket?.createdBy?.firstName}{" "}
                      {singleTicket?.createdBy?.lastName}
                    </p>
                  </div>
                  <div className="d-flex align-items-center">
                    <h4 className="mb-0">Ticket No:</h4>
                    <p className="mb-0">{singleTicket?.ticketNumber}</p>
                  </div>
                </div>
                {singleTicket?.subject && (
                  <div className="d-md-flex align-items-center mb-2">
                    <h4 className="mb-0">Subject : </h4>
                    <p className="mb-0 text-capitalize">{singleTicket?.subject}</p>
                  </div>
                )}
                {singleTicket?.description && (
                  <div>
                    <h4 className="mb-0">Desrciption :</h4>
                    <p className="mb-1 ">{singleTicket?.description}</p>
                  </div>
                )}
                {allTicketsLoader ? (
                  <Row className="d-flex justify-content-center">
                    <Spinner size="sm" />
                  </Row>
                ) : (
                  <ChatMessagesTickets ticketChat={ticketChat} />
                )}
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default TicketsDetails;

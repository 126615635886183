import Repository from "./Repository";
const GET_NAME_REQUESTS = "/name-changed-request/pending";
const REQUEST_DECISION = "/name-changed-request/status/";

export default {
  getNameRequests(page, limit) {
    return Repository.get(`${GET_NAME_REQUESTS}?page=${page}&limit=${limit}`);
  },
  nameRequestDecision(id, payload) {
    return Repository.put(`${REQUEST_DECISION}${id}`, payload);
  },
};

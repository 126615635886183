import Repository from "./Repository";
const GET_ALL_TAXES = "tax/all";
const GET_PREVIOUS_TAXES = "tax/all";
const SUBMIT_TAX = "tax";
const DELETE_TAX = "tax";

export default {
  getAllTaxes(id, year) {
    return Repository.get(`${GET_ALL_TAXES}/${id}?year=${year}`);
  },
  getPreviousTaxes(id) {
    return Repository.get(`${GET_PREVIOUS_TAXES}/${id}`);
  },
  deleteTax(id) {
    return Repository.delete(`${DELETE_TAX}/${id}`);
  },
  submitTax(payload) {
    return Repository.post(`${SUBMIT_TAX}`, payload, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
  },
};

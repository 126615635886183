const initState = {
  allUsers: [],
  singleUser: null,
  totalPages: null,
  usersSupportChat: [],
  accountSuspendLoader: false,
  getUsersLoader: false,
  passwordUpdateLoader: false,
};
const usersReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_USERS_LOADER": {
      return {
        ...state,
        getUsersLoader: payload,
      };
    }
    case "GET_USERS_SUPPORT_CHAT": {
      return {
        ...state,
        usersSupportChat: payload.data,
      };
    }
    case "REMOVE_USERS_SUPPORT_CHAT": {
      console.log("payload REMOVE", payload);
      console.log("payload", payload);
      return {
        ...state,
        usersSupportChat: payload,
      };
    }
    case "GET_SINGLE_USER": {
      return {
        ...state,
        singleUser: payload,
      };
    }
    case "GET_USERS":
      return {
        ...state,
        allUsers: payload.users,
        totalPages: payload.totalPages,
      };
    case "USER_SEARCH": {
      return {
        ...state,
        allUsers: payload,
        totalPages: payload?.totalPages,
      };
    }
    case "ACC_ACTIVATED":
      return {
        ...state,
        allUsers: state.allUsers.map((user) => {
          if (user.id === payload.id) {
            return {
              ...user,
              status: payload.data?.status,
            };
          }
          return user;
        }),
        totalPages: payload.data?.totalPages,
      };

    case "ACCOUNT_SUSPENDED":
      return {
        ...state,
        accountSuspendLoader: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default usersReducer;

import React from "react";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { Button, Spinner } from "reactstrap";
import { VerifyEmailAction } from "store/actions/authAction";

const VerifyEmail = () => {
  const dispatch = useDispatch();
  const { verifyEmailLoader } = useSelector((state) => state.auth);
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get("token");
  useEffect(() => {
    dispatch(VerifyEmailAction(token));
  }, [token]);
  return (
    <div>
      <div className="h-full align-items-center d-flex w-full justify-content-center ">
        <div className="d-flex flex-column gap-4 justify-content-center align-items-center">
          {!verifyEmailLoader ? (
            <>
              <h3 className="text-white">
                Thanks for verifying you email you email is verified now !
              </h3>
              <div className="d-flex justify-content-center align-items-center mt-3">
                <Link to="/admin/index">
                  <Button className="px-10  py-2 bg-primary text-white ">
                    Go to Dashboard
                  </Button>
                </Link>
              </div>
            </>
          ) : (
            <Spinner size="lg" />
          )}
        </div>
      </div>
    </div>
  );
};

export default VerifyEmail;

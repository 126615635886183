import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const stats = RepositoryFactory.get("stats");

export const getStats = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_STATS_LOADER", payload: true });
    const { data } = await stats.getAllStats();

    dispatch({
      type: "GET_STATS",
      payload: data.data,
    });
    dispatch({ type: "GET_STATS_LOADER", payload: false });
  } catch (err) {
    toast.error(err.response.data.message);

    dispatch({ type: "GET_STATS_LOADER", payload: false });
  }
};
export const demographicInfoAction = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_DEMOGRAPHIC_LOADER", payload: true });
    const { data } = await stats.getDemographicInfo();

    dispatch({
      type: "GET_DEMOGRAPHIC_INFO",
      payload: data.data,
    });
    dispatch({ type: "GET_DEMOGRAPHIC_LOADER", payload: false });
  } catch (err) {
    toast.error(err.response.data.message);

    dispatch({ type: "GET_DEMOGRAPHIC_LOADER", payload: false });
  }
};

export const getCountryRevenueAction = () => async (dispatch) => {
  try {
    dispatch({ type: "GET_REVENUE_LOADER", payload: true });
    const { data } = await stats.getCountryRevenue();
    console.log("data: ", data);

    dispatch({
      type: "GET_REVENUE_INFO",
      payload: data.revenue,
    });
    dispatch({ type: "GET_REVENUE_LOADER", payload: false });
  } catch (err) {
    toast.error(err.response.data.message);

    dispatch({ type: "GET_REVENUE_LOADER", payload: false });
  }
};

export const createPublicNotification =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      const { data } = await stats.addNotification(payload);
      if (data?.success) {
        toast.success(data?.message);
        onSuccess();
      }
    } catch (error) {
      toast.error(error.response.data.message);
    }
  };
export const getPublicNotification = () => async (dispatch) => {
  try {
    const { data } = await stats.publicNotification();
    if (data?.success) {
      dispatch({
        type: "PUBLIC_MESSAGE",
        payload: data.data,
      });
      // toast.success(data?.message);
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const updatePublicNotification =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: "PUBLIC_LOADER", payload: true });
    try {
      const { data } = await stats.updateNotification(payload);
      if (data?.success) {
        toast.success(data?.message);
        onSuccess();
      }
      dispatch({ type: "PUBLIC_LOADER", payload: false });
    } catch (error) {
      dispatch({ type: "PUBLIC_LOADER", payload: false });
      toast.error(error.response.data.message);
    }
  };
export const deletePublicNotification =
  (onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({ type: "PUBLIC_LOADER", payload: true });
    try {
      const { data } = await stats.deleteNotification();
      if (data?.success) {
        toast.success(data?.message);
        onSuccess();
        dispatch({
          type: "DELETE_MESSAGE",
          // payload: null,
        });
      }
      dispatch({ type: "PUBLIC_LOADER", payload: false });
    } catch (error) {
      dispatch({ type: "PUBLIC_LOADER", payload: false });
      toast.error(error.response.data.message);
    }
  };

export const getUserEarnings = (id, role) => async (dispatch) => {
  try {
    const { data } = await stats.userEarnings(id, role);
    if (data?.success) {
      dispatch({
        type: "EARNINGS_STATS",
        payload: data?.data,
      });
      console.log({ data });
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const getUserCharts = (id, role) => async (dispatch) => {
  try {
    const { data } = await stats.userChartStats(id, role);
    if (data?.success) {
      dispatch({
        type: "CHARTS_STATS",
        payload: data?.data,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
  }
};
export const getUserOrders = (payload) => async (dispatch) => {
  dispatch({ type: "GET_STATS_LOADER", payload: true });
  try {
    const { data } = await stats.userOrders(payload);
    if (data?.success) {
      dispatch({
        type: "USER_ORDERS",
        payload: data?.data,
      });
      dispatch({ type: "GET_STATS_LOADER", payload: false });
    }
  } catch (error) {
    dispatch({ type: "GET_STATS_LOADER", payload: false });
    toast.error(error.response.data.message);
  }
};
export const getCurrentMonthSales = (payload) => async (dispatch) => {
  try {
    const { data } = await stats.userCurrentMonthOrders(payload);
    console.log(data, "current month ", payload.role);
    dispatch({
      type: "CURRENT_MONTH_SALES",
      payload: data?.data,
    });
  } catch (error) {
    toast.error(error.response.data.message);
  }
};

export const paymentLogsAction = (page) => async (dispatch) => {
  dispatch({ type: "GET_STATS_LOADER", payload: true });
  try {
    const { data } = await stats.getPaymentLogs(page);
    if (data?.success) {
      dispatch({
        type: "PAYMENT_LOGS",
        payload: data?.data,
      });
    }
    dispatch({ type: "GET_STATS_LOADER", payload: false });
  } catch (error) {
    dispatch({ type: "GET_STATS_LOADER", payload: false });
    toast.error(error.response.data.message);
  }
};
export const searchPaymentLogs = (query, page) => async (dispatch) => {
  dispatch({ type: "GET_STATS_LOADER", payload: true });
  try {
    const { data } = await stats.searchLogs(query, page);
    dispatch({
      type: "SEARCH_PAYMENT_LOGS",
      payload: data?.data,
    });
    dispatch({ type: "GET_STATS_LOADER", payload: false });
  } catch (error) {
    dispatch({ type: "GET_STATS_LOADER", payload: false });
    toast.error(error.response.data.message);
  }
};

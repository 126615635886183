import Repository from "./Repository";
const GET_ALL = "support/";
const ADD_SUPPORT = "support/";
const DELETE_SUPPORT = "support/";
const UPDATE_SUPPORT = "support/";
const SEARCH_SUPPORT_PERSON = "support/search/";
const GET_COUNTRIES = "/global/country/all";
const ENABLE_DISABLE = "/global/country";

export default {
  GetSupportPersons(page, limit) {
    return Repository.get(`${GET_ALL}?page=${page}&limit=${limit}`);
  },
  supportSearchByName(name) {
    return Repository.post(`${SEARCH_SUPPORT_PERSON}?name=${name}`);
  },
  addSupportPerson(payload) {
    return Repository.post(`${ADD_SUPPORT}`, payload);
  },
  updateSupportPerson(id, payload) {
    return Repository.put(`${UPDATE_SUPPORT}/${id}`, payload);
  },
  deleteSupportPerson(id) {
    return Repository.delete(`${DELETE_SUPPORT}/${id}`);
  },
  searchSupportPerson(name) {
    return Repository.post(`${SEARCH_SUPPORT_PERSON}?name=${name}`);
  },
  getCountires() {
    return Repository.get(`${GET_COUNTRIES}`);
  },
  enableDisabelCountry(id, payload) {
    return Repository.put(`${ENABLE_DISABLE}/${id}`, payload);
  },
};

import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { getSkillsAction } from "store/actions/skillsAction";
import { updateSkill } from "store/actions/skillsAction";

const EditSkillModal = ({
  modal,
  toggle,
  skill,
  currentPage,
  itemsPerPage,
}) => {
  const dispatch = useDispatch();
  const { updateSkillLoader } = useSelector((state) => state.skills);
  const [singleSkill, setSingleSkill] = useState(null);

  const handleChange = (e) => {
    setSingleSkill({
      ...singleSkill,
      name: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!singleSkill?.name.trim()) {
      toast.error("Add skill name");
      return;
    }

    dispatch(
      updateSkill(singleSkill._id, singleSkill, () => {
        toggle();
        setSingleSkill(null);
        dispatch(getSkillsAction(currentPage, itemsPerPage));
      })
    );
  };

  useEffect(() => {
    if (skill) {
      setSingleSkill(skill);
    } else {
      setSingleSkill(null);
    }
  }, [skill]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Update Skill</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Skill</Label>
                <FormGroup>
                  <Input
                    type="text"
                    name="name"
                    placeholder="Enter Skill"
                    defaultValue={singleSkill?.name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                color="primary"
                type="submit"
                disabled={updateSkillLoader}
              >
                {updateSkillLoader ? <Spinner size="sm" /> : "Update"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditSkillModal;

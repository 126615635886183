const initState = {
  allReports: [],
  getReportsLoader: false,
  deleteCategoryLoader: false,
  singleReport: {},
};

const reportsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_REPORTS":
      return {
        ...state,
        allReports: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_REPORTS_LOADER":
      return {
        ...state,
        getReportsLoader: payload,
      };
    case "DELETE_REPORT_LOADER":
      return {
        ...state,
        deleteReportLoader: payload,
      };
    case "GET_REPORT_SEARCH_BY_NAME":
      return {
        ...state,
        allReports: payload.data,
        totalPages: payload.totalPages,
      };
    case "GET_SINGLE_REPORT":
      return {
        ...state,
        singleReport: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default reportsReducer;

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const tax = RepositoryFactory.get("tax");

export const getAllTaxesAction = (id, year) => async (dispatch) => {
  try {
    const { data } = await tax.getAllTaxes(id, year);

    dispatch({
      type: "GET_TAXES",
      payload: data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
export const getPreviousTaxesAction = (id) => async (dispatch) => {
  try {
    const { data } = await tax.getPreviousTaxes(id);

    dispatch({
      type: "GET_PREVIOUS_TAXES",
      payload: data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};
export const submitTaxFileAction =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({ type: "SUBMIT_TAX_LOADER", payload: true });
      const { data } = await tax.submitTax(payload);

      if (data?.success) {
        toast.success(data?.message);
      }

      dispatch({ type: "SUBMIT_TAX_LOADER", payload: false });

      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({ type: "SUBMIT_TAX_LOADER", payload: false });
    }
  };

export const deleteTaxAction =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch(taxLoader(true));
      const { data } = await tax.deleteTax(payload);

      if (data?.success) {
        toast.success(data?.message);
      }
      dispatch({ type: "DELETE_TAX", payload: payload });
      onSuccess();
      dispatch(taxLoader(false));
    } catch (err) {
      dispatch(taxLoader(false));
      toast.error(err.response.data.message);
    }
  };
export const deletePreviousTaxAction =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch(taxLoader(true));
    try {
      const { data } = await tax.deleteTax(payload);

      if (data?.success) {
        toast.success(data?.message);
      }
      dispatch({ type: "DELETE_PREVIOUS_TAX", payload: payload });
      onSuccess();
      dispatch(taxLoader(false));
    } catch (err) {
      dispatch(taxLoader(false));
      toast.error(err.response.data.message);
    }
  };

export const taxLoader = (val) => async (dispatch) => {
  dispatch({
    type: "TAX_LOADER",
    payload: val,
  });
};

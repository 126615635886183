import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import DatePicker from "react-datepicker";
import moment from "moment";
import { exportUsers } from "store/actions/usersAction";
import { useDispatch } from "react-redux";
import Papa from "papaparse";
import { toast } from "react-toastify";

const UserCSVModal = ({ modal, toggle }) => {
  const dispatch = useDispatch();
  const [startDate, setStartDate] = useState(null);
  const [loader, setLoader] = useState(false);
  const [endDate, setEndDate] = useState(null);
  const [isEndDateDisabled, setIsEndDateDisabled] = useState(false);
  const handleConfirm = () => {
    if (!startDate || !endDate || startDate === "" || endDate === "") {
      toast.error("Both Fields Are Required");
      return;
    }
    setLoader(true);
    let data = {
      startDate: moment(startDate).format("YYYY-MM-DD"),
      endDate: moment(endDate).format("YYYY-MM-DD"),
    };
    dispatch(
      exportUsers(
        data,
        (data) => {
          if (data?.data?.length > 0) {
            exportToCsv(data.data);
            toggle();
          } else {
            toast.warning("There is no user signup in this date range ");
          }

          setLoader(false);
        },
        () => {
          setLoader(false);
        }
      )
    );
  };

  const exportToCsv = (data) => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: "text/csv;charset=utf-8;" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute("download", "Tealpot-Registered-Users.csv");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    setIsEndDateDisabled(!startDate);
  }, [startDate]);
  useEffect(() => {
    if (modal) {
      setStartDate(moment().startOf("year").startOf("month").toDate());
      setEndDate(new Date());
    }
  }, [modal]);
  return (
    <Modal isOpen={modal} toggle={toggle} centered>
      <ModalHeader toggle={toggle} className="pb-0 pt-2 px-2"></ModalHeader>
      <ModalBody className="p-2 pt-0">
        <h1 className="text-center">Export User Data</h1>
        <div className="py-3 d-flex flex-column align-items-center">
          <div className="m-2">
            <label className="mr-2">Start Date :</label>
            <DatePicker
              className="px-2 border rounded-lg pointer-event"
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              maxDate={new Date()} // Disable future dates
              dateFormat="MM/dd/yyyy" // US date format
            />
          </div>
          <div className="m-2">
            <label className="mr-2">End Date :</label>
            <DatePicker
              className="px-2 border rounded-lg"
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              minDate={startDate} // Ensure end date is after start date
              maxDate={new Date()} // Disable future dates
              disabled={isEndDateDisabled}
              dateFormat="MM/dd/yyyy" // US date format
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          color="primary"
          onClick={handleConfirm}
          size="sm"
          disabled={loader} // Disable button when loading
        >
          {loader ? <Spinner size="sm" /> : "Confirm"}
        </Button>
        <Button color="secondary" onClick={toggle} size="sm">
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default UserCSVModal;

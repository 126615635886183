import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";
import { getSubCategoriesAction } from "store/actions/categoriesAction";
import { updateSubCategory } from "store/actions/categoriesAction";

const EditSubCategoryModal = ({ modal, toggle, subCategory, categoryId }) => {
  const { updateSubCategoryLoader } = useSelector((state) => state.category);
  const dispatch = useDispatch();
  const [categoryName, setCategoryName] = useState("");
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCategoryName({
      [name]: value,
    });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(
      updateSubCategory(subCategory._id, categoryName, () => {
        toggle();
        setCategoryName("");
        dispatch(getSubCategoriesAction(categoryId));
      })
    );
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Update Sub Category</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Sub Category</Label>
                <FormGroup>
                  <Input
                    placeholder="Enter Sub Category"
                    name="name"
                    defaultValue={subCategory?.name}
                    onChange={handleChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                color="primary"
                type="submit"
                disabled={updateSubCategoryLoader}
              >
                Update
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default EditSubCategoryModal;

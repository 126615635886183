import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getSkillsAction } from "store/actions/skillsAction";
import { deleteSkill } from "store/actions/skillsAction";

const DeleteSkillModal = ({ modal, toggle, id, currentPage, itemsPerPage }) => {
  const dispatch = useDispatch();
  const { deleteSkillLoader } = useSelector((state) => state.skills);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Delete Skill</ModalHeader>
        <ModalBody>Are you sure you want to delete ?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggle()}>
            Cancel
          </Button>
          <Button
            color="danger"
            disabled={deleteSkillLoader}
            onClick={() => {
              dispatch(
                deleteSkill(id, () => {
                  toggle();
                  dispatch(getSkillsAction(currentPage, itemsPerPage));
                })
              );
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteSkillModal;

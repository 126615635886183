import Img from "../../assets/img/brand/favicon.png";

const ChatHeader = ({ toggle, activeConversation }) => {
  return (
    <div className="d-flex p-2 bg-lighter align-items-center">
      <h1
        onClick={toggle}
        className="m-0 pr-2 d-block d-xl-none cursor-pointer"
      >
        {"<"}
      </h1>
      <img
        alt="img"
        src={
          activeConversation?.profileImage
            ? activeConversation?.profileImage
            : Img
        }
        className="rounded-circle objectFit-cover"
        width="40px"
        height="40px"
      />
      <div>
        <h4 className="m-0 pl-2">{activeConversation?.username}</h4>
        <h6 className="m-0 pl-2">{activeConversation?.isOnline && "Online"}</h6>
      </div>
    </div>
  );
};

export default ChatHeader;

import classnames from "classnames";
import { useEffect, useState } from "react";
import {
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ChatMessagesDisputes from "./ChatMessagesDisputes";
import GoogleMap from "./GoogleMap";
import { formatDate } from "Contants";
import { useDispatch, useSelector } from "react-redux";
import { getDisputeChat } from "store/actions/disputesAction";

const DisputesTab = ({ dispute, page, setPage }) => {
  console.log({ dispute });
  const { disputeChat } = useSelector((state) => state.disputes);
  const [activeTab, setActiveTab] = useState("1");
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <div>
      <Nav tabs responsive>
        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => toggle("1")}
          >
            Info
          </NavLink>
        </NavItem>

        <NavItem className="cursor-pointer">
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => toggle("2")}
          >
            Chat
          </NavLink>
        </NavItem>
        {dispute?.orderId?.deliverables?.length > 0 && (
          <NavItem className="cursor-pointer">
            <NavLink
              className={classnames({ active: activeTab === "3" })}
              onClick={() => toggle("3")}
            >
              Deliverables
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1" className="mt-3">
          <Row>
            <Col md={6} sm="12">
              <FormGroup>
                <Label>Opened By</Label>
                <Input
                  type="text"
                  className="text-capitalize"
                  value={dispute?.type}
                  readOnly
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Dispute Details</Label>
                <Input
                  type="textarea"
                  className="text-capitalize"
                  value={dispute?.description}
                  readOnly
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Buyer Name</Label>
                <Input
                  type="text"
                  value={
                    dispute?.buyer?.firstName + " " + dispute?.buyer?.lastName
                  }
                  readOnly
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Seller Name</Label>
                <Input
                  type="text"
                  value={
                    dispute?.seller?.firstName + " " + dispute?.seller?.lastName
                  }
                  readOnly
                ></Input>
              </FormGroup>
              <FormGroup>
                <Label>Seller Price</Label>
                <Input
                  type="text"
                  value={dispute?.orderId?.totalPrice}
                  readOnly
                ></Input>
              </FormGroup>

              <FormGroup>
                <Label>Checked In</Label>
                {/* "24-10-2023 12:00pm" */}
                <Input
                  type="text"
                  value={formatDate(dispute?.createdAt)}
                  readOnly
                ></Input>
              </FormGroup>
            </Col>
            <Col md={6} sm="12">
              <GoogleMap order={dispute?.orderId} />
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2" className="mt-5">
          <ChatMessagesDisputes
            dispute={dispute}
            disputeChat={disputeChat}
            page={page}
            setPage={setPage}
          />
        </TabPane>
        <TabPane tabId="3" className="mt-5">
          {dispute?.orderId?.deliverables.map((deliverable, index) => {
            if (deliverable.endsWith(".pdf?alt=media")) {
              return (
                <div
                  key={index}
                  className="m-2"
                  style={{
                    position: "relative",
                    width: "200px",
                    height: "200px",
                  }}
                >
                  <object
                    data={deliverable}
                    type="application/pdf"
                    width="200"
                    height="200"
                    style={{ position: "relative" }}
                    aria-hidden="true"
                  ></object>
                  <a
                    href={deliverable}
                    download={`file_${index}.pdf`}
                    className="btn btn-primary"
                    style={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                    }}
                  >
                    Download
                  </a>
                </div>
              );
            } else {
              return (
                <img
                  key={index}
                  src={deliverable}
                  width={200}
                  height={200}
                  className="cursor-pointer m-2"
                  onClick={() => handleImageClick(deliverable)}
                  alt={`Image ${index}`}
                />
              );
            }
          })}
        </TabPane>
      </TabContent>
      <Modal
        isOpen={isImageModalOpen}
        toggle={() => setImageModalOpen(!isImageModalOpen)}
      >
        <ModalHeader toggle={() => setImageModalOpen(!isImageModalOpen)}>
          Image Preview
        </ModalHeader>
        <ModalBody>
          <img src={selectedImage} alt="Preview" style={{ width: "100%" }} />
        </ModalBody>
      </Modal>
    </div>
  );
};

export default DisputesTab;

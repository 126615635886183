import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import { refundBuyerFunds } from "store/actions/disputesAction";

const RefundToBuyerModal = ({ modal, toggle, dispute }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { getDisputesLoader } = useSelector((state) => state.disputes);
  const [releaseOption, setReleaseOption] = useState("specificAmount");
  const [specificAmount, setSpecificAmount] = useState("");

  const handleRadioChange = (event) => {
    setReleaseOption(event.target.value);
  };

  const handleReleaseAmount = (e) => {
    e.preventDefault();
    let payload = {
      orderId: dispute?.orderId?._id,
    };
    if (releaseOption === "specificAmount") {
      payload.amount = Number(specificAmount);
    } else {
      payload.amount = dispute?.orderId?.totalPrice;
    }
    dispatch(
      refundBuyerFunds(payload, () => {
        toggle();
        setSpecificAmount("");
        history.push("/admin/disputes");
      })
    );
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Refunding To Buyer</ModalHeader>
        <ModalBody>
          <div>
            <div className="mb-3">
              <b>
                Do you want to refund full amount or specific amount? Select
                Below
              </b>
            </div>
            <form>
              <div className="form-group form-check">
                <input
                  type="radio"
                  name="releaseOption"
                  value="specificAmount"
                  className="mr-2 form-check-input"
                  checked={releaseOption === "specificAmount"}
                  onChange={handleRadioChange}
                />
                Refund Specific Amount
              </div>

              <div className="form-group form-check">
                <input
                  type="radio"
                  name="releaseOption"
                  value="fullAmount"
                  className="mr-2 form-check-input cursor-pointer"
                  checked={releaseOption === "fullAmount"}
                  onChange={handleRadioChange}
                />
                Refund Full Amount
              </div>

              <br />

              {releaseOption === "specificAmount" && (
                <>
                  <b htmlFor="specificAmount">Enter Specific Amount:</b>
                  <div className="d-flex justify-content-start align-items-center">
                    <input
                      type="number"
                      className="form-control mt-3 w-75 w-md-50 cursor-pointer"
                      name="specificAmount"
                      placeholder="0000.00"
                      value={specificAmount}
                      onChange={(e) => setSpecificAmount(e.target.value)}
                    />
                    <label className="mt-4 mx-2 font-weight-bold"> USD</label>
                  </div>
                </>
              )}

              {releaseOption === "fullAmount" && (
                <div className="mt-3">
                  <Button
                    type="button"
                    color="primary"
                    size="lg"
                    disabled={getDisputesLoader}
                    onClick={handleReleaseAmount}
                  >
                    {getDisputesLoader ? (
                      <Spinner size="sm" className="mx-4" />
                    ) : (
                      "Release Full Amount"
                    )}
                  </Button>
                </div>
              )}
            </form>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            size="sm"
            onClick={handleReleaseAmount}
            disabled={getDisputesLoader}
          >
            {getDisputesLoader ? (
              <Spinner size="sm" className="mx-2" />
            ) : (
              "Save"
            )}
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default RefundToBuyerModal;

const initState = {
  allJobs: [],
  searchData: [],
  deleteJobLoader: false,
};

const deletedJobReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_DELETED_JOBS":
      return {
        ...state,
        allJobs: payload.data,
        totalPages: payload.totalPages,
      };
    case "DELETED_JOB_LOADER":
      return {
        ...state,
        deleteJobLoader: payload,
      };
    case "GET_JOB_BY_NAME":
      return {
        ...state,
        allJobs: payload.data,
        totalPages: payload.totalPages,
      };
    default:
      return state;
  }
};

export default deletedJobReducer;

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const reports = RepositoryFactory.get("reports");

export const getAllReports = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_REPORTS_LOADER",
      payload: true,
    });
    const { data } = await reports.getReports(page, limit);

    dispatch({
      type: "GET_REPORTS",
      payload: data,
    });
    dispatch({
      type: "GET_REPORTS_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_REPORTS_LOADER",
      payload: false,
    });
  }
};

export const deleteReport =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_REPORT_LOADER",
        payload: true,
      });
      const { data } = await reports.deleteReport(id);

      dispatch({
        type: "DELETE_REPORT_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "REPORT_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "DELETE_REPORT_LOADER",
        payload: false,
      });
    }
  };

export const getSingleReport = (id) => async (dispatch) => {
  dispatch({ type: "GET_REPORTS_LOADER", payload: true });

  try {
    const { data } = await reports.getSingleReport(id);

    dispatch({
      type: "GET_SINGLE_REPORT",
      payload: data.data,
    });
    dispatch({ type: "GET_REPORTS_LOADER", payload: false });
  } catch (err) {
    dispatch({ type: "GET_REPORTS_LOADER", payload: false });
    console.log(err.message);
  }
};

export const ReportSearchByNameAction = (name) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_REPORTS_LOADER",
      payload: true,
    });
    const { data } = await reports.reportSearchByName(name);

    if (data?.success) {
      dispatch({
        type: "GET_REPORT_SEARCH_BY_NAME",
        payload: data,
      });
      dispatch({
        type: "GET_REPORTS_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "GET_REPORTS_LOADER",
      payload: false,
    });
  }
};

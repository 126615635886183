const initState = {
  allConversations: [],
  chatMessages: [],
  messagesLoading: false,
  SendMessageResponse: null,
  conversationLoading: false,
  scrolling: true,
  totalMessages: 0,
  totalPages: 0,
  activeConversation: null,
  page: 0,
};
const chatsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "ADMIN_SEND_MESSAGE": {
      return {
        ...state,
        SendMessageResponse: payload,
        chatLoading: false,
      };
    }
    case "ADMIN_CONVERSATION_LOADER": {
      return {
        ...state,
        conversationLoading: payload,
      };
    }
    case "ADMIN_GET_CONVERSATION": {
      return {
        ...state,
        allConversations: payload,
        conversationLoading: false,
      };
    }
    case "ACTIVE_CONVERSATION": {
      return {
        ...state,
        activeConversation: payload,
      };
    }
    case "CHAT_MESSAGES_LOADER": {
      return {
        ...state,
        messagesLoading: payload,
      };
    }
    case "GET_CHAT_MESSAGES": {
      return {
        ...state,
        chatMessages: payload.messages,
        totalMessages: payload.paginationDetail.totalMessges,
        totalPages: payload.paginationDetail.totalPages,
        page: payload.paginationDetail.page,
        messagesLoading: false,
      };
    }
    case "SET_SCROLLING_MODE": {
      return {
        ...state,
        scrolling: payload,
      };
    }
    case "UPDATE_CONVERSATION": {
      return {
        ...state,
        allConversations: payload,
      };
    }
    case "UPDATE_CHAT_MESSAGES": {
      return {
        ...state,
        chatMessages: payload,
      };
    }

    default:
      return {
        ...state,
      };
  }
};
export default chatsReducer;
export const getActiveConversation = (state) => state.chat.activeConversation;

const initState = {
  accepted: null,
  pending: null,
  rejected: null,
  totalPages: null,
  allPoliciesLoader: false,
};
const ticketsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_ALL_POLICIES":
      return {
        ...state,
        accepted: payload?.statusArrays?.accepted,
        pending: payload?.statusArrays?.pending,
        rejected: payload?.statusArrays?.rejected,
        totalPages: payload.totalPages,
      };
    case "UPDATE_ARRAY":
      const { id, toRemove } = payload;
      if (toRemove === "acceptedArray") {
        const indexToRemove = state.accepted.items.findIndex(
          (item) => item?._id === id
        );
        if (indexToRemove !== -1) {
          const updatedItems = [
            ...state.accepted.items.slice(0, indexToRemove),
            ...state.accepted.items.slice(indexToRemove + 1),
          ];
          return {
            ...state,
            accepted: {
              ...state.accepted,
              items: updatedItems,
            },
          };
        }
      } else if (toRemove === "pendingArray") {
        const indexToRemove = state.pending.items.findIndex(
          (item) => item?._id === id
        );
        if (indexToRemove !== -1) {
          const updatedItems = [
            ...state.pending.items.slice(0, indexToRemove),
            ...state.pending.items.slice(indexToRemove + 1),
          ];
          return {
            ...state,
            pending: {
              ...state.pending,
              items: updatedItems,
            },
          };
        }
      } else if (toRemove == "rejectedArray") {
        const indexToRemove = state.rejected.items.findIndex(
          (item) => item?._id === id
        );
        if (indexToRemove !== -1) {
          const updatedItems = [
            ...state.rejected.items.slice(0, indexToRemove),
            ...state.rejected.items.slice(indexToRemove + 1),
          ];
          return {
            ...state,
            rejected: {
              ...state.rejected,
              items: updatedItems,
            },
          };
        }
      }

      return state;

    case "GET_POLICIES_LOADER":
      return {
        ...state,
        allPoliciesLoader: payload,
      };
    case "UPDATE_POLICIES_LOADER":
      return {
        ...state,
        updatePoliciesLoader: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default ticketsReducer;

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const certification = RepositoryFactory.get("certification");

export const getCertificationAction = (id) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CERTIFICATION_LOADER",
      payload: true,
    });
    const { data } = await certification.getCertification(id);

    dispatch({
      type: "GET_CERTIFICATION",
      payload: data,
    });
    dispatch({
      type: "GET_CERTIFICATION_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_CERTIFICATION_LOADER",
      payload: false,
    });
  }
};
export const getAllCovidCertificationAction =
  (page, limit) => async (dispatch) => {
    try {
      dispatch({
        type: "GET_CERTIFICATION_LOADER",
        payload: true,
      });

      console.log("all covid certifications page limit", page, limit);

      const { data } = await certification.getAllCovidCertification(
        page,
        limit
      );
      console.log("all covid certifications hamza", data);

      dispatch({
        type: "GET_COVID_CERTIFICATION",
        payload: data,
      });
      dispatch({
        type: "GET_CERTIFICATION_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "GET_CERTIFICATION_LOADER",
        payload: false,
      });
    }
  };

export const covidCertificationSearch = (query, page) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_CERTIFICATION_LOADER",
      payload: true,
    });
    let payload = {
      name: query,
    };
    const { data } = await certification.searchCovidCertification(
      payload,
      page
    );

    if (data?.success) {
      console.log(data, "daat");
      dispatch({
        type: "COVID_CERTIFICATION_SEARCH",
        payload: { data: data.data, totalPages: data.totalPages },
      });
      dispatch({
        type: "GET_CERTIFICATION_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    dispatch({
      type: "COVID_CERTIFICATION_SEARCH",
      payload: { data: [], totalPages: 1 },
    });
    // toast.error(error.response.data.message);
    dispatch({
      type: "GET_CERTIFICATION_LOADER",
      payload: false,
    });
  }
};

export const updateCertificateStatus =
  (userId, id, status, onSuccess) => async (dispatch) => {
    console.log(userId, id, status);
    try {
      dispatch({
        type: "GET_CERTIFICATION_LOADER",
        payload: true,
      });
      const { data } = await certification.certificateStatus(id, status);

      if (data?.success) {
        onSuccess();
      }
      dispatch({
        type: "CERTIFICATE_STATUS",
        payload: { id, status: data },
      });
      dispatch({
        type: "GET_CERTIFICATION_LOADER",
        payload: false,
      });
      dispatch(getCertificationAction(userId));
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "GET_CERTIFICATION_LOADER",
        payload: false,
      });
    }
  };

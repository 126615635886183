const initState = {
    admins: [],
    totalPages: null,
    addAdminLoader: false,
    updateAdminLoader: false,
    getAdminLoader: false,
  };
  const adminReducer = (state = initState, { type, payload }) => {
    switch (type) {
      case "GET_ADMINS":
        return {
          ...state,
          admins: payload.data,
          totalPages: payload.totalPages,
        };
      case "GET_ADMIN_BY_NAME":
        return {
          ...state,
          admins: payload.data,
          totalPages: payload.totalPages,
        };
      case "GET_ADMINS_LOADER":
        return {
          ...state,
          getAdminLoader: payload,
        };
      case "ADD_ADMIN_LOADER":
        return {
          ...state,
          addAdminLoader: payload,
        };
      case "UPDATE_ADMIN_LOADER":
        return {
          ...state,
          updateAdminLoader: payload,
        };
    
      default:
        return {
          ...state,
        };
    }
  };
  export default adminReducer;
  
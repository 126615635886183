import OnlyHeader from "components/Headers/OnlyHeader";
import ChatSupport from "components/SupportChat/Index";
import {
  Button,
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
} from "reactstrap";

const SupportChat = () => {
  return (
    <div>
      <OnlyHeader />

      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="">
                    <h3 className="text-center text-xl-left">Support Chat</h3>
                  </Col>
                </Row>
              </CardHeader>
              <div className="p-4">
                <ChatSupport />
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default SupportChat;

import EmojiPicker from "emoji-picker-react";
import React, { useEffect, useRef, useState } from "react";
import Attachment from "../../assets/img/Attachment.png";
import { Image } from "react-bootstrap";
import { Button, Form, Label, Spinner } from "reactstrap";
import Smiley from "../../assets/img/Smiley.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileAlt, faTimes } from "@fortawesome/free-solid-svg-icons";
import { sendConversationMessage } from "store/actions/chatAction";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { sendMessageSocket } from "config/socketConfig";
import { updateConversation } from "store/actions/chatAction";
import { updateChatMesssages } from "store/actions/chatAction";

const ChatFooter = ({ activeConversation }) => {
  const dispatch = useDispatch();
  const [message, setMessage] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const emojiRef = useRef(null);
  const fileRef = useRef(null);
  const [media, setMedia] = useState("");
  const { user } = useSelector((state) => state.auth);
  const { token, ...userData } = user;
  let [isSmiley, setisSmiley] = useState(false);
  const textareaRef = useRef(null);
  const handleClick = () => {
    fileRef.current.click();
  };

  const handleClearFile = () => {
    setMedia("");
  };

  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "auto";
      textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
    }
  };

  const handleEmojiSelect = (emoji) => {
    setMessage((prevMessage) => prevMessage + emoji.emoji);
    // setisSmiley(false);
  };

  useEffect(() => {
    adjustTextareaHeight();
  }, [message]);
  useEffect(() => {
    setMedia("");
    setMessage("");
  }, [activeConversation]);
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (emojiRef.current && !emojiRef.current.contains(event.target)) {
        setisSmiley(false);
      }
    };
    if (isSmiley) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [isSmiley]);
  return (
    <div className="bg-lighter bg-opacity-5 p-2">
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          setButtonLoader(true);
          let data = {
            message: message,
            media: media,
            messageType: "normal",
            participant_type: activeConversation?.participant_type,
            participant: activeConversation?.participantId,
          };
          if (media != "" && message == "") {
            dispatch(
              sendConversationMessage(
                data,
                (data) => {
                  setButtonLoader(false);

                  let updateConversationDetail = {
                    conversationId: data.conversationId,
                    lastMessage: data,
                    participant_1_unread: data?.participant_1_unread,
                    participant_2_unread: data?.participant_2_unread,
                    isBlocked: activeConversation?.isBlocked,
                    blockedBy: activeConversation?.blockedBy,
                    fileName: data?.fileName,
                  };
                  let currentMessage = {
                    _id: data._id,
                    message: data.message,
                    attachment: data.attachment,
                    type: data.type,
                    read: data.read,
                    fileName: data?.fileName,
                    senderId: userData,
                    senderType: data.senderType,
                    conversationId: data.conversationId,
                    createdAt: data.createdAt,
                    __v: data.__v,
                    receiverId: activeConversation.participantId,
                    receiverRole: activeConversation.participant_type,
                    updatedConversation: updateConversationDetail,
                  };
                  sendMessageSocket(currentMessage);
                  dispatch(updateConversation(updateConversationDetail));
                  dispatch(updateChatMesssages(currentMessage));
                  setMessage("");
                  setMedia("");
                },
                () => {
                  setButtonLoader(false);
                }
              )
            );
          } else if (media == "" && message != "") {
            dispatch(
              sendConversationMessage(
                data,
                (data) => {
                  setButtonLoader(false);

                  let updateConversationDetail = {
                    conversationId: data.conversationId,
                    lastMessage: data,

                    participant_1_unread: data?.participant_1_unread,
                    participant_2_unread: data?.participant_2_unread,
                    isBlocked: activeConversation?.isBlocked,
                    blockedBy: activeConversation?.blockedBy,
                  };
                  let currentMessage = {
                    _id: data._id,
                    message: data.message,
                    read: data.read,

                    senderId: userData,
                    senderType: data.senderType,
                    conversationId: data.conversationId,
                    createdAt: data.createdAt,
                    __v: data.__v,
                    receiverId: activeConversation.participantId,
                    receiverRole: activeConversation.participant_type,
                    updatedConversation: updateConversationDetail,
                  };

                  sendMessageSocket(currentMessage);
                  dispatch(updateConversation(updateConversationDetail));
                  dispatch(updateChatMesssages(currentMessage));
                  setMessage("");
                },
                () => {
                  setButtonLoader(false);
                }
              )
            );
          } else if (media != "" && message != "") {
            dispatch(
              sendConversationMessage(
                data,
                (data) => {
                  setButtonLoader(false);
                  let updateConversationDetail = {
                    conversationId: data.conversationId,
                    lastMessage: data,
                    fileName: data?.fileName,
                    participant_1_unread: data?.participant_1_unread,
                    participant_2_unread: data?.participant_2_unread,
                    isBlocked: activeConversation?.isBlocked,
                    blockedBy: activeConversation?.blockedBy,
                  };
                  let currentMessage = {
                    _id: data._id,
                    message: data.message,
                    attachment: data.attachment,
                    type: data.type,
                    read: data.read,
                    fileName: data?.fileName,
                    senderId: userData,
                    senderType: data.senderType,
                    conversationId: data.conversationId,
                    createdAt: data.createdAt,
                    __v: data.__v,
                    receiverId: activeConversation.participantId,
                    receiverRole: activeConversation.participant_type,
                    updatedConversation: updateConversationDetail,
                  };

                  sendMessageSocket(currentMessage);
                  dispatch(updateConversation(updateConversationDetail));
                  dispatch(updateChatMesssages(currentMessage));
                  setMedia("");
                  setMessage("");
                },
                () => {
                  setButtonLoader(false);
                }
              )
            );
          } else {
            toast.error("Please filled the required filed!");
          }
        }}
      >
        <div className="d-flex align-items-center w-100">
          <textarea
            type="text"
            ref={textareaRef}
            tabIndex={0}
            rows={1}
            value={message}
            placeholder="Type a message"
            className="bg-light w-100 resize-none rounded-lg p-3 mr-3 text-dark message-input "
            onChange={(e) => {
              setMessage(e.target.value);
              adjustTextareaHeight();
            }}
          />
        </div>
        <div className="d-flex align-items-top justify-content-between mt-2">
          <div className="d-flex align-items-center  justify-content-start  flex-wrap mr-2">
            <div>
              <div
                id="emojiPopover"
                className="cursor-pointer position-relative"
              >
                <Image
                  className={`cursor-pointer inline-block `}
                  alt="FooterImage"
                  width={22}
                  height={22}
                  src={Smiley}
                  onClick={() => setisSmiley((prevState) => !prevState)}
                />
                {isSmiley && (
                  <div className="position-absolute emoji " ref={emojiRef}>
                    <EmojiPicker
                      onEmojiClick={handleEmojiSelect}
                      width={"95%"}
                      height={350}
                    />
                  </div>
                )}
              </div>
            </div>
            <div className="ml-2" role="button" onClick={handleClick}>
              <Image
                alt="FooterImage"
                width={20}
                height={22}
                src={Attachment}
                className="cursor-pointer mb-0"
              />
              {/* <Label className="cursor-pointer mb-0">📎</Label> */}
              <input
                // id="fileInput"
                type="file"
                className=""
                ref={fileRef}
                onChange={(e) => {
                  setMedia(e.target.files[0]);
                  e.target.value = null;
                }}
                hidden
                accept=".pdf, .docx, .doc, .zip, .txt, .jpg, .jpeg, .png, .gif, .svg, .tiff, .mp4, .webm, .3gp, .jfif"
              />
            </div>
            {media != undefined && media != "" ? (
              <button
                className="bg-gradient-light rounded border-0 p-1 pl-3 pr-3 ml-2 input-file"
                type="text"
              >
                <FontAwesomeIcon
                  className="ml-auto cursor-pointer mr-2"
                  icon={faFileAlt}
                />
                {media.name?.length > 10
                  ? `${media?.name?.slice(0, 10)}...`
                  : media.name}
                <FontAwesomeIcon
                  className="ml-auto cursor-pointer text-red-600 text-danger"
                  icon={faTimes}
                  onClick={() => {
                    handleClearFile();
                  }}
                />
              </button>
            ) : (
              ""
            )}
          </div>
          <div className="d-flex align-items-top">
            <Button
              type="submit"
              color="primary"
              disabled={(!message.trim() && !media) || buttonLoader}
              className="cursor-pointer p-1 m-0 pl-2 pr-2"
            >
              {" "}
              {buttonLoader ? <Spinner size={"sm"} /> : "Send"}
            </Button>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default ChatFooter;

import Repository from "./Repository";
const GET_ALL_CHECKR_REPORTS = "user/reports";
const ENGAGED_CHECKR_REPORTS = "user/report";

export default {
  getReports(page, limit) {
    // console.log("Hamza Testing ", page, limit);
    return Repository.get(
      `${GET_ALL_CHECKR_REPORTS}?page=${page}&limit=${limit}`
    );
  },
  engagedCheckrReport(userId) {
    return Repository.put(`${ENGAGED_CHECKR_REPORTS}/${userId}`);
  },
};

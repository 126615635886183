import React, { useRef, useState } from "react";
import { faArrowCircleUp, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import Image from "next/image";

const ImagePicker = ({ image, onImageChange }) => {
  console.log(image);
  const inputRef = useRef();

  //states
  const [errorMessage, setErrorMessage] = useState(null);

  //functions
  const handleImageChange = (event) => {
    const file = event.target.files[0];

    // Validate file type
    if (!file.type.startsWith("image/")) {
      setErrorMessage("Only image files are allowed.");
      return;
    }

    // Validate file size
    if (file.size > 2 * 1024 * 1024) {
      setErrorMessage("Maximum image size allowed is 2 MB.");
      return;
    }

    setErrorMessage(null);
    onImageChange(file);
  };

  const handleDeleteImage = () => {
    onImageChange(null);
  };

  return (
    <div className="">
      {image ? (
        <div className="border rounded-2xl position-relative">
          <img
            src={typeof image === "string" ? image : URL.createObjectURL(image)}
            alt="Selected Image"
            width={150}
            height={150}
            onError={() => setErrorMessage("Failed to load image.")}
            className="rounded-2xl img-fluid"
          />
          <div
            onClick={handleDeleteImage}
            className="position-absolute top-0 end-0 bg-dark p-1 rounded-circle cursor-pointer red-icon"
          >
            <FontAwesomeIcon
              icon={faTrash}
              className="position-absolute top-50 start-50 translate-middle text-white red-icon"
            />
          </div>
        </div>
      ) : (
        <>
          <div
            className="d-flex align-items-center  justify-content-between cursor-pointer border border-2 rounded border-dashed p-4 max-w-14rem hover-bg-secondary"
            onClick={() => inputRef.current.click()}
          >
            <FontAwesomeIcon
              icon={faArrowCircleUp}
              size="2x"
              className="text-primary"
            />
            <div className="mx-2">
              <div className="text-base font-weight-bold text-primary">
                Upload an image
              </div>
              <div className="text-xs text-gray">Max 2 MB</div>
            </div>
            <input
              type="file"
              hidden
              ref={inputRef}
              onChange={handleImageChange}
            />
          </div>
          {errorMessage && (
            <p className="text-xs mt-1 text-danger">{errorMessage}</p>
          )}
        </>
      )}
    </div>
  );
};

export default ImagePicker;

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const orders = RepositoryFactory.get("orders");

export const getAllOrdersAction = (status, page) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ORDERS_LOADER",
      payload: true,
    });
    const { data } = await orders.getOrders(status, page);

    dispatch({
      type: "GET_ORDERS",
      payload: data.data,
    });
    dispatch({
      type: "GET_ORDERS_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_ORDERS_LOADER",
      payload: false,
    });
  }
};

export const orderSearchByIdAction = (id, page) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_ORDERS_LOADER",
      payload: true,
    });
    const { data } = await orders.orderSearchById(id, page);

    if (data?.success) {
      dispatch({
        type: "GET_ORDER_SEARCH_BY_ID",
        payload: data,
      });

      dispatch({
        type: "GET_ORDERS_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    toast.error(error.response.data.message);
    dispatch({
      type: "GET_ORDERS_LOADER",
      payload: false,
    });
  }
};

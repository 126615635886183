import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { getAllSupportPersons } from "store/actions/supportActions";
import { updateSupportPerson } from "store/actions/supportActions";

const UpdateSupportModal = ({
  modal,
  toggle,
  data,
  currentPage,
  itemsPerPage,
}) => {
  // console.log("data: kkkk", data);
  const dispatch = useDispatch();
  const { updateSupportLoader } = useSelector((state) => state.support);
  const [user, setUser] = useState({
    firstName: "",
    lastName: "",
    email: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (!user?.firstName || !user?.lastName || !user?.email) {
      toast.error("Please fill in all fields before submitting.");
      return;
    }

    dispatch(
      updateSupportPerson(data?.id, user, () => {
        toggle();
        setUser({});
        dispatch(getAllSupportPersons(currentPage, itemsPerPage));
      })
    );
  };

  useEffect(() => {
    if (data) {
      setUser({
        firstName: data?.firstName,
        lastName: data?.lastName,
        // password: data?.password,
        email: data?.email,
      });
    }
  }, [data]);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Update Support Person</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">First Name</Label>
                <FormGroup>
                  <Input
                    value={user?.firstName}
                    onChange={(e) => handleChange(e)}
                    name="firstName"
                    placeholder="First Name"
                  />
                </FormGroup>
              </Col>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Last Name</Label>
                <FormGroup>
                  <Input
                    value={user?.lastName}
                    onChange={(e) => handleChange(e)}
                    name="lastName"
                    placeholder="Last Name"
                  />
                </FormGroup>
              </Col>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Email</Label>
                <FormGroup>
                  <Input
                    value={user?.email}
                    onChange={(e) => handleChange(e)}
                    name="email"
                    type="email"
                    placeholder="Email"
                    disabled
                  />
                </FormGroup>
              </Col>
              {/* <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Password</Label>
                <FormGroup>
                  <Input
                    value={user?.password}
                    onChange={(e) => handleChange(e)}
                    name="password"
                    placeholder="Password"
                  />
                </FormGroup>
              </Col> */}
            </Row>

            <div className="d-flex justify-content-end align-items-center">
              <Button
                color="primary"
                type="submit"
                disabled={updateSupportLoader}
              >
                {updateSupportLoader ? <Spinner size="sm" /> : "Update"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default UpdateSupportModal;

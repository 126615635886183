import Repository from "./Repository";
const GET_ALL = "dashboard/";
const DEMOGRAPHIC_INFO = "user/demographic-info";
const REVENUE = "order/country-revenue";
const NOTIFICATION = "public-notification";
const EARNINGS = "/dashboard/user";
const CHART = "/dashboard/chart/user";
const ORDERS = "/dashboard/user-order";
const PAYMENT_LOGS = "/dashboard/payment-log";
const SEARCH_PAYMENT_LOGS = "/dashboard/search";
const CURRENT_MONTH = "/dashboard/current-month";

export default {
  getAllStats() {
    return Repository.get(`${GET_ALL}`);
  },
  getDemographicInfo() {
    return Repository.get(`${DEMOGRAPHIC_INFO}`);
  },
  getCountryRevenue() {
    return Repository.get(`${REVENUE}`);
  },
  addNotification(payload) {
    return Repository.post(`${NOTIFICATION}`, payload);
  },
  publicNotification() {
    return Repository.get(`${NOTIFICATION}`);
  },
  updateNotification(payload) {
    return Repository.put(`${NOTIFICATION}`, payload);
  },
  deleteNotification(payload) {
    return Repository.delete(`${NOTIFICATION}`);
  },
  userEarnings(ID, role) {
    return Repository.get(`${EARNINGS}/${ID}?role=${role}`);
  },
  userChartStats(ID, role) {
    return Repository.get(`${CHART}/${ID}?role=${role}`);
  },
  userOrders(payload) {
    const statusParam =
      payload.status !== undefined && payload.status !== " "
        ? `&status=${payload.status}`
        : "";
    return Repository.get(
      `${ORDERS}/${payload.id}?role=${payload.role}${statusParam}&page=${payload.page}&limit=10`
    );
  },
  userCurrentMonthOrders(payload) {
    return Repository.get(
      `${CURRENT_MONTH}/${payload.id}?role=${payload.role}&page=${payload.page}&limit=10`
    );
  },
  getPaymentLogs(page) {
    return Repository.get(`${PAYMENT_LOGS}?page=${page}&limit=10`);
  },
  searchLogs(query, page) {
    return Repository.get(
      `${SEARCH_PAYMENT_LOGS}/${query}?page=${page}&limit=10`
    );
  },
};

import OnlyHeader from "components/Headers/OnlyHeader";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Media,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { searchHelpArticleByTitle } from "store/actions/helpArticleAction";
import { deleteHelpArticle } from "store/actions/helpArticleAction";
import { getAllHelpArticles } from "store/actions/helpArticleAction";

const HelpArticles = () => {
  const { allHelpArticles, getHelpArticlesLoader, totalPages } = useSelector(
    (state) => state.helpArticles
  );
  const dispatch = useDispatch();

  const [currentPage, setCurrentPage] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");
  const [articleID, setArticleID] = useState("");
  const [articleSearchTitle, setArticleSearchTitle] = useState("");

  const itemsPerPage = 10;
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleDeleteModal = (id) => {
    console.log("<<<<>>>id: ", id);
    setArticleID(id);
    setMessage("Are you sure you want to delete this article?");
    toggleConfirmationModal();
  };

  const handleConfirmationOk = () => {
    console.log("<<<<><>articleID: ", articleID);
    console.log(`Activating article ID: ${articleID}`);
    dispatch(
      deleteHelpArticle(articleID, () => {
        dispatch(getAllHelpArticles());
      })
    );
    toggleConfirmationModal();
  };

  const handleConfirmationCancel = () => {
    toggleConfirmationModal();
  };

  const handleOnSumbit = () => {
    setCurrentPage(1);
    dispatch(searchHelpArticleByTitle(articleSearchTitle, currentPage));
  };

  const handleClearSearch = () => {
    setArticleSearchTitle("");
    dispatch(getAllHelpArticles(currentPage, itemsPerPage));
  };

  useEffect(() => {
    dispatch(getAllHelpArticles(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Help Articles</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="ResolutionSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={articleSearchTitle}
                          onChange={(e) =>
                            setArticleSearchTitle(e.target.value)
                          }
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Title
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        disabled={getHelpArticlesLoader}
                        onClick={handleOnSumbit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        disabled={getHelpArticlesLoader}
                        onClick={handleClearSearch}
                        size="sm"
                      >
                        Clear Search
                      </Button>
                      <Link to={"/admin/add-article"}>
                        <Button
                          color="primary"
                          className="p-2 mx-2 my-2 my-lg-0"
                          size="sm"
                        >
                          Add Article
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Actions</th>
                    <th scope="col" />
                  </tr>
                </thead>
                {!getHelpArticlesLoader ? (
                  <tbody>
                    {allHelpArticles && allHelpArticles.length > 0 ? (
                      allHelpArticles?.map((article, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {article?.title}
                                </span>
                              </Media>
                            </Media>
                          </th>

                          <td>{formatTimestamp(article?.createdAt)}</td>
                          <td>{formatTimestamp(article?.updatedAt)}</td>

                          <td>
                            <div className="d-flex align-items-center">
                              <Link to={`/admin/edit-article/${article?._id}`}>
                                <Button color="primary" size="sm">
                                  <i className="fas fa-pencil-alt" />
                                </Button>
                              </Link>
                              <Button
                                color="danger"
                                className="mx-2"
                                size="sm"
                                onClick={() => handleDeleteModal(article?._id)}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          {allHelpArticles && allHelpArticles.length === 0 ? (
                            <p className="mb-0 text-sm">No Article Found.</p>
                          ) : (
                            <Spinner
                              size="lg"
                              style={{
                                display: "block",
                                margin: "0 auto",
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
        <ConfirmationModal
          modal={confirmationModal}
          toggle={toggleConfirmationModal}
          userId={articleID}
          message={message}
          onConfirm={handleConfirmationOk}
          onCancel={handleConfirmationCancel}
        />
      </Container>
    </>
  );
};

export default HelpArticles;

// Root Reducer

import { combineReducers } from "redux";
import authReducer from "./authReducer";
import supportReducer from "./supportReducer";
import resolutionReducer from "./resolutionReducer";
import blogsReducer from "./blogsReducer";
import statsReducer from "./dashboardReducer";
import usersReducer from "./usersReducer";
import ticketsReducer from "./ticketsReducer";
import policyReducer from "./policyReducer";
import categoriesReducer from "./categoriesReducer";
import taxReducer from "./taxReducer";
import ordersReducer from "./ordersReducer";
import disputeReducer from "./disputesReducer";
import reportsReducer from "./reportReducer";
import certificationReducer from "./certificationReducer";
import chatsReducer from "./chatReducer";
import deletedJobReducer from "./deletedJobsReducer";
import adminReducer from "./adminsReducer";
import checkrReportsReducer from "./checkrReportReducer";
import helpArticlesReducer from "./helpArticleReducer";
import skillsReducer from "./skillReducer";
import nameRequestReducer from "./nameRequestsReducer";

export const appReducer = combineReducers({
  auth: authReducer,
  support: supportReducer,
  resolution: resolutionReducer,
  blogs: blogsReducer,
  disputes: disputeReducer,
  dashboard: statsReducer,
  users: usersReducer,
  tickets: ticketsReducer,
  policies: policyReducer,
  category: categoriesReducer,
  tax: taxReducer,
  orders: ordersReducer,
  reports: reportsReducer,
  certification: certificationReducer,
  chats: chatsReducer,
  deletedJobs: deletedJobReducer,
  admins: adminReducer,
  checkrReports: checkrReportsReducer,
  helpArticles: helpArticlesReducer,
  skills: skillsReducer,
  nameRequests: nameRequestReducer
});

const rootReducer = (state, action) => {
  if (action.type === "LOGOUT") {
    return appReducer(undefined, action);
  }
  return appReducer(state, action);
};
export default rootReducer;

import OnlyHeader from "components/Headers/OnlyHeader";
import ConfirmationModal from "components/Modals/ConfirmationModal";
import DeleteReportModal from "components/Modals/reportModals/DeleteReportModal";
import SuspendAccountModal from "components/Modals/usersModals/SuspendAccountModal";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Media,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { getAllReports } from "store/actions/reportAction";
import { activateUserAccount } from "store/actions/usersAction";
import { ReportSearchByNameAction } from "store/actions/reportAction";

const Report = () => {
  const { allReports, getReportsLoader, totalPages } = useSelector(
    (state) => state.reports
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const [reportSearchByName, setReportSearchByName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [userId, setUserID] = useState("");
  const [message, setMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState("");
  const [isDeleteModalOpen, setDeleteSubModalOpen] = useState(false);
  const [searchExecuted, setSearchExecuted] = useState(false);

  const itemsPerPage = 10;
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleActivateModal = (id) => {
    setUserID(id);
    setMessage("Are you sure you want to activate this account?");
    toggleConfirmationModal();
  };

  const handleConfirmationOk = () => {
    dispatch(activateUserAccount(userId, "active"));
    dispatch(getAllReports(currentPage, itemsPerPage));
    toggleConfirmationModal();
  };

  const toggleModal = () => {
    setModal(!modal);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleConfirmationCancel = () => {
    toggleConfirmationModal();
  };
  const handleDeleteModal = (id) => {
    setSelectedReport(id);
    setDeleteSubModalOpen(!isDeleteModalOpen);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };
  const handleOnSumbit = () => {
    dispatch(ReportSearchByNameAction(reportSearchByName));
    setSearchExecuted(true);
  };

  const handleClearSearch = () => {
    setReportSearchByName("");
    dispatch(getAllReports(currentPage, itemsPerPage));
    setSearchExecuted(false);
  };

  const modalGetaction = () => {
    if (setReportSearchByName !== "") {
      setCurrentPage(1);
      dispatch(ReportSearchByNameAction(reportSearchByName, currentPage));
    } else {
      dispatch(getAllReports(currentPage, itemsPerPage));
    }
  };

  useEffect(() => {
    dispatch(getAllReports(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <div>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Reports</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="ResolutionSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={reportSearchByName}
                          onChange={(e) =>
                            setReportSearchByName(e.target.value)
                          }
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Reporter Name
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-10 py-2 mx-2 my-2 my-lg-0"
                        size="sm"
                        disabled={
                          getReportsLoader || !reportSearchByName.trim()
                        }
                        onClick={handleOnSumbit}
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        size="sm"
                        disabled={!searchExecuted}
                        onClick={handleClearSearch}
                      >
                        Clear Search
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Report ID</th>
                    <th scope="col">Reported By</th>
                    <th scope="col">User Name</th>
                    <th scope="col">Report Date</th>
                    <th scope="col">Detail</th>
                    <th scope="col" align="right">
                      Actions
                    </th>
                  </tr>
                </thead>
                {!getReportsLoader ? (
                  <tbody>
                    {allReports && allReports.length > 0 ? (
                      allReports?.map((report, index) => (
                        <tr key={index}>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {report?._id}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {report?.reportedBy?.firstName +
                                    " " +
                                    report?.reportedBy?.lastName}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {report?.user?.firstName +
                                    " " +
                                    report?.user?.lastName}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {formatTimestamp(report?.createdAt)}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() => {
                                history.push(
                                  `/admin/report-details/${report?._id}`
                                );
                              }}
                            >
                              View
                            </Button>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() => handleDeleteModal(report._id)}
                            >
                              Discard
                            </Button>
                            {report?.user?.status === "active" ? (
                              <Button
                                size="sm"
                                color="primary"
                                onClick={() => {
                                  setUserID(report?.user?.id);
                                  toggleModal();
                                }}
                              >
                                Suspend Account
                              </Button>
                            ) : (
                              <Button
                                size="sm"
                                color="primary"
                                onClick={() =>
                                  handleActivateModal(report?.user?.id)
                                }
                              >
                                Activate Account
                              </Button>
                            )}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          {allReports && allReports.length === 0 ? (
                            <p className="mb-0 text-sm">No Reports Found.</p>
                          ) : (
                            <Spinner
                              size="lg"
                              style={{
                                display: "block",
                                margin: "0 auto",
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <SuspendAccountModal
        modal={modal}
        toggle={toggleModal}
        userId={userId}
        page={currentPage}
        limit={itemsPerPage}
        modalGetaction={modalGetaction}
      />
      <ConfirmationModal
        modal={confirmationModal}
        toggle={toggleConfirmationModal}
        userId={userId}
        message={message}
        onConfirm={handleConfirmationOk}
        onCancel={handleConfirmationCancel}
      />
      <DeleteReportModal
        modal={isDeleteModalOpen}
        toggle={() => handleDeleteModal()}
        id={selectedReport}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
    </div>
  );
};

export default Report;

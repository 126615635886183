import Repository from "./Repository";
const GET_ALL_DISPUTES = "/dispute";
const GET_DISPUTE_SEARCH_BY_ID = "/dispute/search";
const GET_DISPUTE_COMMENTS = "chat/adminChat/messages";
const RELEASE_AMOUNT = "/dispute/release";
const REFUND_AMOUNT = "/dispute/refund";

export default {
  getDisputes(status, page, limit) {
    console.log(status);
    return Repository.get(
      `${GET_ALL_DISPUTES}?page=${page}&limit=${limit}&${
        status ? `&status=${status}` : ""
      }`
    );
  },
  disputeSearchById(id, page) {
    return Repository.get(`${GET_DISPUTE_SEARCH_BY_ID}/${id}?page=${page}`);
  },
  getSingleDispute(id) {
    return Repository.get(`${GET_ALL_DISPUTES}/${id}`);
  },
  getDisputeComments(payload) {
    return Repository.get(
      `${GET_DISPUTE_COMMENTS}?sellerId=${payload?.sellerID}&buyerId=${payload?.buyerID}&limit=${payload?.limit}&page=${payload?.page}`
    );
  },
  releaseSellerAmount(payload) {
    return Repository.post(`${RELEASE_AMOUNT}`, payload);
  },
  refundBuyerAmount(payload) {
    return Repository.post(`${REFUND_AMOUNT}`, payload);
  },
};

import { convertToHTML } from "draft-convert";
import { EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import Multiselect from "multiselect-react-dropdown";

import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";

import OnlyHeader from "components/Headers/OnlyHeader";
import { useHistory } from "react-router-dom";
import { addNewBlog } from "store/actions/blogsAction";
import { toast } from "react-toastify";
import { getCategoriesAction } from "store/actions/categoriesAction";
import { getSubCategoriesAction } from "store/actions/categoriesAction";
import Select from "react-select";
import ImagePicker from "components/ImagePicker";

const AddBlog = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { allSubCategories, allCategories } = useSelector(
    (state) => state.category
  );

  const { addBlogLoader } = useSelector((state) => state.blogs);

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );
  const [selectedValues, setSelectedValues] = useState([]);
  const [newImage, setNewImage] = useState(null);
  console.log(newImage);

  const [blogData, setBlogData] = useState({
    title: "",
    content: "",
    category: "",
    file: "",
  });
  console.log("blogData: ", blogData);

  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setBlogData((prevData) => ({
      ...prevData,
      content: currentContentAsHTML,
    }));
  };

  const handleBlogDataChange = (e) => {
    const { name, value, files } = e.target;

    if (name === "featuredImgURL" || name === "getMediaURL") {
      setBlogData((prevData) => ({
        ...prevData,
        [name]: files[0],
      }));
    } else {
      setBlogData((prevData) => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  const handleCategoryChange = (selectedOption) => {
    setBlogData((prevData) => ({
      ...prevData,
      category: selectedOption.value,
    }));
    dispatch(getSubCategoriesAction(selectedOption.value));
  };

  const onSelect = (selectedList) => {
    setSelectedValues(selectedList);
  };

  const onRemove = (selectedList) => {
    setSelectedValues(selectedList);
  };

  const handleSubmit = () => {
    const minTitleLength = 40;
    console.log(blogData.title.length, "title length>>>>>>>>>");

    if (blogData.title.length < minTitleLength) {
      toast.error("Title should be minimum of 40 charachters");
      return;
    }
    if (
      !blogData.title ||
      !blogData.title ||
      !blogData.content ||
      !blogData.category ||
      !blogData.file
      // ||
      // selectedValues.length === 0
    ) {
      toast.error("Please fill all the required fields");
      return;
    }

    const temp = {
      ...blogData,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    };

    const payload = {
      title: temp.title,
      description: temp.content,
      category: temp.category,
      subCategory: selectedValues?.map((item) => item?._id) || "",
      file: temp.file,
    };

    dispatch(
      addNewBlog(payload, () => {
        history.push("/admin/blogs");
        toast.success("Blog added Successfully");
      })
    );
  };
  const embedVideoCallBack = (iframeHtml) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = iframeHtml.trim();
    const iframeElement = tempDiv.querySelector("iframe");
    if (iframeElement) {
      const src = iframeElement.getAttribute("src");
      const cleanSrc = src.split("?")[0];
      return cleanSrc;
    } else {
      return null;
    }
  };
  useEffect(() => {
    dispatch(getSubCategoriesAction());
    dispatch(getCategoriesAction());
  }, [dispatch]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Col>
            <Card>
              <CardBody>
                <Row>
                  <Col md="4" className="my-2">
                    <Label className="mb-0 my-auto">Title</Label>
                    <Input
                      name="title"
                      className="form-controll_title"
                      value={blogData.title}
                      required
                      onChange={(e) => {
                        handleBlogDataChange(e);
                      }}
                    />
                    {blogData.title.length < 40 && (
                      <h6 className="text-muted">
                        Title must be at least 40 characters long.
                      </h6>
                    )}
                  </Col>
                  <Col md="4" className="my-2">
                    <Label className="mb-0 my-auto">Category</Label>
                    <Select
                      name="category"
                      value={blogData?.category?.label}
                      options={allCategories.map((category) => ({
                        value: category._id,
                        label: category.name,
                        key: category._id,
                      }))}
                      onChange={handleCategoryChange}
                      required
                    />
                  </Col>
                  <Col md="4" className="my-2">
                    <Label className="mb-0 my-auto ">Sub Categories</Label>
                    <Multiselect
                      options={allSubCategories}
                      selectedValues={selectedValues}
                      onSelect={onSelect}
                      onRemove={onRemove}
                      displayValue="name"
                      // required
                    />
                  </Col>

                  <div className="mx-3 mt-2 mb-2">
                    <ImagePicker
                      image={blogData.file}
                      onImageChange={(newFile) => {
                        setBlogData((prevData) => ({
                          ...prevData,
                          file: newFile,
                        }));
                      }}
                    />
                  </div>
                  <Col md={12} className="mt-3">
                    <Editor
                      editorState={editorState}
                      editorStyle={{
                        border: "2px solid #C0C0C0",
                      }}
                      toolbar={{
                        embedded: {
                          embedCallback: embedVideoCallBack,
                          defaultSize: {
                            height: "auto",
                            width: "auto",
                          },
                        },
                        fontFamily: {
                          options: [
                            "Arial",
                            "Georgia",
                            "Impact",
                            "Tahoma",
                            "Times New Roman",
                            "Verdana",
                            "Barlow",
                          ],
                        },
                      }}
                      onEditorStateChange={handleEditorChange}
                    />
                  </Col>
                </Row>

                {/* <Row>
                    <Col>
                      <div>
                        <Row style={{ display: "flex", marginTop: "20px" }}>
                          {previewImages.map((preview, index) => (
                            <Col
                              key={index}
                              md={2}
                              style={{ marginRight: "10px" }}
                            >
                              <img
                                src={preview}
                                alt={`Preview ${index}`}
                                style={{
                                  position: "relative",
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <Button
                                color="danger"
                                size="sm"
                                onClick={() => handleRemoveImage(index)}
                                style={{
                                  position: "absolute",
                                  top: "0%",
                                  right: "0%",
                                }}
                              >
                                X
                              </Button>
                            </Col>
                          ))}
                        </Row>
                        <div className="mt-3 form-group">
                          <FormGroup>
                            <Label for="exampleFile">Upload Files</Label>
                            <Input
                              type="file"
                              name="attachments"
                              multiple
                              onChange={handleFileChange}
                              id="exampleFile"
                            />
                          </FormGroup>
                        </div>
                      </div>
                    </Col>
                  </Row> */}

                <div className="d-flex justify-content-end align-items-center mt-3">
                  <div>
                    <Button
                      type="button"
                      color="primary"
                      onClick={handleSubmit}
                      disabled={
                        addBlogLoader || !blogData.title || !blogData.category
                        // ||
                        // selectedValues.length === 0
                      }
                    >
                      {addBlogLoader ? (
                        <Spinner
                          className="d-flex mx-auto align-items-center justify-content-center overflow-hidden"
                          size="sm"
                          color="white"
                          disabled={addBlogLoader}
                        />
                      ) : (
                        "SAVE CHANGES"
                      )}
                    </Button>
                  </div>
                </div>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default AddBlog;

import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Spinner,
} from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { useDispatch, useSelector } from "react-redux";
import { suspendUserAccount } from "store/actions/usersAction";
import { getUsers } from "store/actions/usersAction";
import ConfirmationModal from "../ConfirmationModal";

const SuspendAccountModal = ({
  modal,
  toggle,
  userId,
  page,
  limit,
  modalGetaction,
}) => {
  const dispatch = useDispatch();
  const { accountSuspendLoader } = useSelector((state) => state.users);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(null);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const formatToISODate = (date) => {
    return date.toISOString().split("T")[0];
  };

  const handleSaveButtonClick = () => {
    setMessage("Are you sure you want to suspend this account?");
    toggleConfirmationModal();
  };

  const handleConfirmationOk = () => {
    const formattedDate = endDate
      ? formatToISODate(endDate)
      : formatToISODate(startDate);
    dispatch(
      suspendUserAccount(userId, formattedDate, () => {
        toggle();
        setStartDate(new Date());
        setEndDate(null);
        modalGetaction();
        // dispatch(getUsers(page, limit));
      })
    );

    // Close the confirmation modal
    toggleConfirmationModal();
  };

  const handleConfirmationCancel = () => {
    // Close the confirmation modal
    toggleConfirmationModal();
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Account Suspension</ModalHeader>
        <ModalBody>
          <div>
            <div className="d-flex align-items-center justify-content-around">
              <h4 className="mb-0">Suspend Account till </h4>{" "}
            </div>
            <div className="d-flex justify-content-center align-items-center mt-3">
              <DatePicker
                selected={startDate}
                onChange={onChange}
                startDate={startDate}
                endDate={endDate}
                selectsRange
                inline
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" size="sm" onClick={handleSaveButtonClick}>
            {accountSuspendLoader ? <Spinner size="sm" /> : "Save"}
          </Button>
        </ModalFooter>
      </Modal>

      <ConfirmationModal
        modal={confirmationModal}
        toggle={toggleConfirmationModal}
        userId={userId}
        message={message}
        onConfirm={handleConfirmationOk}
        onCancel={handleConfirmationCancel}
      />
    </div>
  );
};

export default SuspendAccountModal;

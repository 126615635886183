const initState = {
  allTickets: [],
  singleTicket: {},
  allTicketsLoader: false,
  assignTicketLoader: false,
};
const ticketsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_ALL_TICKETS":
      return {
        ...state,
        allTickets: payload.data,
        totalPages: payload.totalPages,
      };
      case "GET_TICKETS_SEARCH_BY_ID":
      return {
        ...state,
        allTickets: payload,
        totalPages:0
      };
      case "GET_SINGLE_TICKET":
        return {
        ...state,
        singleTicket: payload,
      };
    case "ADD_COMMENT":
      return {
        ...state,
        singleTicket: {
          ...state.singleTicket,
          comments: payload.comments,
        },
      };
    case "STATUS_UPDATED":
      return {
        ...state,
        singleTicket: {
          ...state.singleTicket,
          status: payload.status,
        },
      };

    case "ASSIGN_TICKET_SUCCESS":
      return {
        ...state,
        singleTicket: {
          ...state.singleTicket,
          ...payload.updatedTicket,
        },
      };

    case "GET_TICKETS_LOADER":
      return {
        ...state,
        allTicketsLoader: payload,
      };
    case "ASSIGN_TICKET_LOADER":
      return {
        ...state,
        assignTicketLoader: payload,
      };

    default:
      return {
        ...state,
      };
  }
};
export default ticketsReducer;

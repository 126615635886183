import { formatDate } from "Contants";
import { useEffect, useRef } from "react";
import Avatar from "react-avatar";
import { useSelector } from "react-redux";
import DefaultAvatar_1 from "../../assets/img/brand/ticketdetailavatar_1.png";

const ChatBody = ({ ticketChat }) => {
  console.log(">>>ticketChat: ", ticketChat);
  const { user } = useSelector((state) => state.auth);
  const scrollDown = useRef(null);

  useEffect(() => {
    if (scrollDown.current) {
      scrollDown.current.scrollTop = scrollDown.current.scrollHeight;
    }
  }, [ticketChat]);

  return (
    <>
      <div
        className="chat-container bg-white rounded-bottom rounded-lg"
        ref={scrollDown}
      >
        <div>
          <div>
            {ticketChat?.comments?.map((item, id) => {
              return (
                <div
                  className={`d-flex ${
                    item?.author?.id === user?.id ? "right" : "left"
                  } mt-3`}
                  key={id}
                >
                  {item?.author?.id === user?.id ? (
                    <Avatar
                      alt=""
                      src={
                        item?.author?.profileImage === null ||
                        item?.author?.profileImage === undefined
                          ? DefaultAvatar_1
                          : item?.createdBy?.profileImage
                      }
                      className="mx-1 mb-1"
                      size="30"
                      round={true}
                    />
                  ) : (
                    <Avatar
                      alt=""
                      src={
                        item?.author?.profileImage === null ||
                        item?.author?.profileImage === undefined
                          ? DefaultAvatar_1
                          : item?.author?.profileImage
                      }
                      className="mx-1 mb-1"
                      size="30"
                      round={true}
                    />
                  )}
                  <div
                    className={`message-bubble-${
                      item?.author?.id === user?.id ? "right" : "left"
                    }`}
                  ></div>
                  <div
                    className={`message ${
                      item?.author?.id === user?.id
                        ? "message-right"
                        : "message-left"
                    }`}
                  >
                    {item?.text}
                    {item?.attachment && (
                    <a href={item?.attachment} target="_blank" className="mx-2">
                      Attachments
                    </a>
                    )}

                    <div
                      className={`d-flex ${
                        item?.author?.id === user?.id ? "right" : "left"
                      }`}
                    >
                      <div className="d-flex justify-content-end m-2">
                        <small className="mb-0">
                          {formatDate(item?.createdAt)}
                        </small>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
};

export default ChatBody;

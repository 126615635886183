import OnlyHeader from "components/Headers/OnlyHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import { getAllCountries, updateCountry } from "store/actions/supportActions";

const CountriesSupport = () => {
  const dispatch = useDispatch();
  const { allCountries, getSupportLoader } = useSelector(
    (state) => state.support
  );
  console.log("allCountries: ", allCountries);
  const [countries, setCountries] = useState([]);
  const [selectedCountries, setSelectedCountries] = useState([]);

  const handleBlock = (country) => {
    let payload = {
      enabled: !country?.enabled,
    };
    dispatch(
      updateCountry(payload, country?._id, () => {
        setSelectedCountries((prevSelectedCountries) => {
          const updatedCountries = [...prevSelectedCountries];
          const countryIndex = updatedCountries.indexOf(country?.countryName);
          if (countryIndex !== -1) {
            updatedCountries.splice(countryIndex, 1);
          } else if (country?.enabled) {
            updatedCountries.push(country?.countryName);
          } else {
            const updatedCountry = countries.find(
              (item) => item._id === country?._id
            );
            if (updatedCountry) {
              updatedCountry.enabled = true;
            }
          }

          return updatedCountries;
        });
      })
    );
  };

  useEffect(() => {
    dispatch(getAllCountries());
  }, [dispatch]);

  useEffect(() => {
    if (allCountries?.length > 0) {
      const sortedCountries = allCountries.sort((a, b) => {
        if (a.enabled === b.enabled) {
          return 0;
        }
        if (a.enabled) {
          return -1;
        }
        return 1;
      });
      setCountries(sortedCountries);
    }
  }, [allCountries]);

  return (
    <>
      {" "}
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="flex-start">
                    <h3 className="mb-0">Countries Support</h3>
                  </div>
                  <div className="flex-end d-flex flex-column flex-sm-row">
                    <div className="d-flex align-items-center mr-sm-3">
                      <div className="p-2 bg-gradient-tealpot"></div>
                      <small className="mx-2 text-capitalize">Countries that we support</small>
                    </div>
                    <div className="d-flex align-items-center">
                      <div className="p-2 bg-danger"></div>
                      <small className="mx-2 text-capitalize">Not supported countries</small>
                    </div>
                  </div>

                  {/* <Button color="primary">Create</Button> */}
                </div>
              </CardHeader>
              <CardBody>
                {getSupportLoader ? (
                  <Row className="justify-content-center">
                    <Spinner />
                  </Row>
                ) : (
                  <Row>
                    {countries?.map((country) => {
                      return (
                        <Col lg={3} md={4} sm={6} xs={10} key={country?._id}>
                          <Card
                            className={`countries_card p-3 cursor-pointer border-0 mb-3 ${
                              selectedCountries?.includes(
                                country.countryName
                              ) || !country?.enabled
                                ? "bg-danger"
                                : "bg-gradient-tealpot"
                            }`}
                            onClick={() => handleBlock(country)}
                          >
                            <p className="text-capitalize text-white mb-0 cursor-pointer ">
                              {country?.countryName}
                            </p>
                          </Card>
                        </Col>
                      );
                    })}
                  </Row>
                )}
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default CountriesSupport;

import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const helpArticles = RepositoryFactory.get("helpArticles");

export const getAllHelpArticles = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_HELP_ARTICLES_LOADER",
      payload: true,
    });
    const { data } = await helpArticles.getHelpArticles(page, limit);

    dispatch({
      type: "GET_HELP_ARTICLES",
      payload: data,
    });
    dispatch({
      type: "GET_HELP_ARTICLES_LOADER",
      payload: false,
    });
  } catch (err) {
    toast.error(err.response.data.message);
    dispatch({
      type: "GET_HELP_ARTICLES_LOADER",
      payload: false,
    });
  }
};

export const getSingleHelpArticle = (id) => async (dispatch) => {
  try {
    const { data } = await helpArticles.getSingleHelpArticle(id);

    dispatch({
      type: "GET_SINGLE_HELP_ARTICLE",
      payload: data.data,
    });
  } catch (err) {
    toast.error(err.response.data.message);
  }
};

export const addHelpArticle =
  (payload, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "ADD_HELP_ARTICLES_LOADER",
        payload: true,
      });

      const formData = new FormData();
      formData.append("title", payload.title);
      formData.append("description", payload.description);
      formData.append("content", payload.content);
      formData.append("file", payload.file);

      const { data } = await helpArticles.addHelpArticle(formData);

      toast.success(data?.message);
      onSuccess();

      dispatch({
        type: "ADD_HELP_ARTICLES_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);
      dispatch({
        type: "ADD_HELP_ARTICLES_LOADER",
        payload: false,
      });
    }
  };

export const updateHelpArticle =
  (id, payload, onSuccess = () => {}) =>
  async (dispatch) => {
    // console.log("id, payload: ", id, payload);
    try {
      dispatch({
        type: "UPDATE_HELP_ARTICLES_LOADER",
        payload: true,
      });

      const formData = new FormData();
      formData.append("title", payload.title);
      formData.append("description", payload.description);
      formData.append("content", payload.content);
      formData.append("file", payload.file);

      const { data } = await helpArticles.updateHelpArticle(id, formData);

      // console.log("Hamza test 123", data);

      toast.success(data?.message);
      onSuccess();
      dispatch({
        type: "UPDATE_HELP_ARTICLES_LOADER",
        payload: false,
      });
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "UPDATE_HELP_ARTICLES_LOADER",
        payload: false,
      });
    }
  };

export const deleteHelpArticle =
  (id, onSuccess = () => {}) =>
  async (dispatch) => {
    try {
      dispatch({
        type: "DELETE_HELP_ARTICLES_LOADER",
        payload: true,
      });
      const { data } = await helpArticles.deleteHelpArticle(id);

      dispatch({
        type: "DELETE_HELP_ARTICLES_LOADER",
        payload: false,
      });

      toast.success(data?.message);
      if (data?.success) {
        dispatch({
          type: "HELP_ARTICLE_DELETED",
          payload: id,
        });
      }
      onSuccess();
    } catch (err) {
      toast.error(err.response.data.message);

      dispatch({
        type: "DELETE_HELP_ARTICLES_LOADER",
        payload: false,
      });
    }
  };

export const searchHelpArticleByTitle = (title, page) => async (dispatch) => {
  console.log("searchHelpArticleByTitle_title: ", title, page);
  try {
    dispatch({
      type: "GET_HELP_ARTICLES_LOADER",
      payload: true,
    });
    const { data } = await helpArticles.searchHelpArticleByTitle(title, page);

    if (data?.success) {
      dispatch({
        type: "SEARCH_HELP_ARTICLE_BY_Title",
        payload: data,
      });
      dispatch({
        type: "GET_HELP_ARTICLES_LOADER",
        payload: false,
      });
    }
  } catch (error) {
    // toast.error(error.response.data.message);
    dispatch({
      type: "GET_HELP_ARTICLES_LOADER",
      payload: false,
    });
  }
};

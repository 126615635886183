/* eslint-disable import/no-anonymous-default-export */
import Repository from "./Repository";
const GET_ALL_SKILLS = "skill";
const ADD_SKILL = "skill";
const DELETE_SKILL = "skill";
const UPDATE_SKILL = "skill";
const GET_SEARCH_BY_TITLE = "skill/search";

export default {
  getSkills(page, limit) {
    return Repository.get(`${GET_ALL_SKILLS}?page=${page}&limit=${limit}`);
  },
  addSkill(payload) {
    return Repository.post(`${ADD_SKILL}`, payload);
  },
  deleteSkill(id) {
    return Repository.delete(`${DELETE_SKILL}/${id}`);
  },
  updateSkill(id, payload) {
    return Repository.put(`${UPDATE_SKILL}/${id}`, payload);
  },
  skillSearchByTitle(title) {
    const requestBody = {
      text: title,
    };
    return Repository.post(`${GET_SEARCH_BY_TITLE}`, requestBody);
  },
};

import { taxDetails } from "Contants";
import { taxOrderDetails } from "Contants";
import AsBuyerTab from "components/AsBuyerTab";
import AsSellerTab from "components/AsSellerTab";
import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardHeader,
  Container,
  Row,
  FormGroup,
  Input,
  Label,
  Button,
  Table,
  Spinner,
} from "reactstrap";
import { deletePreviousTaxAction } from "store/actions/taxActions";
import { deleteTaxAction } from "store/actions/taxActions";
import { getPreviousTaxesAction } from "store/actions/taxActions";

const PreviousTaxes = () => {
  const { previousTaxes, taxLoader } = useSelector((state) => state.tax);
  const history = useHistory();
  const { id } = useParams();
  const dispatch = useDispatch();

  const [viewAsSeller, setViewAsSeller] = useState(true);

  const handleViewChange = (isSeller) => {
    setViewAsSeller(isSeller);
  };

  const handleGoBack = () => {
    history.goBack();
  };

  useEffect(() => {
    dispatch(getPreviousTaxesAction(id));
  }, []);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <h3
                  className="mb-0 cursor-pointer text-sm"
                  onClick={handleGoBack}
                >
                  <i class="fas fa-chevron-left"></i> Previous Paid Taxes
                </h3>
              </CardHeader>

              <div className="p-2 p-lg-5">
                <Table responsive>
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">Tax ID</th>
                      <th scope="col">Tax Year</th>
                      <th scope="col">Tax File</th>
                      <th scope="col">Action</th>
                    </tr>
                  </thead>
                  {previousTaxes.length > 0 ? (
                    <tbody>
                      {previousTaxes?.map((tax, index) => (
                        <tr key={index}>
                          <td>{tax?._id}</td>
                          <td>{tax.year}</td>
                          <td>
                            <a
                              href={tax.url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Tax File <i className="fa fa-download"></i>
                            </a>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              color="danger"
                              disabled={taxLoader}
                              onClick={() => {
                                dispatch(deletePreviousTaxAction(tax?._id));
                              }}
                            >
                              Delete
                            </Button>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  ) : (
                    <tbody>
                      <tr>
                        <td colSpan="4" style={{ textAlign: "center" }}>
                          <p className="mb-0 text-sm">No RECORD FOUND</p>
                          {/* <Spinner
                            size="lg"
                            style={{ display: "block", margin: "0 auto" }}
                          /> */}
                        </td>
                      </tr>
                    </tbody>
                  )}
                </Table>
              </div>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default PreviousTaxes;

export const users = [
  {
    id: 1,
    name: "John Doe",
    email: "johndoe@example.com",
  },
  {
    id: 2,
    name: "Jane Smith",
    email: "janesmith@example.com",
  },
  {
    id: 3,
    name: "Alice Johnson",
    email: "alice@example.com",
  },
  {
    id: 4,
    name: "Jane Smith",
    email: "janesmith@example.com",
  },
  {
    id: 5,
    name: "Alice Johnson",
    email: "alice@example.com",
  },
];

export const jobs = [
  {
    id: 1,
    jobTitle: "Software Engineer",
    description: "Developing software applications",
    amount: 80000,
    status: "Completed",
  },
  {
    id: 2,
    jobTitle: "Data Analyst",
    description: "Analyzing data and generating reports",
    amount: 60000,
    status: "Cancelled",
  },
  {
    id: 3,
    jobTitle: "Graphic Designer",
    description: "Creating visual designs and graphics",
    amount: 55000,
    status: "Active",
  },
];

export const orders = [
  {
    jobId: 1,
    buyerName: "John Doe",
    sellerName: "Alice Smith",
    amount: 1000,
    status: "Completed",
  },
  {
    jobId: 2,
    buyerName: "Jane Smith",
    sellerName: "Bob Johnson",
    amount: 750,
    status: "Pending",
  },
  {
    jobId: 3,
    buyerName: "David Wilson",
    sellerName: "Eva Brown",
    amount: 500,
    status: "Failed",
  },
];

export const disputes = [
  {
    jobId: 1,
    buyerName: "John Doe",
    sellerName: "Alice Smith",
    openedBy: "UserA",
  },
  {
    jobId: 2,
    buyerName: "Jane Smith",
    sellerName: "Bob Johnson",
    openedBy: "UserB",
  },
  {
    jobId: 3,
    buyerName: "David Wilson",
    sellerName: "Eva Brown",
    openedBy: "UserC",
  },
];

export const policies = [
  {
    username: "user1",
    policyDoc: "policy_document_1.pdf",
    accept: true,
    reject: false,
  },
  {
    username: "user2",
    policyDoc: "policy_document_2.pdf",
    accept: false,
    reject: true,
  },
];

export const ticketArray = [
  {
    ticketID: 1,
    createdBy: "User1",
    status: "Open",
  },
  {
    ticketID: 2,
    createdBy: "User2",
    status: "Closed",
  },
  {
    ticketID: 3,
    createdBy: "User3",
    status: "InProgress",
  },
];

export const taxOrderDetails = [
  {
    orderID: 1,
    orderPrice: 100.0,
    sellerName: "Seller1",
    orderCompletionDate: "2023-10-07",
  },
  {
    orderID: 2,
    orderPrice: 75.5,
    sellerName: "Seller2",
    orderCompletionDate: "2023-10-08",
  },
  {
    orderID: 3,
    orderPrice: 50.2,
    sellerName: "Seller3",
    orderCompletionDate: "2023-10-09",
  },
];

export const taxDetails = [
  { taxID: 1, year: 2021, file: "tax-2021.pdf" },
  { taxID: 2, year: 2022, file: "tax-2022.pdf" },
  { taxID: 3, year: 2023, file: "tax-2023.pdf" },
  // Add more objects as needed
];

export const activeUsersData = [
  { countryCode: "US", activeUsers: 1000 },
  { countryCode: "CA", activeUsers: 800 },
  { countryCode: "UK", activeUsers: 600 },
  { countryCode: "AU", activeUsers: 500 },
  { countryCode: "DE", activeUsers: 400 },
  { countryCode: "US", activeUsers: 1000 },
  { countryCode: "CA", activeUsers: 800 },
  { countryCode: "UK", activeUsers: 600 },
  { countryCode: "AU", activeUsers: 500 },
  { countryCode: "DE", activeUsers: 400 },
  { countryCode: "US", activeUsers: 1000 },
  { countryCode: "CA", activeUsers: 800 },
  { countryCode: "UK", activeUsers: 600 },
  { countryCode: "AU", activeUsers: 500 },
  { countryCode: "DE", activeUsers: 400 },
];

export const revenueData = [
  { countryCode: "US", revenueGenerated: 50000 },
  { countryCode: "CA", revenueGenerated: 40000 },
  { countryCode: "UK", revenueGenerated: 35000 },
  { countryCode: "AU", revenueGenerated: 30000 },
  { countryCode: "DE", revenueGenerated: 28000 },
  { countryCode: "US", revenueGenerated: 50000 },
  { countryCode: "CA", revenueGenerated: 40000 },
  { countryCode: "UK", revenueGenerated: 35000 },
  { countryCode: "AU", revenueGenerated: 30000 },
  { countryCode: "DE", revenueGenerated: 28000 },
  { countryCode: "US", revenueGenerated: 50000 },
  { countryCode: "CA", revenueGenerated: 40000 },
  { countryCode: "UK", revenueGenerated: 35000 },
  { countryCode: "AU", revenueGenerated: 30000 },
  { countryCode: "DE", revenueGenerated: 28000 },
];

export const resolutions = [
  {
    resolutionName: "Resolution 1",
    resolutionEmail: "resolution1@example.com",
    resolutionPassword: "password1",
  },
  {
    resolutionName: "Resolution 2",
    resolutionEmail: "resolution2@example.com",
    resolutionPassword: "password2",
  },
  {
    resolutionName: "Resolution 3",
    resolutionEmail: "resolution3@example.com",
    resolutionPassword: "password3",
  },
  {
    resolutionName: "Resolution 1",
    resolutionEmail: "resolution1@example.com",
    resolutionPassword: "password1",
  },
  {
    resolutionName: "Resolution 2",
    resolutionEmail: "resolution2@example.com",
    resolutionPassword: "password2",
  },
  {
    resolutionName: "Resolution 3",
    resolutionEmail: "resolution3@example.com",
    resolutionPassword: "password3",
  },
];

export const teamSupport = [
  {
    id: 1,
    teamName: "Team 1",
    teamEmail: "team1@example.com",
    teamPassword: "password1",
  },
  {
    id: 2,
    teamName: "Team 2",
    teamEmail: "team2@example.com",
    teamPassword: "password2",
  },
  {
    id: 3,
    teamName: "Team 3",
    teamEmail: "team3@example.com",
    teamPassword: "password3",
  },
  {
    id: 4,
    teamName: "Team 1",
    teamEmail: "team1@example.com",
    teamPassword: "password1",
  },
  {
    id: 5,
    teamName: "Team 2",
    teamEmail: "team2@example.com",
    teamPassword: "password2",
  },
  {
    id: 6,
    teamName: "Team 3",
    teamEmail: "team3@example.com",
    teamPassword: "password3",
  },
];

export function formatDate(inputDate) {
  var dateObject = new Date(inputDate);
  var options = {
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
  };
  var formattedDate = dateObject.toLocaleString("en-US", options);
  formattedDate = formattedDate.replace(/\//g, "-");

  return formattedDate;
}

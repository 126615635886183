import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  Row,
} from "reactstrap";
import { getCurrentMonthSales } from "store/actions/dashboardActions";

const SpentRevenueModal = ({ modal, toggle }) => {
  const { id } = useParams();
  const { monthTotalPages, monthOrders } = useSelector(
    (state) => state.dashboard
  );
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    const role = "buyer";
    let payload = {
      id,
      role,
      page: currentPage,
    };
    dispatch(getCurrentMonthSales(payload));
  }, [id, dispatch, currentPage]);
  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= monthTotalPages) {
      setCurrentPage(newPage);
    }
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Current Month Spent</ModalHeader>
        <ModalBody className="p-0">
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Job Title</th>
                <th scope="col">Buyer</th>
                <th scope="col">Seller</th>
                <th scope="col">Amount</th>
              </tr>
            </thead>

            {monthOrders && monthOrders?.length > 0 ? (
              <>
                <tbody>
                  {monthOrders?.map((order) => {
                    return (
                      <tr>
                        <td>{order?._id}</td>
                        <td>{order?.buyer?.firstName}</td>
                        <td>{order?.service?.title}</td>
                        <td>{order?.totalPrice}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </>
            ) : (
              <tr className="text-center">No Orders Found</tr>
            )}
          </Table>
          {monthTotalPages > 0 && (
            <div className="py-4">
              <nav aria-label="...">
                <ReactPaginate
                  previousLabel={"<"}
                  nextLabel={">"}
                  breakLabel={"..."}
                  breakClassName={"break-me"}
                  pageCount={monthTotalPages}
                  marginPagesDisplayed={1}
                  pageRangeDisplayed={2}
                  onPageChange={handlePageChange}
                  containerClassName={
                    "pagination px-1 justify-content-end mb-0"
                  }
                  activeClassName={"active"}
                  pageClassName={"page-item"}
                  pageLinkClassName={"page-link"}
                  previousClassName={"page-item"}
                  nextClassName={"page-item"}
                  previousLinkClassName={"page-link"}
                  nextLinkClassName={"page-link"}
                />
              </nav>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle} size="sm">
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SpentRevenueModal;

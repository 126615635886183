import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { searchSupportPersonAction } from "store/actions/supportActions";
import { assignTicketAction } from "store/actions/ticketsAction";

const AssignTicketModal = ({ modal, toggle, singleTicket }) => {
  const { searchData } = useSelector((state) => state.support);
  const [loading, setLoading] = useState(false);
  console.log(searchData);
  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");

  const handleFilter = (e) => {
    setFilter(e.target.value);
  };

  const handleAssignTicket = (supportPersonId) => {
    setLoading(true);
    dispatch(
      assignTicketAction(supportPersonId, singleTicket?._id, () => {
        setLoading(false);
        toggle();
        setFilter("");
      })
    );
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(searchSupportPersonAction(filter));
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Search and Assign Ticket</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <div className="d-lg-flex align-items-center ">
              <FormGroup className="w-100 w-md-75">
                <Label>Search Support Person</Label>
                <Input
                  placeholder="search here ...."
                  value={filter}
                  onChange={handleFilter}
                />
              </FormGroup>

              <div className="d-flex m-0 mt-md-2 ml-md-3">
                <Button type="submit" size="md" color="primary">
                  Search
                </Button>
              </div>
            </div>
          </Form>
          {searchData?.length > 0 ? (
            searchData?.map((data) => (
              <Card key={data.id} className="my-3">
                <div className="d-flex justify-content-between align-items-center p-2">
                  <div>
                    <p className="mb-0">
                      {data?.firstName + " " + data?.lastName}
                    </p>
                  </div>

                  <div onClick={() => handleAssignTicket(data?.id)}>
                  <Button className="" size="sm" color="primary" disabled={loading}>
                      {loading ? <Spinner size="sm" /> : "Assign"}
                    </Button>
                  </div>
                </div>
              </Card>
            ))
          ) : (
            <div className="my-3">
              <p className="text-center">Support person not available</p>
            </div>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AssignTicketModal;

import OnlyHeader from "components/Headers/OnlyHeader";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { getAllSupportPersons } from "store/actions/supportActions";
import { ticketsSearchByIdAction } from "store/actions/ticketsAction";
import { getAllTickets } from "store/actions/ticketsAction";
import { getUsers } from "store/actions/usersAction";
const Tickets = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { allTickets, totalPages, allTicketsLoader } = useSelector(
    (state) => state.tickets
  );
  console.log("allTickets: ", allTickets);
  const { supportPersons } = useSelector((state) => state.support);
  const { allUsers } = useSelector((state) => state.users);
  const [selectedOption, setSelectedOption] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsSearchId, setTicketsSearchId] = useState("");
  console.log("ticketsSearchId: ", ticketsSearchId);
  const itemsPerPage = 10;

  const handleOnSumbit = () => {
    setCurrentPage(1);
    dispatch(ticketsSearchByIdAction(ticketsSearchId, currentPage));
  };

  const getSupportPersonDetails = (id) => {
    return supportPersons?.find((person) => {
      return person?.id === id;
    });
  };

  const handleSelectChange = (event) => {
    const status = event.target.value;
    setSelectedOption(status);
    dispatch(getAllTickets(currentPage, itemsPerPage, status));
  };

  const getCreatedByUser = (id) => {
    return allUsers?.find((person) => {
      return person?.id === id;
    });
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  useEffect(() => {
    dispatch(getAllTickets(currentPage, itemsPerPage, selectedOption));
    dispatch(getAllSupportPersons(currentPage, itemsPerPage));
    dispatch(getUsers(currentPage, itemsPerPage));
  }, [currentPage, itemsPerPage, selectedOption]);

  const handleClearSearch = () => {
    setTicketsSearchId("");
    dispatch(getAllTickets(currentPage, itemsPerPage, selectedOption));
    dispatch(getAllSupportPersons(currentPage, itemsPerPage));
    dispatch(getUsers(currentPage, itemsPerPage));
  };

  // format the date and time

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center align-items-lg-start flex-xl-row">
                  <Col className="d-flex justify-content-between align-items-center col-12 col-xl-5">
                    <h3 className="text-center text-xl-left">Tickets</h3>
                    <FormGroup className="mb-xl-0">
                      <Input
                        type="select"
                        name="select"
                        id="exampleSelect"
                        value={selectedOption}
                        onChange={handleSelectChange}
                      >
                        <option value="">All Tickets</option>
                        <option value="in progress">In Progress</option>
                        <option value="closed">Closed</option>
                        <option value="open">Open</option>
                        <option value="completed">Completed</option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="userSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={ticketsSearchId}
                          onChange={(e) => setTicketsSearchId(e.target.value)}
                          // bsSize="sm"
                          // className="py-3"
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Ticket ID
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        onClick={handleOnSumbit}
                        disabled={allTicketsLoader}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        disabled={allTicketsLoader}
                        onClick={handleClearSearch}
                        size="sm"
                      >
                        Clear Search
                      </Button>
                    </div>
                  </Col>
                </Row>
                {/* <div className="d-lg-flex justify-content-between align-items-center">
                  <h3>Tickets</h3>
                  <Row className="align-items-center">
                    <Col lg={5} xs={8} className="pr-lg-0">
                      <FormGroup className="mb-lg-0">
                        <Input
                          id="TicketsSearch"
                          name="Search"
                          placeholder="Search...."
                          type="text"
                          value={ticketsSearchId}
                          onChange={(e) => setTicketsSearchId(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      lg={3}
                      xs={4}
                      className="px-lg-0 text-lg-center text-right"
                    >
                      <Button
                        color="primary"
                        className="mb-lg-0 mb-4 px-3"
                        onClick={handleOnSumbit}
                      >
                        Search
                      </Button>
                    </Col>
                    <Col lg={4} xs={12} className="pl-lg-0">
                      <FormGroup className="mb-lg-0">
                        <Input
                          type="select"
                          name="select"
                          id="exampleSelect"
                          value={selectedOption}
                          onChange={handleSelectChange}
                        >
                          <option value="">All Tickets</option>
                          <option value="in progress">In Progress</option>
                          <option value="closed">Closed</option>
                          <option value="open">Open</option>
                          <option value="completed">Completed</option>
                        </Input>
                      </FormGroup>
                    </Col>
                  </Row>
                </div> */}
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Ticket ID</th>
                    <th scope="col">Ticket No</th>
                    <th scope="col">Created By</th>
                    <th scope="col">Subject</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Assigned To</th>

                    <th scope="col">Status</th>

                    <th>Action</th>
                  </tr>
                </thead>
                {!allTicketsLoader ? (
                  <tbody>
                    {allTickets && allTickets.length > 0 ? (
                      allTickets?.map((ticket, index) => (
                        <tr key={index}>
                          <td>{ticket?._id}</td>
                          <td>{ticket?.ticketNumber}</td>
                          <td>{ticket?.createdBy?.email}</td>
                          <td>{ticket?.subject}</td>
                          <td>{formatTimestamp(ticket?.createdAt)}</td>
                          <td>
                            {formatTimestamp(ticket?.updatedAt)
                              ? formatTimestamp(ticket?.updatedAt)
                              : "---"}
                          </td>
                          <td>
                            {ticket?.assignedTo?.email
                              ? ticket?.assignedTo?.email
                              : "Not Assigned "}
                          </td>
                          <td>{ticket?.status}</td>

                          <td>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() => {
                                history.push(
                                  `/admin/ticket-details/${ticket?._id}`
                                );
                              }}
                            >
                              View Details
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <p className="mb-0 text-sm">No Tickets Found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Tickets;

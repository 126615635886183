const initState = {
  allTaxes: [],
  previousTaxes: [],
  submitTaxLoader: false,
};
const taxReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_TAXES":
      console.log(payload);
      return {
        ...state,
        allTaxes: payload,
      };
    case "GET_PREVIOUS_TAXES":
      console.log(payload);
      return {
        ...state,
        previousTaxes: payload,
      };
    case "SUBMIT_TAX_LOADER":
      return {
        ...state,
        submitTaxLoader: payload,
      };
    case "DELETE_TAX":
      return {
        ...state,
        allTaxes: state.allTaxes.filter((tax) => tax._id !== payload),
      };
    case "TAX_LOADER":
      return {
        ...state,
        taxLoader: payload,
      };

    case "DELETE_PREVIOUS_TAX":
      return {
        ...state,
        previousTaxes: state.previousTaxes.filter((tax) => tax._id !== payload),
      };
    default:
      return {
        ...state,
      };
  }
};
export default taxReducer;

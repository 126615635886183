import ChatBody from "components/Chat/ChatBody";
import ChatFooter from "components/Chat/ChatFooter";
import ChatHeader from "components/Chat/ChatHeader";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Container } from "reactstrap";
import { supportAddCommentAction } from "store/actions/ticketsAction";

const CustomerSupport = ({ ticketChat }) => {
  console.log('>>>Chat_Mesgs_ticketChat: ', ticketChat);
  const dispatch = useDispatch();
  const [chats, setChats] = useState([]);

  const handleSendClick = (message) => {
    console.log('<<<<Received message:', message);
    dispatch(supportAddCommentAction(ticketChat?._id, { text: message }));
    setChats([...chats, { text: message }]);
  };

  return (
    <>
      <Container fluid className="mt-4">
          <ChatHeader profileImage={ticketChat?.createdBy?.profileImage}
          name={`${ticketChat?.createdBy?.firstName} ${ticketChat?.createdBy?.lastName}`} />
        <ChatBody
          ticketChat={ticketChat}
          messages={chats}
        />
        <ChatFooter onSendClick={handleSendClick} />
      </Container>
    </>
  );
};

export default CustomerSupport;

import { RepositoryFactory } from "repository/RepositoryFactory";

const deletedJobs = RepositoryFactory.get("deletedJobs");

export const getAllDeletedJobs = (page, limit) => async (dispatch) => {
  console.log(page, limit);
  try {
    dispatch({ type: "DELETED_JOB_LOADER", payload: true });
    const { data } = await deletedJobs.getDeletedJobs(page, limit);
    console.log(data);

    dispatch({
      type: "GET_DELETED_JOBS",
      payload: data,
    });
    dispatch({ type: "DELETED_JOB_LOADER", payload: false });
  } catch (err) {
    console.log(err.message);
    dispatch({ type: "DELETED_JOB_LOADER", payload: false });
  }
};

export const JobSearchByNameAction = (name) => async (dispatch) => {
    console.log(name)
    try {
      dispatch({
        type: "DELETED_JOB_LOADER",
        payload: true,
      });
      const { data } = await deletedJobs.jobSearchByName(name);
      console.log(data)
      dispatch({
        type: "GET_JOB_BY_NAME",
        payload: data,
      });
      dispatch({
        type: "DELETED_JOB_LOADER",
        payload: false,
      });
    } catch (error) {
      dispatch({
        type: "DELETED_JOB_LOADER",
        payload: false,
      });
    }
  };

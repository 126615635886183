import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { addNewSkill } from "store/actions/skillsAction";
import { getSkillsAction } from "store/actions/skillsAction";

const AddSkillModal = ({ modal, toggle, currentPage, itemsPerPage }) => {
  const dispatch = useDispatch();
  const { addSkillLoader } = useSelector((state) => state.skills);
  const [skillName, setSkillName] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!skillName.trim()) {
      toast.error("Add skill name");
      return;
    }

    dispatch(
      addNewSkill(
        {
          name: skillName,
        },
        () => {
          toggle();
          dispatch(getSkillsAction(currentPage, itemsPerPage));
          setSkillName("");
        }
      )
    );
  };

  const handleNameChange = (e) => {
    setSkillName(e.target.value);
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Add Skill</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Skill</Label>
                <FormGroup>
                  <Input
                    placeholder="Enter Skill"
                    value={skillName}
                    onChange={handleNameChange}
                  />
                </FormGroup>
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-items-center">
              <Button color="primary" type="submit" disabled={addSkillLoader}>
                {addSkillLoader ? <Spinner size="sm" /> : "Add"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default AddSkillModal;

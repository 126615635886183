import OnlyHeader from "components/Headers/OnlyHeader";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { updateEmailAction } from "store/actions/authAction";
import { accountPasswordUpdate } from "store/actions/usersAction";

const Profile = () => {
  const dispatch = useDispatch();
  const { user, emailUpdateSuccess, email } = useSelector((state) => state.auth);
  console.log("emailUpdateSuccess: ", emailUpdateSuccess);
  const { passwordUpdateLoader } = useSelector((state) => state.users);
  // Password States --- Start
  const [passwordDetails, setPasswordDetails] = useState({
    currentPassword: "",
    newPassword: "",
  });
  const [showCurrentPassword, setShowCurrentPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);

  const [updateEmailLoading, setUpdateEmailLoading] = useState(false);
  const [updatePasswordLoading, setUpdatePasswordLoading] = useState(false);
  // Password States --- End
  const [userEmail, setUserEmail] = useState("");
  console.log("userEmail: ", userEmail);
  const [profile, setProfile] = useState({
    firstName: "",
    lastName: "",
  });

  // Password Hide/ Show Function --- Start

  const handlePasswordToggle = (field) => {
    if (field === "current") {
      setShowCurrentPassword(!showCurrentPassword);
    } else if (field === "new") {
      setShowNewPassword(!showNewPassword);
    }
  };
  // Password Hide/ Show Function --- End

  const handlePassword = async (e) => {
    e.preventDefault();

    if (!passwordDetails.currentPassword || !passwordDetails.newPassword) {
      toast.error("Please fill all the fields");
      return;
    }

    setUpdatePasswordLoading(true);

    try {
      await dispatch(accountPasswordUpdate(passwordDetails));
      // Reset password details after successful API call
      setPasswordDetails({
        currentPassword: "",
        newPassword: "",
      });
    } catch (error) {
      // Handle error if needed
      console.error("Password update failed:", error);
    } finally {
      setUpdatePasswordLoading(false);
    }
  };

  const handleEmail = async (e) => {
    e.preventDefault();

    if (!userEmail) {
      toast.error("Please fill all the fields");
      return;
    }

    setUpdateEmailLoading(true);
    try {
      await dispatch(
        updateEmailAction(
          userEmail,
          () => {},
          () => {}
        )
      );
    } catch (error) {
      console.error("Email update failed:", error);
    } finally {
      setUpdateEmailLoading(false);
    }
  };

  useEffect(() => {
    if (user && email) {
      setProfile({
        firstName: user?.firstName,
        lastName: user?.lastName,
      });
      
      setUserEmail(email);

      if (emailUpdateSuccess) {
        toast.info("This session has expired. Please check your email and restart the session after confirming.");
      }
    }
  }, [emailUpdateSuccess, user, email]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-lg-flex justify-content-between align-items-center">
                  <h3>Profile</h3>
                </div>
              </CardHeader>

              <CardBody>
                <Row>
                  <Col md="6" className="m-auto">
                    <div>
                      <Label className="font-weight-bold">First Name</Label>
                      <FormGroup>
                        <Input
                          name="firstName"
                          value={profile?.firstName}
                          placeholder="First Name"
                          disabled
                        />
                      </FormGroup>
                    </div>
                    <div>
                      <Label className="font-weight-bold">Last Name</Label>
                      <FormGroup>
                        <Input
                          name="lastName"
                          value={profile?.lastName}
                          placeholder="Last Name"
                          disabled
                        />
                      </FormGroup>
                    </div>
                  </Col>
                </Row>
                <hr />
                <Form onSubmit={handleEmail}>
                  <Row>
                    <Col md="6" className="m-auto">
                      <div>
                        <Label className="font-weight-bold">
                          Email Address
                        </Label>
                        <FormGroup>
                          <Input
                            name="email"
                            type="email"
                            value={userEmail}
                            onChange={(e) => setUserEmail(e.target.value)}
                            placeholder="Email address"
                          />
                        </FormGroup>
                      </div>
                      <div className="d-flex justify-content-end mt-3">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={updateEmailLoading}
                        >
                          {updateEmailLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            "Verify & Update Email"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
                <hr />
                <Form onSubmit={handlePassword}>
                  <Row>
                    <Col md="6" className="m-auto">
                      <div>
                        <Label className="font-weight-bold">Password</Label>
                        <div className="input-group">
                          <Input
                            type={showCurrentPassword ? "text" : "password"}
                            name="currentPassword"
                            value={passwordDetails?.currentPassword}
                            onChange={(e) =>
                              setPasswordDetails({
                                ...passwordDetails,
                                currentPassword: e.target.value,
                              })
                            }
                            placeholder="Current Password"
                            className="pr-10"
                          />
                          <div className="input-group-append">
                            <span
                              onClick={() => handlePasswordToggle("current")}
                              className="input-group-text cursor-pointer"
                            >
                              {showCurrentPassword ? (
                                <i className="fas fa-eye text-gray-500"></i>
                              ) : (
                                <i className="fas fa-eye-slash text-gray-500"></i>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div>
                        <Label className="font-weight-bold">New Password</Label>
                        <div className="input-group">
                          <Input
                            type={showNewPassword ? "text" : "password"}
                            name="newPassword"
                            value={passwordDetails?.newPassword}
                            onChange={(e) =>
                              setPasswordDetails({
                                ...passwordDetails,
                                newPassword: e.target.value,
                              })
                            }
                            placeholder="New Password"
                            className="pr-10"
                          />
                          <div className="input-group-append">
                            <span
                              onClick={() => handlePasswordToggle("new")}
                              className="input-group-text cursor-pointer"
                            >
                              {showNewPassword ? (
                                <i className="fas fa-eye text-gray-500"></i>
                              ) : (
                                <i className="fas fa-eye-slash text-gray-500"></i>
                              )}
                            </span>
                          </div>
                        </div>
                      </div>

                      <div className="d-flex justify-content-end mt-3">
                        <Button
                          color="primary"
                          type="submit"
                          disabled={updatePasswordLoading}
                        >
                          {updatePasswordLoading ? (
                            <Spinner size="sm" />
                          ) : (
                            " Update Password"
                          )}
                        </Button>
                      </div>
                    </Col>
                  </Row>
                </Form>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Profile;

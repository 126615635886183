import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  useHistory,
  useParams,
} from "react-router-dom/cjs/react-router-dom.min";
import {
  Card,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { getSingleReport } from "store/actions/reportAction";

function ReportDetails() {
  const { id } = useParams();
  const history = useHistory();
  const dispatch = useDispatch();
  const { singleReport, getReportsLoader } = useSelector(
    (state) => state.reports
  );
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);

  const handleGoBack = () => {
    history.goBack();
  };
  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  useEffect(() => {
    dispatch(getSingleReport(id));
  }, []);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <div className="col">
            <Card className="shadow">
              <CardHeader className="bg-transparent border-0 d-lg-flex justify-content-between align-items-center">
                <h3
                  className="mb-0 cursor-pointer text-sm"
                  onClick={handleGoBack}
                >
                  <i className="fas fa-chevron-left mr-2"></i> Back to Reports
                </h3>
              </CardHeader>
              {!getReportsLoader ? (
                <div className="p-5">
                  <Row>
                    <Col md={12}>
                      <div className="">
                        <h4 className="">Report Time:</h4>
                        <p className="text-muted">
                          {formatTimestamp(singleReport?.createdAt)}{" "}
                        </p>
                      </div>
                    </Col>
                    <Col md={6} sm="12">
                      <div className="mb-4">
                        <h4 className="mb-2">Reported By:</h4>
                        <p className="text-muted">
                          {singleReport?.reportedBy?.firstName +
                            " " +
                            singleReport?.reportedBy?.lastName}
                        </p>
                      </div>
                      <div className="mb-4">
                        <h4 className="mb-2">Reported User:</h4>
                        <p className="text-muted">
                          {singleReport?.user?.firstName +
                            " " +
                            singleReport?.user?.lastName}
                        </p>
                      </div>
                    </Col>
                    <Col md={6} sm="12">
                      <div className="mb-4">
                        <h4 className="mb-2">Reporter ID:</h4>
                        <p className="text-muted">
                          {singleReport?.reportedBy?.userId}
                        </p>
                      </div>
                      <div className="mb-4">
                        <h4 className="mb-2">Reported User ID:</h4>
                        <p className="text-muted">
                          {singleReport?.user?.userId}
                        </p>
                      </div>
                    </Col>
                    <Col md={12}>
                      <div className="mb-4">
                        <h4 className="mb-2">Description:</h4>
                        <p className="text-muted">
                          {singleReport?.description}
                        </p>
                      </div>
                    </Col>
                  </Row>

                  {singleReport?.file && (
                    <div>
                      <h4 className="mb-2">Attachment:</h4>
                      {/* <img
                        src={singleReport?.file}
                        alt="Report Image"
                        style={{ width: "150px" }}
                        className="img-fluid cursor-pointer"
                        onClick={() => handleImageClick(singleReport?.file)}
                      /> */}
                      <a
                        href={singleReport?.file}
                        target="_blank"
                        download
                        className="btn btn-primary btn-sm"
                        style={{ textDecoration: "none" }}
                      >
                        Download Attachment
                      </a>
                    </div>
                  )}
                </div>
              ) : (
                <Row className="d-flex justify-content-center mb-5">
                  <Spinner size="sm" />
                </Row>
              )}
            </Card>
          </div>
        </Row>
        <Modal
          isOpen={isImageModalOpen}
          toggle={() => setImageModalOpen(!isImageModalOpen)}
        >
          <ModalHeader toggle={() => setImageModalOpen(!isImageModalOpen)}>
            Image Preview
          </ModalHeader>
          <ModalBody>
            <img src={selectedImage} alt="Preview" className="img-fluid" />
          </ModalBody>
        </Modal>
      </Container>
    </>
  );
}

export default ReportDetails;

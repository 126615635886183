import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Media,
  Row,
  Spinner,
  Table,
  Badge,
} from "reactstrap";
import { getAllCheckrReports } from "store/actions/checkrReportAction";
import CheckrReportDocumentsModal from "components/Modals/checkrReportModals/CheckrReportDocumentsModal";
import { engagedCheckrReport } from "store/actions/checkrReportAction";
import dayjs from "dayjs";

const CheckrReport = () => {
  const { allCheckrReports, checkrReportsLoader, engagedCheckrReportLoader } =
    useSelector((state) => state.checkrReports);
  const dispatch = useDispatch();
  const history = useHistory();
  const [currentPage, setCurrentPage] = useState(1);
  const [documentsModal, setDocumentsModal] = useState(false);

  const [documents, setDocuments] = useState([]);

  console.log("hamza Test 1234 all checkr reports", allCheckrReports);

  const itemsPerPage = 10;
  // const handlePageChange = ({ selected }) => {
  //   const newPage = selected + 1;
  //   if (newPage >= 1 && newPage <= totalPages) {
  //     setCurrentPage(newPage);
  //   }
  // };

  const handleEngaged = (candidateId, reportId) => {
    const url = `${process.env.REACT_APP_CHECKR_ENGAGED_BASE_URL}candidates/${candidateId}/reports/${reportId}`;
    window.open(url, "_blank");

    // dispatch(engagedCheckrReport(userId));
  };

  const toggleDocumentsModal = () => {
    setDocumentsModal(!documentsModal);
  };

  const openDocumentsModal = () => {
    setDocumentsModal(true);
  };

  useEffect(() => {
    dispatch(getAllCheckrReports(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <div>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="">
                    <h3 className="text-center text-xl-left">Checkr Reports</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">User ID</th>
                    <th scope="col">Name</th>
                    <th scope="col">Candidate ID</th>
                    <th scope="col">Status</th>
                    <th scope="col">Documents</th>
                    <th scope="col">Date</th>
                    <th scope="col" align="right">
                      Actions
                    </th>
                  </tr>
                </thead>
                {!checkrReportsLoader ? (
                  <tbody>
                    {allCheckrReports && allCheckrReports.length > 0 ? (
                      allCheckrReports?.map((checkReport, index) => (
                        <tr key={index}>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {checkReport?.userId?.userId}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {checkReport?.userId?.firstName +
                                    " " +
                                    checkReport?.userId?.lastName}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {checkReport?.userId?.candidateId}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {checkReport?.status === "pending" ? (
                                    <Badge color="warning">
                                      {checkReport?.status}
                                    </Badge>
                                  ) : checkReport?.status === "failed" ? (
                                    <Badge color="danger">
                                      {checkReport?.status}
                                    </Badge>
                                  ) : (
                                    <Badge color="success">
                                      {checkReport?.status}
                                    </Badge>
                                  )}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() => {
                                setDocuments(checkReport?.documents);
                                openDocumentsModal();
                              }}
                            >
                              View Documents
                            </Button>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {dayjs(checkReport?.userId?.createdAt).format(
                                    "MM-DD-YYYY"
                                  )}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() =>
                                handleEngaged(
                                  checkReport?.userId?.candidateId,
                                  checkReport?.reportId
                                )
                              }
                            >
                              Engaged
                              {/* {engagedCheckrReportLoader ? (
                                <Spinner size="sm" />
                              ) : (
                                "Engaged"
                              )} */}
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          {allCheckrReports && allCheckrReports.length === 0 ? (
                            <p className="mb-0 text-sm">
                              No Checkr Reports Found.
                            </p>
                          ) : (
                            <Spinner
                              size="lg"
                              style={{
                                display: "block",
                                margin: "0 auto",
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {/* {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )} */}
            </Card>
          </div>
        </Row>
      </Container>
      <CheckrReportDocumentsModal
        modal={documentsModal}
        toggle={toggleDocumentsModal}
        data={documents}
      />
    </div>
  );
};

export default CheckrReport;

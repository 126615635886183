import Repository from "./Repository";
const GET_ALL_ORDERS = "order/all";
const GET_ORDER_SEARCH_BY_ID = "order/search";

export default {
  getOrders(status, page) {
    return Repository.get(
      `${GET_ALL_ORDERS}?page=${page}&limit=10${
        status ? `&status=${status}` : ""
      }`
    );
  },
  orderSearchById(id, page) {
    return Repository.get(`${GET_ORDER_SEARCH_BY_ID}/${id}?page=${page}`);
  },
};

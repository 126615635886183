const initState = {
  allDisputes: [],
  getDisputesLoader: false,
  chatLoader: false,
  singleDispute: {},
  disputeChat: [],
  totalPages: 0,
};
const disputeReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_DISPUTES_LOADER":
      return {
        ...state,
        getDisputesLoader: payload,
      };
    case "CHAT_ORDER_LOADER":
      return {
        ...state,
        chatLoader: payload,
      };
    case "GET_DISPUTES":
      return {
        ...state,
        allDisputes: payload.data,
        disputeChat: [],
        totalPages: payload?.totalPages,
      };
    case "GET_DISPUTE_SEARCH_BY_ID":
      return {
        ...state,
        allDisputes: payload?.disputedOrders,
        totalPages: payload?.totalPages,
      };
    case "SINGLE_DISPUTE":
      return {
        ...state,
        singleDispute: payload,
      };
    case "SINGLE_DISPUTE_CHAT":
      return {
        ...state,
        disputeChat: [...state.disputeChat, ...payload?.data?.messages],
      };
    case "EMPTY_DISPUTE_CHAT":
      return {
        ...state,
        disputeChat: [],
      };
    default:
      return {
        ...state,
      };
  }
};
export default disputeReducer;

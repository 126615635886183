const initState = {
  allCheckrReports: [],
  checkrReportsLoader: false,
  engagedCheckrReportLoader: false,
};

const checkrReportsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_CHECKR_REPORTS":
      return {
        ...state,
        allCheckrReports: payload.data,
        totalPages: payload.totalPages,
      };
    case "CHECKR_REPORTS_LOADER":
      return {
        ...state,
        checkrReportsLoader: payload,
      };
    case "ENGAGED_CHECKR_REPORT_LOADER":
      return {
        ...state,
        engagedCheckrReportLoader: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default checkrReportsReducer;

import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer } from "react-toastify";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { getAllSupportPersons } from "store/actions/supportActions";
import OnlyHeader from "components/Headers/OnlyHeader";
import CreateSupportModal from "components/Modals/teamSupportModals/CreateSupportModal";
import DeleteTeamModal from "components/Modals/teamSupportModals/DeleteTeamModal";
import UpdateSupportModal from "components/Modals/teamSupportModals/UpdateSupportModal";
import { supportSearchByNameAction } from "store/actions/supportActions";

const SupportTeam = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { supportPersons, totalPages, getSupportLoader } = useSelector(
    (state) => state.support
  );
  console.log("supportPersons: ", supportPersons);

  // console.log("supportPersons: ", supportPersons);
  const [teamID, setTeamID] = useState("");
  const [isModalOpen, setModalOpen] = useState(false);
  const [editData, setEditData] = useState(null);
  const [isEditModalOpen, setEditModalOpen] = useState(false);
  const [supportByName, setSupportByName] = useState("");
  console.log("supportByName: ", supportByName);

  const handleOnSumbit = () => {
    dispatch(supportSearchByNameAction(supportByName));
  };

  const toggleModal = () => {
    setModalOpen(!isModalOpen);
    setEditData(null);
  };

  const toggleEditModal = () => {
    setEditModalOpen(!isEditModalOpen);
    setEditData(null);
  };

  const openEditModal = () => {
    setEditModalOpen(true);
  };

  const [deleteModal, setDeleteModal] = useState(false);

  const deleteTeamModal = () => setDeleteModal(!deleteModal);

  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5;

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  // format the date and time
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };
  const handleClearSearch = () => {
    setSupportByName("");
    dispatch(getAllSupportPersons(currentPage, itemsPerPage));
  };

  useEffect(() => {
    dispatch(getAllSupportPersons(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <>
      <ToastContainer />
      <UpdateSupportModal
        modal={isEditModalOpen}
        toggle={toggleEditModal}
        data={editData}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
      <CreateSupportModal
        modal={isModalOpen}
        toggle={toggleModal}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
      <DeleteTeamModal
        id={teamID}
        modal={deleteModal}
        toggle={deleteTeamModal}
      />
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Support Team</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start ">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="ResolutionSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={supportByName}
                          onChange={(e) => setSupportByName(e.target.value)}
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Name & Email
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        onClick={handleOnSumbit}
                        disabled={getSupportLoader}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        onClick={handleClearSearch}
                        disabled={getSupportLoader}
                        size="sm"
                      >
                        Clear Search
                      </Button>

                      {user?.role === "supportPerson" ? (
                        ""
                      ) : (
                        <Button
                          color="primary"
                          onClick={toggleModal}
                          size="sm"
                          className="p-2 mx-2 my-2 my-lg-0"
                        >
                          Create
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
                {/* <div className="border-0 d-lg-flex w-100 justify-content-between">
                  <h3>Support Team</h3>
                  <Row className="align-items-center">
                    <Col lg={5} xs={8} className="pr-lg-0">
                      <FormGroup className="mb-lg-0">
                        <Input
                          id="SupportSearch"
                          name="Search"
                          placeholder="Search...."
                          type="text"
                          value={supportByName}
                          onChange={(e) => setSupportByName(e.target.value)}
                        />
                      </FormGroup>
                    </Col>
                    <Col
                      lg={3}
                      xs={4}
                      className="px-lg-0 text-lg-center text-right"
                    >
                      <Button
                        color="primary"
                        className="mb-lg-0 mb-4 px-3"
                        onClick={handleOnSumbit}
                      >
                        Search
                      </Button>
                    </Col>
                    <Col lg={4} xs={12} className="pl-lg-0">
                      {user?.role === "supportPerson" ? (
                        ""
                      ) : (
                        <Button color="primary" onClick={toggleModal}>
                          Create
                        </Button>
                      )}
                    </Col>
                  </Row>
                </div> */}
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Email</th>
                    {user?.role === "supportPerson" ? (
                      ""
                    ) : (
                      <th scope="col">Actions</th>
                    )}
                  </tr>
                </thead>
                {!getSupportLoader ? (
                  <tbody>
                    {supportPersons && supportPersons.length > 0 ? (
                      supportPersons?.map((team, index) => (
                        <tr key={index}>
                          <td>{team?.firstName}</td>
                          <td>{team?.lastName}</td>
                          <td>{formatTimestamp(team?.createdAt)}</td>
                          <td>{formatTimestamp(team?.updatedAt)}</td>
                          <td>{team?.email}</td>
                          {user?.role === "supportPerson" ? (
                            ""
                          ) : (
                            <td>
                              <Button color="primary" size="sm">
                                <i
                                  className="fa fa-edit"
                                  onClick={() => {
                                    setEditData(team);
                                    openEditModal();
                                  }}
                                ></i>
                              </Button>
                              <Button color="danger" size="sm">
                                <i
                                  className="fa fa-trash"
                                  onClick={() => {
                                    setTeamID(team?.id);
                                    deleteTeamModal();
                                  }}
                                ></i>
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <p className="mb-0 text-sm">
                            No Support Members Found.
                          </p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination className="pagination justify-content-end mb-0">
                      <div className="d-flex mb-0">
                        <PaginationItem>
                          <PaginationLink
                            previous
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          />
                        </PaginationItem>
                        {Array.from(
                          {
                            length: totalPages,
                          },
                          (_, i) => (
                            <PaginationItem
                              key={i}
                              active={i + 1 === currentPage}
                            >
                              <PaginationLink
                                onClick={() => handlePageChange(i + 1)}
                              >
                                {i + 1}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        )}
                        <PaginationItem>
                          <PaginationLink
                            next
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          />
                        </PaginationItem>
                      </div>
                    </Pagination>
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default SupportTeam;

import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import { updatePublicNotification } from "store/actions/dashboardActions";
import { deletePublicNotification } from "store/actions/dashboardActions";
import { getPublicNotification } from "store/actions/dashboardActions";
import { createPublicNotification } from "store/actions/dashboardActions";

const Message = () => {
  const dispatch = useDispatch();
  const { publicMessage, pubicLoader } = useSelector(
    (state) => state.dashboard
  );
  console.log('publicMessage: ', publicMessage);

  const [formData, setFormData] = useState({
    title: "",
    message: "",
    enable: false,
  });

  const submitMessage = (e) => {
    e.preventDefault();
    if (formData.title === "" || formData.message === "") {
      toast.error("Please fill in all fields")
      return
    }
    if (publicMessage || formData) {
      dispatch(
        updatePublicNotification(formData, () => {
          dispatch(getPublicNotification());
        })
      );
    } else {
      dispatch(
        createPublicNotification(formData, () => {
          dispatch(getPublicNotification());
        })
      );
    }
    // console.log({ formData });
  }

  useEffect(() => {
    dispatch(getPublicNotification());
  }, [dispatch]);

  useEffect(() => {
    if (publicMessage !== null && Object.keys(publicMessage).length !== 0) {
      setFormData({
        title: publicMessage?.title,
        message: publicMessage?.message,
        enable: publicMessage?.enable,
      });
    }
  }, [publicMessage]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <div className="d-flex justify-content-between align-items-center">
                  <h3 className="mb-0">Public Message</h3>
                  {/* <Button color="primary">Create</Button> */}
                </div>
              </CardHeader>
              <CardBody>
                <Row className="d-flex justify-content-center">
                  <Col lg={6} sm={10} xs={10}>
                    <Form
                      onSubmit={submitMessage}
                    >
                      <FormGroup>
                        <Label>Title</Label>
                        <Input
                          type="text"
                          placeholder="Title"
                          value={formData.title}
                          onChange={(e) =>
                            setFormData({ ...formData, title: e.target.value })
                          }
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label>Message</Label>
                        <Input
                          type="textarea"
                          placeholder="Message"
                          value={formData.message}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              message: e.target.value,
                            })
                          }
                        />
                      </FormGroup>
                      <FormGroup check>
                        <Input
                          type="checkbox"
                          checked={formData.enable}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              enable: e.target.checked,
                            })
                          }
                        />{" "}
                        <Label check>
                          Enable message
                        </Label>
                      </FormGroup>
                      <Button
                        color="primary"
                        className="my-3 px-4"
                        disabled={pubicLoader}
                        type="submit"
                      >
                        {publicMessage === null ||
                          Object.keys(publicMessage).length === 0
                          ? "Save"
                          : "Update"}
                      </Button>
                      <Button
                        color="danger"
                        disabled={
                          publicMessage === null ||
                          Object.keys(publicMessage).length === 0 ||
                          pubicLoader
                        }
                        onClick={() =>
                          dispatch(
                            deletePublicNotification(() => {
                              dispatch(getPublicNotification());
                              setFormData({
                                title: "",
                                message: "",
                                enable: false,
                              });
                            })
                          )
                        }
                        className="my-3 px-4"
                        type="button"
                      >
                        Delete
                      </Button>
                    </Form>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Message;

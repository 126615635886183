import React, { useState } from "react";
import { Button, Form, Spinner } from "reactstrap";
import MessageInput from "./MessageInput";

const ChatFooter = ({ onSendClick }) => {
  const [newMessage, setNewMessage] = useState("");
  console.log('>>>>newMessage: ', newMessage);
  const [chatLoader, setChatLoader] = useState(false);

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log('<<<<Form submitted');
    setChatLoader(true);
    if (newMessage.trim() !== "") {
      handScrollDown();
      console.log('<<<<Sending message:', newMessage);
      onSendClick(newMessage);
      setNewMessage("");
      setTimeout(() => {
        setChatLoader(false);
      }, 1000);
    }
  };

  const handScrollDown = () => {
    const element = document.getElementById("chatbox");
  
    if (element) {
      const isScrolledToBottom = element.scrollHeight - element.scrollTop === element.clientHeight;
  
      if (!isScrolledToBottom) {
        element.scrollTop = element.scrollHeight;
      }
    }
  };
  

  return (
    <Form onSubmit={handleSubmit} className="d-flex border rounded mt-3">
      <div className="d-flex align-items-center w-100 bg-white rounded position-relative">
        <MessageInput
          disabled={false}
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
        />

        <Button
          className={`bg-primary rounded border-0 message-send-btn`}
          type="submit"
          disabled={chatLoader || newMessage.trim() === ""}
        >
          {chatLoader ? (
            <Spinner size="sm" className="position-absolute" color="white" />
          ) : (
            <i className="fas text-white fa-paper-plane"></i>
          )}
        </Button>
      </div>
    </Form>
  );
};

export default ChatFooter;

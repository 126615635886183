const initState = {
  allSkills: [],
  getSkillsLoader: false,
  addSkillLoader: false,
  deleteSkillLoader: false,
  updateSkillLoader: false,
  totalPages: null,
};

const skillsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_SKILLS":
      return {
        ...state,
        allSkills: payload.skills,
        totalPages: payload.totalPages,
      };
    case "GET_SKILLS_LOADER":
      return {
        ...state,
        getSkillsLoader: payload,
      };
    case "ADD_SKILL_LOADER":
      return {
        ...state,
        addSkillLoader: payload,
      };
    case "DELETE_SKILL_LOADER":
      return {
        ...state,
        deleteSkillLoader: payload,
      };
    case "UPDATE_SKILL_LOADER":
      return {
        ...state,
        updateSkillLoader: payload,
      };
    case "GET_SKILL_SEARCH_BY_TITLE":
      return {
        ...state,
        allSkills: payload.data,
        totalPages: payload.totalPages,
      };
    default:
      return {
        ...state,
      };
  }
};
export default skillsReducer;

import React, { useState, useCallback, useEffect } from "react";
import debounce from "lodash.debounce";
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  Row,
  Spinner,
} from "reactstrap";
import ConversationItem from "./ConversationItem";
import ChatHeader from "./ChatHeader";
import ChatBody from "./ChatBody";
import ChatFooter from "./ChatFooter";
import { useDispatch, useSelector } from "react-redux";
import { userSearchSupportChat } from "store/actions/usersAction";
import { sendMessage } from "store/actions/chatAction";
import { toast } from "react-toastify";
import { getConversation } from "store/actions/chatAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSearch, faTimes } from "@fortawesome/free-solid-svg-icons";
import { ShimmerCategoryList } from "react-shimmer-effects";
import { socket } from "config/socketConfig";
import { updateChatMesssages } from "store/actions/chatAction";
import { updateConversation } from "store/actions/chatAction";
import { updateActiveConvercation } from "store/actions/chatAction";
import { pageRefresh } from "config/socketConfig";
import { updateserSearchSupportChat } from "store/actions/usersAction";
import { getChatMessages } from "store/actions/chatAction";
import { updateAllConversation } from "store/actions/chatAction";

const ChatSupport = () => {
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  const [buttonLoader, setButtonLoader] = useState(false);
  const [dropdownLoader, setDropDownLoader] = useState(false);
  const [participantId, setParticipantId] = useState("");
  const [isDropDown, setIsDropDown] = useState(false);
  const [activeIndex, setActiveIndex] = useState();
  const [toggleChatCmp, setToggleChatCmp] = useState(false);
  const { usersSupportChat } = useSelector((state) => state.users);
  const { user } = useSelector((state) => state.auth);
  const { token, ...userData } = user;

  const [selectedUser, setSelectedUser] = useState();
  const [totalUnread, setTotalUnread] = useState(0);
  const {
    allConversations,
    chatMessages,
    activeConversation,
    conversationLoading,
  } = useSelector((state) => state.chats);
  const [searchchats, setSearchChats] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchActive, setSearchActive] = useState(false);
  const toggleSearch = () => {
    setSearchActive(!searchActive);
    setSearchValue("");
  };
  const handleSelectChange = (event) => {
    setInputValue(event.target.innerText);
  };
  const findUserIdInConversation = (con, id) => {
    return con?.map((obs) => {
      if (obs.participant_2.id == id) {
        return obs;
      }
    });
  };

  const cutomUpdateInitiateCall = (conversation, data) => {
    console.log("converstion", conversation);
    const {
      participant_1_profile,
      participant_2_profile,
      participant_1_unread,
      participant_2_unread,
      participant_1_type,
      participant_2_type,
    } = conversation[0];
    let activeConversationObj = {};
    if (user?.id != participant_1_profile?.id) {
      activeConversationObj = {
        username:
          participant_1_profile?.firstName +
            " " +
            participant_1_profile?.lastName ||
          participant_1_profile?.firstname +
            " " +
            participant_1_profile?.lastname,
        unreadMessage: participant_2_unread,
        profileImage: participant_1_profile?.profileImage,
        participantId: participant_1_profile?.id,
        participant_type: participant_1_type,
        conversationId: conversation[0]?._id,
        isBlocked: conversation[0]?.isBlocked,
        blockedBy: conversation[0]?.isBlocked
          ? conversation[0]?.blockedBy
          : null,
        lastMessage: conversation[0]?.lastMessage,
        participant_1_unread: participant_2_unread,
        participant_2_unread: participant_2_unread,
        active: true,
        from: "conversation",
      };
      if (participant_2_unread > 0) {
        setTotalUnread((prevTotalUnread) => prevTotalUnread + 1);
      }
    } else if (user?.id != participant_2_profile?.id) {
      activeConversationObj = {
        username:
          participant_2_profile?.firstName +
            " " +
            participant_2_profile?.lastName ||
          participant_2_profile?.firstname +
            " " +
            participant_2_profile?.lastname,
        unreadMessage: participant_1_unread,
        profileImage: participant_2_profile?.profileImage,
        participantId: participant_2_profile?.id,
        participant_type: participant_2_type,
        conversationId: conversation[0]._id,
        isBlocked: conversation[0]?.isBlocked,
        blockedBy: conversation[0]?.isBlocked
          ? conversation[0]?.blockedBy
          : null,
        lastMessage: conversation[0]?.lastMessage,
        participant_1_unread: participant_1_unread,
        participant_2_unread: participant_2_unread,
        active: true,
        from: "conversation",
      };
      if (participant_1_unread > 0) {
        setTotalUnread((prevTotalUnread) => prevTotalUnread + 1);
      }
    }
    if (
      activeConversation?.participantId === activeConversationObj?.participantId
    ) {
      console.log("Do nothing");
    } else {
      dispatch(
        getChatMessages({
          id: conversation[0]._id,
          page: 1,
          previousChat: [],
        })
      );
    }

    dispatch(updateActiveConvercation(activeConversationObj));
    let updateConversationDetail = {
      conversationId: data.conversationId,
      lastMessage: data,
      participant_1_unread: data?.participant_1_unread,
      participant_2_unread: data?.participant_2_unread,
      isBlocked: activeConversation?.isBlocked,
      blockedBy: activeConversation?.blockedBy,
    };
    let currentMessage = {
      _id: data._id,
      message: data.message,
      attachment: data.attachment,
      type: data.type,
      read: data.read,
      senderId: userData,
      senderType: data.senderType,
      conversationId: data.conversationId,
      createdAt: data.createdAt,
      __v: data.__v,
      receiverId: activeConversation?.participantId,
      receiverRole: activeConversation?.participant_type,
      updatedConversation: updateConversationDetail,
    };
    dispatch(updateConversation(updateConversationDetail));
    dispatch(updateChatMesssages(currentMessage));
  };
  const initiateMessage = () => {
    if (participantId !== "") {
      setButtonLoader(true);
      dispatch(
        sendMessage(
          {
            participant: participantId,
            message: `The ${user.firstName} ${user.lastName} Admin connected with you `,
          },
          (data) => {
            let Objects = findUserIdInConversation(
              allConversations,
              participantId
            );
            const conversation = Objects.filter((obj) => obj !== undefined);

            if (conversation.length > 0) {
              cutomUpdateInitiateCall(conversation, data);
            } else {
              dispatch(
                updateAllConversation((response) => {
                  let Objects = findUserIdInConversation(
                    response,
                    participantId
                  );
                  const conversation = Objects.filter(
                    (obj) => obj !== undefined
                  );
                  if (conversation.length > 0) {
                    cutomUpdateInitiateCall(conversation, data);
                  }
                })
              );
            }
            dispatch(updateserSearchSupportChat());
            setInputValue("");
            setParticipantId("");
            setButtonLoader(false);
          },
          (data) => {
            setButtonLoader(false);
          }
        )
      );
    } else {
      toast.error("please select user");
    }
  };

  const handleToggleChatCmp = () => {
    setToggleChatCmp(!toggleChatCmp);
  };
  const handleSearch = useCallback(
    debounce(async (e) => {
      try {
        if (e.target.value !== "") {
          setIsDropDown(true);
          setDropDownLoader(true);
          await dispatch(
            userSearchSupportChat(
              {
                text: e.target.value,
              },
              () => {
                setDropDownLoader(false);
              }
            )
          );
        }
      } catch (error) {
        console.error("An error occurred:", error);
      }
    }, 3000),
    []
  );
  useEffect(() => {
    if (searchValue) {
      const filteredChats = allConversations?.filter((chat) => {
        const participant1FirstName =
          chat?.participant_1_profile?.firstName.toLowerCase();
        const participant2FirstName =
          chat?.participant_2_profile?.firstName.toLowerCase();
        const lowerCaseSearch = searchValue.toLowerCase();
        return (
          participant1FirstName?.includes(lowerCaseSearch) ||
          participant2FirstName?.includes(lowerCaseSearch)
        );
      });
      setSearchChats(filteredChats);
    } else {
      setSearchChats(allConversations);
    }
  }, [searchValue, allConversations]);

  useEffect(() => {
    setSearchChats(allConversations);
  }, [allConversations]);
  useEffect(() => {
    dispatch(getConversation());
  }, []);

  useEffect(() => {
    if (user) {
      pageRefresh(user?.id, user?.role);
      socket.on("getMessage", async (msg) => {
        dispatch(updateChatMesssages(msg));
        dispatch(updateConversation(msg.updatedConversation));
      });
    }
  }, []);

  return (
    <Container className="border rounded">
      <div className=" border-bottom d-flex justify-content-between align-items-center flex-column flex-sm-row">
        <h1 className="d-flex align-items-center">Inbox</h1>
        <div className=" custom_search p-4 d-flex align-items-start max-md:w-75">
          <div className="wrapper">
            <InputGroup className="w-100 mr-2 ">
              <div className="textfield">
                <Input
                  type="text"
                  className="textfield-input-field"
                  placeholder="Search Here..."
                  value={inputValue}
                  onChange={(e) => {
                    setInputValue(e.target.value);
                    handleSearch(e);
                  }}
                />
              </div>
              <div
                className="dropdown  d-flex align-items-center justify-content-center border "
                onClick={() => {
                  setIsDropDown(!isDropDown);
                }}
              >
                <div className=" w-100">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    class="ionicon"
                    viewBox="0 0 512 512"
                  >
                    <path
                      fill="none"
                      stroke="currentColor"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                      stroke-width="48"
                      d="M112 184l144 144 144-144"
                    />
                  </svg>
                </div>
              </div>
            </InputGroup>
            {isDropDown && (
              <div
                className={` ${
                  usersSupportChat?.length > 11 ? "custom_height" : ""
                }  overflow-auto dropDownList  absolute top-full left-0 mt-1 w-64 bg-white border border-gray-300 rounded-md shadow-lg`}
              >
                <ul className="py-1 list-unstyled pl-0 ">
                  {dropdownLoader ? (
                    <div className="d-flex w-100 justify-content-center pt-2">
                      <Spinner size={"lg"} />
                    </div>
                  ) : (
                    usersSupportChat != null &&
                    usersSupportChat?.length > 0 &&
                    usersSupportChat.map((user, index) => (
                      <li
                        className={`dropdown-list border  pl-2 list_items_initiate_chat ${
                          activeIndex === index ? "text-white bg-blue" : ""
                        } `}
                        innerText={user.userName}
                        onClick={(e) => {
                          setSelectedUser(user);
                          handleSelectChange(e);
                          setParticipantId(user.id);
                          setActiveIndex(index);
                          setIsDropDown(false);
                        }}
                      >
                        {user.userName}
                      </li>
                    ))
                  )}
                </ul>
              </div>
            )}
            <small className="text-xs text-primary">
              Search by Name, Email & User Id
            </small>
          </div>

          <Button
            color="primary"
            className="w-25 d-flex justify-content-center message_initaite_button"
            onClick={() => initiateMessage()}
            disabled={buttonLoader}
          >
            {buttonLoader ? <Spinner size="sm" /> : ">"}
          </Button>
        </div>
      </div>
      <Row>
        <Col xs="12" lg="12" xl="4" className="pr-1 border-right">
          <div
            className={` items-center justify-content-between mt-2 ${
              toggleChatCmp === true ? "d-none d-xl-flex" : "d-flex"
            }`}
          >
            <h4 className="mx-1">All Messages</h4>
            <FontAwesomeIcon
              className="cursor-pointer mt-1  pr-2"
              icon={searchActive ? faTimes : faSearch}
              onClick={() => toggleSearch()}
            />
          </div>
          {searchActive && (
            <div
              className={`relative w-max flex-nowrap  border rounded-lg mt-2 mb-2 mr-3 w-100 ${
                toggleChatCmp === true ? "d-none d-xl-block " : ""
              } `}
            >
              <FontAwesomeIcon
                className="ml-1 left-3 top-7 cursor-pointer"
                icon={faSearch}
              />
              <input
                type="text"
                className=" border-0 bg-gray-100 ml-1 w-75 mt-0 outline-none p-2 px-10 rounded-lg placeholder:text-slate-500"
                placeholder="Search"
                value={searchValue}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                }}
              />
            </div>
          )}
          <div
            className={`conversation-container border-0 chat-scrollbar pl-1 pr-2 mt-2 ${
              toggleChatCmp === true ? "d-none d-xl-block" : ""
            }`}
          >
            {conversationLoading ? (
              <ShimmerCategoryList
                title
                items={2}
                categoryStyle="STYLE_SEVEN"
              />
            ) : (
              searchchats.length > 0 &&
              searchchats?.map((conversation, index) => (
                <ConversationItem
                  conversation={conversation}
                  index={index}
                  setTotalUnread={setTotalUnread}
                  toggle={handleToggleChatCmp}
                />
              ))
            )}
          </div>
        </Col>
        <Col
          xs="12"
          lg="12"
          xl="8"
          className={`pl-0 pr-0 border ${
            activeConversation ? "block" : "hidden"
          } col-span-12 md:block md:col-span-6 lg:col-span-8 xl:col-span-9`}
        >
          {activeConversation?.active == true ? (
            <Col
              xs="12"
              lg="12"
              sm="12"
              className={`p-0  pr-0 ${
                toggleChatCmp === false ? "d-none  d-xl-block" : ""
              }`}
            >
              <ChatHeader
                toggle={handleToggleChatCmp}
                activeConversation={activeConversation}
              />

              <ChatBody
                chatMessage={chatMessages}
                activeConversation={activeConversation}
                searchActive={searchActive}
              />
              <ChatFooter activeConversation={activeConversation} />
            </Col>
          ) : null}
        </Col>
      </Row>
    </Container>
  );
};

export default ChatSupport;

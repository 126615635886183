import Repository from "./Repository";
const GET = "/ticket/all";
const GET_ASSIGNED_TICKET = '/ticket/assigned-tickets';
const GET_SINGLE = "/ticket";
const GET_SEARCH = "/ticket/search";
const ASSIGN_TICKET = "/ticket/assign";
const CHANGE_STATUS = "/ticket/status";
const COMMENT = "/ticket/comment-support";

export default {
  getTickets(page, limit, status) {
    return Repository.get(`${GET}?page=${page}&limit=${limit}&status=${status}`);
  },
  getAssignedTickets(page, limit) {
    return Repository.get(`${GET_ASSIGNED_TICKET}?page=${page}limit=${limit}`);
  },
  ticketsSearchById(id,page) {
    return Repository.get(`${GET_SINGLE}/${id}?page=${page}`);
  },
  getSingleTicket(id) {
    return Repository.get(`${GET_SINGLE}/${id}`);
  },
  assignTicket(payload) {
    return Repository.post(`${ASSIGN_TICKET}`, payload);
  },
  changeTicketStatus(id, payload) {
    return Repository.post(`${CHANGE_STATUS}/${id}`, payload);
  },
  supportAddComment(id, payload) {
    return Repository.post(`${COMMENT}/${id}`, payload);
  },
};

import Repository from "./Repository";
const LOGIN = "admin-auth/login";
const FOREGET_PASSWORD = "admin-auth/forget";
const UPDATE_EMAIL = "admin-auth/update-email";
const VERIFY_EMAIL = "auth/verify-email";
const VERIFY_PASSWORD = "admin-auth/reset-password";
export default {
  Login(payload) {
    return Repository.post(`${LOGIN}`, payload);
  },
  forgetPassword(email) {
    return Repository.post(`${FOREGET_PASSWORD}`, email);
  },
  updateEmail(email) {
    return Repository.put(`${UPDATE_EMAIL}`, email);
  },
  verifyEmail(payload) {
    return Repository.post(`${VERIFY_EMAIL}`, payload);
  },
  verifyPassword(payload) {
    return Repository.post(`${VERIFY_PASSWORD}`, payload);
  },
};

import Repository from "./Repository";
const GET_ALL_CERTIFICATION = "user/certificate";
const GET_ALL_COVID_CERTIFICATION = "user/certificate/covid";
const COVID_CERTIFICATION_SEARCH = "/user/certificate/covid-search";
const CERTIFICATE_STATUS = "user/certificate-status";

export default {
  getCertification(id) {
    return Repository.get(`${GET_ALL_CERTIFICATION}/${id}`);
  },
  getAllCovidCertification(page, limit) {
    return Repository.get(
      `${GET_ALL_COVID_CERTIFICATION}?page=${page}&limit=${limit}`
    );
  },
  searchCovidCertification(payload, page) {
    return Repository.post(
      `${COVID_CERTIFICATION_SEARCH}?page=${page}limit=10`,
      payload
    );
  },
  certificateStatus(id, status) {
    console.log(id, status);
    const requestBody = {
      status: status,
    };
    return Repository.post(`${CERTIFICATE_STATUS}/${id}`, requestBody);
  },
};

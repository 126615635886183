import ChatMessagesDisputes from "components/ChatMessagesDisputes";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Spinner,
} from "reactstrap";
import { getDisputeChat } from "store/actions/disputesAction";

const ViewOrderChatModal = ({ modal, toggle, order }) => {
  console.log({ order });
  const [page, setPage] = useState(1);
  const dispatch = useDispatch();
  const { disputeChat } = useSelector((state) => state.disputes);
  useEffect(() => {
    if (order) {
      let payload = {
        page: page,
        limit: 10,
        sellerID: order?.seller?.id,
        buyerID: order?.buyer?.id,
      };
      dispatch(getDisputeChat(payload));
    }
  }, [order, dispatch, page]);
  return (
    <Modal isOpen={modal} toggle={toggle} className="order__chat-modal">
      <ModalHeader toggle={toggle}>Conversation</ModalHeader>
      <ModalBody className="py-0">
        <ChatMessagesDisputes
          disputeChat={disputeChat}
          page={page}
          setPage={setPage}
        />
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={toggle}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewOrderChatModal;

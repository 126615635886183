import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  Media,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import OnlyHeader from "components/Headers/OnlyHeader.js";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { getAllNameRequests } from "store/actions/nameRequestAction";
import { updateNameRequest } from "store/actions/nameRequestAction";

const NameRequest = () => {
  const {
    nameRequests,
    updateNameRequestLoader,
    allNameRequestsLoader,
    totalPages,
  } = useSelector((state) => state.nameRequests);
  const dispatch = useDispatch();
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageModalOpen, setImageModalOpen] = useState(false);
  const [isConfirmationModalOpen, setConfirmationModalOpen] = useState(false);
  const [currentAction, setCurrentAction] = useState(null);
  const [currentRequest, setCurrentRequest] = useState(null);
  const itemsPerPage = 10;

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  const handleImageClick = (imageUrl) => {
    setSelectedImage(imageUrl);
    setImageModalOpen(true);
  };

  const handleActionClick = (request, action) => {
    setCurrentRequest(request);
    setCurrentAction(action);
    setConfirmationModalOpen(true);
  };

  const handleConfirmAction = () => {
    if (currentRequest && currentAction) {
      dispatch(
        updateNameRequest(currentRequest?._id, currentAction, () => {
          dispatch(getAllNameRequests(currentPage, itemsPerPage));
          setConfirmationModalOpen(false);
          setCurrentAction(null);
          setCurrentRequest(null);
        })
      );
    }
    setConfirmationModalOpen(false);
  };

  useEffect(() => {
    dispatch(getAllNameRequests(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Name Requests</h3>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Document</th>
                    <th scope="col">Actions</th>
                  </tr>
                </thead>
                {!allNameRequestsLoader ? (
                  <tbody>
                    {nameRequests && nameRequests.length > 0 ? (
                      nameRequests?.map((request, index) => (
                        <tr key={index}>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {request?.firstName}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {request?.lastName}
                                </span>
                              </Media>
                            </Media>
                          </td>
                          <td>
                            <img
                              src={request?.document}
                              style={{
                                width: "100px",
                                height: "80px",
                                cursor: "pointer",
                              }}
                              onClick={() =>
                                handleImageClick(request?.document)
                              }
                            />
                          </td>
                          <td>
                            <Button
                              size="sm"
                              color="primary"
                              disabled={updateNameRequestLoader}
                              onClick={() =>
                                handleActionClick(request, "approved")
                              }
                            >
                              Accept
                            </Button>
                            <Button
                              size="sm"
                              color="danger"
                              disabled={updateNameRequestLoader}
                              onClick={() =>
                                handleActionClick(request, "rejected")
                              }
                            >
                              Reject
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          {nameRequests && nameRequests.length === 0 ? (
                            <p className="mb-0 text-sm">No Request found.</p>
                          ) : (
                            <Spinner
                              size="lg"
                              style={{
                                display: "block",
                                margin: "0 auto",
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="6" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{
                            display: "block",
                            margin: "0 auto",
                          }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>

      {/* Image Preview Modal */}
      <Modal
        isOpen={isImageModalOpen}
        toggle={() => setImageModalOpen(!isImageModalOpen)}
      >
        <ModalHeader toggle={() => setImageModalOpen(!isImageModalOpen)}>
          Image Preview
        </ModalHeader>
        <ModalBody>
          <img src={selectedImage} alt="Preview" style={{ width: "100%" }} />
        </ModalBody>
      </Modal>

      {/* Confirmation Modal */}
      <Modal
        isOpen={isConfirmationModalOpen}
        toggle={() => setConfirmationModalOpen(!isConfirmationModalOpen)}
      >
        <ModalHeader
          toggle={() => setConfirmationModalOpen(!isConfirmationModalOpen)}
        >
          Confirm Action
        </ModalHeader>
        <ModalBody>
          {`Are you sure you want to ${
            currentAction === "approved" ? "approve" : "reject"
          } this request?`}
        </ModalBody>
        <ModalFooter>
          <Button
            color="primary"
            onClick={handleConfirmAction}
            disabled={updateNameRequestLoader}
          >
            {`Yes, ${currentAction === "approved" ? "approve" : "reject"}`}
          </Button>

          <Button
            color="secondary"
            onClick={() => setConfirmationModalOpen(false)}
          >
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default NameRequest;

import OnlyHeader from "components/Headers/OnlyHeader";
import { convertToHTML } from "draft-convert";
import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import Multiselect from "multiselect-react-dropdown";
import { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import Select from "react-select";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  Input,
  Label,
  Row,
  Spinner,
} from "reactstrap";
import { getSingleBlogs, updateBlog } from "store/actions/blogsAction";
import {
  getCategoriesAction,
  getSubCategoriesAction,
} from "store/actions/categoriesAction";
import ImagePicker from "components/ImagePicker";

const EditBlog = () => {
  const { allSubCategories, allCategories } = useSelector(
    (state) => state.category
  );
  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();
  console.log("id: ", id);
  const [selectedValue, setSelectedValues] = useState([]);
  const {
    mediaUrl,
    updateBlogLoader,
    singleBlog: { blog },
  } = useSelector((state) => state.blogs);

  const [blogData, setBlogData] = useState({
    title: "",
    content: "",
    category: "",
    file: "",
  });

  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [convertedContent, setConvertedContent] = useState(null);
  const handleEditorChange = (state) => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const handleBlogDataChange = (e) => {
    e.preventDefault();
    if (e.target.name === "featuredImgURL" || e.target.name === "getMediaURL") {
      setBlogData({
        ...blogData,
        [e.target.name]: e.target.files[0],
      });
    } else {
      setBlogData({
        ...blogData,
        [e.target.name]: e.target.value,
      });
    }
  };

  const handleCategoryChange = (selectedOption) => {
    console.log(selectedOption);
    setBlogData((prevData) => ({
      ...prevData,
      category: selectedOption.value,
    }));
    dispatch(getSubCategoriesAction(selectedOption.value));
  };

  const initialCategory = allCategories.find(
    (category) => category._id === blogData.category
  );
  const initialValue = initialCategory
    ? { value: initialCategory._id, label: initialCategory.name }
    : null;

  const onSelect = (selectedList) => {
    setSelectedValues(selectedList);
  };

  const onRemove = (selectedList) => {
    setSelectedValues(selectedList);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const minTitleLength = 40;

    if (
      !blogData.title.length ||
      blogData.title.length < minTitleLength ||
      !blogData.content.length ||
      !blogData.category.length ||
      !blogData.file
      // ||
      // selectedValue.length === 0
    ) {
      toast.error("Please fill all the required fields");
      return;
    }

    const temp = {
      ...blogData,
      content: draftToHtml(convertToRaw(editorState.getCurrentContent())),
    };

    const payload = {
      title: temp.title,
      description: temp.content,
      category: temp.category,
      file: temp.file,
      subCategory: selectedValue?.map((item) => item?._id),
    };

    dispatch(
      updateBlog(id, payload, () => {
        history.push("/admin/Blogs");
        toast.success("Blog Updated Successfully");
      })
    );
  };
  useEffect(() => {
    if (mediaUrl) {
      setBlogData({
        ...blogData,
        mediaFiles: [...blogData.mediaFiles, mediaUrl],
      });
    }
  }, [mediaUrl]);

  useEffect(() => {
    dispatch(getCategoriesAction());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getSingleBlogs(id));
  }, [id]);

  useEffect(() => {
    if (blog?.description) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(htmlToDraft(blog?.description))
        )
      );

      setBlogData({
        title: blog?.title,
        category: blog?.category?._id,
        content: blog?.description,
        file: blog?.image,
      });

      setSelectedValues(blog?.subCategory);
    }
  }, [blog]);
  const embedVideoCallBack = (iframeHtml) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = iframeHtml.trim();
    const iframeElement = tempDiv.querySelector("iframe");
    if (iframeElement) {
      const src = iframeElement.getAttribute("src");
      const cleanSrc = src.split("?")[0];
      return cleanSrc;
    } else {
      return null;
    }
  };
  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row>
          <Card>
            <CardBody>
              <Row>
                <Col md="4" className="my-2">
                  <Label className="mb-0 my-auto ">Title</Label>
                  <Input
                    name="title"
                    className="form-controll_title"
                    value={blogData.title}
                    required
                    onChange={(e) => {
                      handleBlogDataChange(e);
                    }}
                  />
                  {blogData.title.length < 40 && (
                    <h6
                      className="text-muted
                    "
                    >
                      Title must be at least 40 characters long.
                    </h6>
                  )}
                </Col>
                <Col md="4" className="my-2">
                  <Label className="mb-0 my-auto">Category</Label>
                  <Select
                    name="category"
                    value={initialValue}
                    options={allCategories.map((category) => ({
                      value: category._id,
                      label: category.name,
                    }))}
                    onChange={handleCategoryChange}
                    required
                  />
                </Col>
                <Col md="4" className="my-2">
                  <Label className="mb-0 my-auto ">Sub Categories</Label>
                  <Multiselect
                    options={allSubCategories}
                    selectedValues={selectedValue}
                    onSelect={onSelect}
                    onRemove={onRemove}
                    displayValue="name"
                    required
                  />
                </Col>
                <div className="mx-3 mt-2 mb-2">
                  <ImagePicker
                    image={blogData.file || blogData.image}
                    className="red-icon"
                    onImageChange={(newFile) => {
                      setBlogData((prevData) => ({
                        ...prevData,
                        file: newFile,
                      }));
                    }}
                  />
                </div>
                <Col md={12} className="mt-3">
                  {/* <Editor
                    editorState={editorState}
                    editorStyle={{
                      border: "2px solid #C0C0C0",
                    }}
                    toolbar={{
                      embedded: {
                        embedCallback: embedVideoCallBack,
                      },

                      fontFamily: {
                        options: [
                          "Arial",
                          "Georgia",
                          "Impact",
                          "Tahoma",
                          "Times New Roman",
                          "Verdana",
                          "Barlow",
                        ],
                      },
                    }}
                    onEditorStateChange={handleEditorChange}
                  /> */}
                  <Editor
                    editorState={editorState}
                    editorStyle={{
                      border: "2px solid #C0C0C0",
                    }}
                    toolbar={{
                      embedded: {
                        embedCallback: embedVideoCallBack,
                        defaultSize: {
                          height: "auto",
                          width: "auto",
                        },
                      },
                      fontFamily: {
                        options: [
                          "Arial",
                          "Georgia",
                          "Impact",
                          "Tahoma",
                          "Times New Roman",
                          "Verdana",
                          "Barlow",
                        ],
                      },
                    }}
                    onEditorStateChange={handleEditorChange}
                  />
                </Col>
              </Row>
              <div className="d-flex justify-content-end align-items-center">
                <Button
                  type="button"
                  color="primary"
                  className="mt-3"
                  disabled={updateBlogLoader}
                  onClick={handleSubmit}
                >
                  {updateBlogLoader ? (
                    <Spinner
                      className="d-flex mx-auto align-items-center justify-content-center overflow-hidden"
                      size="sm"
                      color="white"
                    />
                  ) : (
                    "SAVE CHANGES"
                  )}
                </Button>
              </div>
            </CardBody>
          </Card>
        </Row>
      </Container>
    </>
  );
};
export default EditBlog;

const initState = {
  resolutionPersons: [],
  totalPages: null,
  addResolutionLoader: false,
  deleteResolutionLoader: false,
  updateResolutionLoader: false,
  getResolutionLoader: false,
};
const resolutionReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_RESOLUTION_TEAMS":
      return {
        ...state,
        resolutionPersons: payload.resolutionPersons,
        totalPages: payload.totalPages,
      };
    case "GET_RESOLUTION_BY_NAME":
      return {
        ...state,
        resolutionPersons: payload.resolutionPersons,
        totalPages: payload.totalPages,
      };
    case "GET_RESOLUTION_LOADER":
      return {
        ...state,
        getResolutionLoader: payload,
      };
    case "ADD_RESOLUTION_LOADER":
      return {
        ...state,
        addResolutionLoader: payload,
      };
    case "DELETE_RESOLUTION_LOADER":
      return {
        ...state,
        deleteResolutionLoader: payload,
      };
    case "UPDATE_RESOLUTION_LOADER":
      return {
        ...state,
        updateResolutionLoader: payload,
      };
    case "RESOLUTION_TEAM_DELETED":
      return {
        ...state,
        resolutionPersons: state.resolutionPersons?.filter(
          (team) => team.id !== payload
        ),
      };

    // case "RESOLUTION_TEAM_ADDED":
    //   return {
    //     ...state,
    //     resolutionTeams: [...state.resolutionTeams, payload],
    //   };

    default:
      return {
        ...state,
      };
  }
};
export default resolutionReducer;

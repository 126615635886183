import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Media,
  Row,
  Spinner,
  Table,
} from "reactstrap";
// core components
import OnlyHeader from "components/Headers/OnlyHeader.js";

import ConfirmationModal from "components/Modals/ConfirmationModal";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { deleteBlog, getAllBlogs } from "store/actions/blogsAction";
import { BlogSearchByTitleAction } from "store/actions/blogsAction";

const Blogs = () => {
  const dispatch = useDispatch();
  const { allBlogs, totalPages, getBlogsLoader } = useSelector(
    (state) => state.blogs
  );
  console.log("allBlogs: ", allBlogs);
  const [currentPage, setCurrentPage] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);
  const [message, setMessage] = useState("");
  const [userId, setUserID] = useState("");
  const [BlogSearchTitle, setBlogSearchTitle] = useState("");
  console.log("BlogSearchTitle: ", BlogSearchTitle);

  const toggleConfirmationModal = () => {
    setConfirmationModal(!confirmationModal);
  };

  const handleOnSumbit = () => {
    setCurrentPage(1);
    dispatch(BlogSearchByTitleAction(BlogSearchTitle, currentPage));
  };

  const handleDeleteModal = (id) => {
    console.log("<<<<>>>id: ", id);
    setUserID(id);
    setMessage("Are you sure you want to delete this blog?");
    toggleConfirmationModal();
  };

  const handleConfirmationOk = () => {
    console.log("<<<<><>userId: ", userId);
    console.log(`Activating user ID: ${userId}`);
    dispatch(
      deleteBlog(userId, () => {
        dispatch(getAllBlogs());
      })
    );
    toggleConfirmationModal();
  };

  const handleConfirmationCancel = () => {
    toggleConfirmationModal();
  };

  const filterOutImages = (html) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(html, "text/html");

    const imgElements = doc.querySelectorAll("img");
    imgElements.forEach((img) => {
      img.parentNode.removeChild(img);
    });

    return doc.body.innerHTML;
  };

  const itemsPerPage = 10;
  const handlePageChange = ({ selected }) => {
    console.log({ selected });
    const newPage = selected + 1;
    console.log({ newPage, totalPages });
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // format the date and time

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  useEffect(() => {
    dispatch(getAllBlogs(currentPage, itemsPerPage));
  }, [dispatch, itemsPerPage, currentPage]);

  console.log({ allBlogs });

  const handleClearSearch = () => {
    setBlogSearchTitle("");
    dispatch(getAllBlogs(currentPage, itemsPerPage));
  };

  return (
    <>
      {/* <DeletePostModel
        isOpen={isModalOpen}
        data={blogData}
        toggle={() => setIsModalOpen(!isModalOpen)}
      /> */}
      <OnlyHeader />

      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Blogs</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="ResolutionSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={BlogSearchTitle}
                          onChange={(e) => setBlogSearchTitle(e.target.value)}
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Title
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        disabled={getBlogsLoader}
                        onClick={handleOnSumbit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        disabled={getBlogsLoader}
                        onClick={handleClearSearch}
                        size="sm"
                      >
                        Clear Search
                      </Button>
                      <Link to={"/admin/addBlog"}>
                        <Button
                          color="primary"
                          className="p-2 mx-2 my-2 my-lg-0"
                          size="sm"
                        >
                          Add Post
                        </Button>
                      </Link>
                    </div>
                  </Col>
                </Row>
                {/* <h3>Blogs</h3>
                <Row className="align-items-center">
                  <Col lg={5} xs={8} className="pr-lg-0">
                    <FormGroup className="mb-lg-0">
                      <Input
                        id="BlogSearch"
                        name="Search"
                        placeholder="Search...."
                        type="text"
                        value={BlogSearchTitle}
                        onChange={(e) => setBlogSearchTitle(e.target.value)}
                      />
                    </FormGroup>
                  </Col>
                  <Col
                    lg={3}
                    xs={4}
                    className="px-lg-0 text-lg-center text-right"
                  >
                    <Button
                      color="primary"
                      className="mb-lg-0 mb-4 px-3"
                      onClick={handleOnSumbit}
                    >
                      Search
                    </Button>
                  </Col>
                  <Col lg={4} xs={12} className="pl-lg-0">
                    <Link to={"/admin/addBlog"}>
                      <Button color="primary">Add Post</Button>
                    </Link>
                  </Col>
                </Row> */}
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Title</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Actions</th>
                    <th scope="col" />
                  </tr>
                </thead>
                {!getBlogsLoader ? (
                  <tbody>
                    {allBlogs && allBlogs.length > 0 ? (
                      allBlogs?.map((blog, index) => (
                        <tr key={index}>
                          <th scope="row">
                            <Media className="align-items-center">
                              <Media>
                                <span className="mb-0 text-sm">
                                  {blog?.title}
                                </span>
                              </Media>
                            </Media>
                          </th>

                          <td>{formatTimestamp(blog?.createdAt)}</td>
                          <td>{formatTimestamp(blog?.updatedAt)}</td>
                          {/* <td>{formatTimestamp(blog?.updatedAt, blog?.updatedAt !== blog?.createdAt)}</td> */}

                          <td>
                            <div className="d-flex align-items-center">
                              <Link to={`/admin/EditBlog/${blog?._id}`}>
                                <Button color="primary" size="sm">
                                  <i className="fas fa-pencil-alt" />
                                </Button>
                              </Link>
                              <Button
                                color="danger"
                                className="mx-2"
                                size="sm"
                                onClick={() => handleDeleteModal(blog._id)}
                              >
                                <i className="fa fa-trash"></i>
                              </Button>
                            </div>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          {allBlogs && allBlogs.length === 0 ? (
                            <p className="mb-0 text-sm">No Blogs Found.</p>
                          ) : (
                            <Spinner
                              size="lg"
                              style={{
                                display: "block",
                                margin: "0 auto",
                              }}
                            />
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}

                {/* <tbody>
                  <tr>
                    <td colSpan="3">
                      <div className="d-flex justify-content-center align-items-center">
                        <Spinner size="lg" />
                      </div>
                    </td>
                  </tr>
                </tbody> */}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
        <ConfirmationModal
          modal={confirmationModal}
          toggle={toggleConfirmationModal}
          userId={userId}
          message={message}
          onConfirm={handleConfirmationOk}
          onCancel={handleConfirmationCancel}
        />
      </Container>
    </>
  );
};

export default Blogs;

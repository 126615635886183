import { useEffect, useState } from "react";
import { CircleFlag } from "react-circle-flags";
import {
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  Spinner,
  Table
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import { demographicInfoAction } from "store/actions/dashboardActions";

const ActiveUsersModal = ({ modal, toggle }) => {
  const dispatch = useDispatch();
  const {
    getDemographicInfoLoader,
    demographicInfo: { paginatedResults },
  } = useSelector((state) => state.dashboard);
  
  console.log('paginatedResults: ', paginatedResults);
  const [visibleRecords, setVisibleRecords] = useState(3);
  const loadMore = () => {
    setVisibleRecords(visibleRecords + 3);
  };
  const loadLess = () => {
    setVisibleRecords(Math.max(visibleRecords - 3, 3));
  };

  useEffect(() => {
    dispatch(demographicInfoAction());
  }, []);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Active Users</ModalHeader>
        <ModalBody>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Country Code</th>
                <th scope="col">Country Name</th>
                <th scope="col">Status</th>
              </tr>
            </thead>

            <tbody>
              {!getDemographicInfoLoader ? (
                <>
                  {paginatedResults &&
                    paginatedResults
                      ?.slice(0, visibleRecords)
                      ?.map((user, index) => (
                        <tr key={index}>
                          <td>
                            <span className="mx-2">
                              <CircleFlag
                                countryCode={user?._id
                                  ?.slice(0, 2)
                                  .toLowerCase()}
                                height="35"
                              />
                            </span>
                          </td>
                          <td>{user?._id}</td>
                          <td>{user?.count}</td>
                        </tr>
                      ))}
                </>
              ) : (
                <tr>
                  <td colSpan="3" style={{ textAlign: "center" }}>
                    <Spinner
                      size="lg"
                      style={{ display: "block", margin: "0 auto" }}
                    />
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
          <div className="d-flex justify-content-center align-items-center">
            {visibleRecords < paginatedResults?.length && (
              <>
                <Button color="primary" size="sm" onClick={loadMore}>
                  Load More
                </Button>
                {visibleRecords > 3 && (
                  <Button color="secondary" size="sm" onClick={loadLess}>
                    Load Less
                  </Button>
                )}
              </>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ActiveUsersModal;

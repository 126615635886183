import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Label,
  Col,
  Row,
  Badge,
} from "reactstrap";

const ViewDisputeActivity = ({ modal, toggle, singleDispute }) => {
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Dispute Details</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="8" className="m-auto">
              <Label className="font-weight-bold">Buyer Name</Label>
              <FormGroup>
                <Input value={singleDispute?.buyerName} readOnly></Input>
              </FormGroup>
            </Col>
            <Col md="8" className="m-auto">
              <Label className="font-weight-bold">Seller Name</Label>
              <FormGroup>
                <Input value={singleDispute?.sellerName} readOnly></Input>
              </FormGroup>
            </Col>

            <Col md="8" className="m-auto">
              <Label className="font-weight-bold">Opened By</Label>
              <FormGroup>
                <Input value={singleDispute?.openedBy} readOnly></Input>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" onClick={toggle}>
            X
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default ViewDisputeActivity;

const initState = {
  allCountries: [],
  supportPersons: [],
  searchData: [],
  addSupportLoader: false,
  deleteSupportLoader: false,
  updateSupportLoader: false,
  getSupportLoader: false,
};
const supportReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "ALL_COUNTRIES":
      return {
        ...state,
        allCountries: payload,
      };
    case "GET_SUPPORT_PERSONS":
      return {
        ...state,
        supportPersons: payload.supportPersons,
        totalPages: payload.totalPages,
        totalSupportPersons: payload.totalSupportPersons,
      };
    case "GET_SUPPORT_BY_NAME":
      return {
        ...state,
        supportPersons: payload.supportPersons,
        totalPages: payload.totalPages,
      };
    case "GET_SUPPORT_LOADER":
      return {
        ...state,
        getSupportLoader: payload,
      };
    case "ADD_PERSON_LOADER":
      return {
        ...state,
        addSupportLoader: payload,
      };
    case "DELETE_SUPPORT_LOADER":
      return {
        ...state,
        deleteSupportLoader: payload,
      };
    case "UPDATE_SUPPORT_LOADER":
      return {
        ...state,
        updateSupportLoader: payload,
      };
    case "SEARCH_SUPPORT_PERSONS": {
      return {
        ...state,
        searchData: payload,
      };
    }
    case "SUPPORT_PERSON_DELETED":
      return {
        ...state,
        supportPersons: state.supportPersons?.filter(
          (person) => person.id !== payload
        ),
      };

    default:
      return {
        ...state,
      };
  }
};
export default supportReducer;

import {
  Badge,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
} from "reactstrap";

const ViewOrderActivity = ({ modal, toggle, singleOrder }) => {
  console.log("singleOrder: ", singleOrder);
  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>
            Order Details <Badge size="lg">{singleOrder?.status}</Badge>
          </h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="8" className="m-auto">
              <Label className="font-weight-bold">Buyer Name</Label>
              <FormGroup>
                <Input
                  value={
                    singleOrder?.buyer?.firstName +
                    " " +
                    singleOrder?.buyer?.lastName
                  }
                  readOnly
                ></Input>
              </FormGroup>
            </Col>
            <Col md="8" className="m-auto">
              <Label className="font-weight-bold">Service</Label>
              <FormGroup>
                <Input
                  value={
                    singleOrder?.service
                      ? singleOrder?.service?.title
                      : singleOrder?.job?.title
                  }
                  readOnly
                  type="textarea"
                ></Input>
              </FormGroup>
            </Col>
            <Col md="8" className="m-auto">
              <Label className="font-weight-bold">Amount</Label>
              <FormGroup>
                <Input value={singleOrder?.totalPrice} readOnly></Input>
              </FormGroup>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default ViewOrderActivity;

import { revenueData } from "Contants";
import { useState } from "react";
import { CircleFlag } from "react-circle-flags";
import { useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalHeader, Table } from "reactstrap";

const RevenueGeneratedModal = ({ modal, toggle }) => {
  const { countryRevenue } = useSelector((state) => state.dashboard);
  const [visibleRecords, setVisibleRecords] = useState(3);

  const loadMore = () => {
    setVisibleRecords(visibleRecords + 3);
  };

  const loadLess = () => {
    setVisibleRecords(Math.max(visibleRecords - 3, 3));
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Revenue Generated</ModalHeader>
        <ModalBody>
          <Table className="align-items-center table-flush" responsive>
            <thead className="thead-light">
              <tr>
                <th scope="col">Country Code</th>
                <th scope="col">Country Name</th>
                <th scope="col">Revenue</th>
              </tr>
            </thead>
            <tbody>
              <>
                {countryRevenue?.slice(0, visibleRecords).map((user, index) => {
                  return (
                    <tr key={index}>
                      <td>
                        <span className="mx-2">
                          <CircleFlag
                            countryCode={user?.countryName?.toLowerCase()}
                            height="35"
                          />
                        </span>
                      </td>
                      <td>{user?.countryName}</td>
                      <td>{user?.revenue?.toFixed(2)}</td>
                    </tr>
                  );
                })}
              </>
            </tbody>
          </Table>
          <div className="d-flex justify-content-center align-items-center">
            {countryRevenue.length > 0 ? (
              <>
                {visibleRecords < countryRevenue.length && (
                  <Button color="primary" size="sm" onClick={loadMore}>
                    Load More
                  </Button>
                )}
                {visibleRecords > 3 && (
                  <Button color="secondary" size="sm" onClick={loadLess}>
                    Load Less
                  </Button>
                )}
              </>
            ) : (
              <p className="text-center mb-0 text-sm">No Data Found</p>
            )}
          </div>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default RevenueGeneratedModal;

import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Col,
  Form,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { getCategoriesAction } from "store/actions/categoriesAction";
import { addNewCategory } from "store/actions/categoriesAction";

const CreateCategoryModal = ({ modal, toggle, currentPage, itemsPerPage }) => {
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const { addCategoryLoader } = useSelector((state) => state.category);
  const [categoryName, setCategoryName] = useState("");
  const [categoryImage, setCategoryImage] = useState(null);
  const [imagePreview, setImagePreview] = useState(null);
  const [taxCodes, setTaxCodes] = useState([""]);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!categoryName.trim()) {
      alert("Add category name");
      return;
    }
    const finalTaxCodes = taxCodes.filter((code) => code.trim());
    if (finalTaxCodes.length === 0) {
      alert("Please enter at least one  tax code");
      return;
    }

    dispatch(
      addNewCategory(
        {
          name: categoryName,
          image: categoryImage,
          taxCodes: finalTaxCodes,
        },
        () => {
          toggle();
          dispatch(getCategoriesAction(currentPage, itemsPerPage));
          setCategoryName("");
          setImagePreview(null);
          setTaxCodes([""]);
        }
      )
    );
  };

  const handleNameChange = (e) => {
    setCategoryName(e.target.value);
  };

  const handleImageChange = (e) => {
    const imageFile = e.target.files[0];
    setCategoryImage(imageFile);

    const reader = new FileReader();
    reader.onload = () => {
      setImagePreview(reader.result);
    };
    reader.readAsDataURL(imageFile);
  };
  const handleDeleteImage = () => {
    setCategoryImage(null);
    setImagePreview(null);
  };
  const handleIconClick = () => {
    fileInputRef.current.click();
  };

  const addTaxField = () => {
    setTaxCodes((prev) => [...prev, ""]);
  };
  const removeField = (index) => {
    setTaxCodes((prev) => prev.filter((_, i) => i !== index));
  };
  const handleTaxCodeChange = (index, newValue) => {
    setTaxCodes((prev) => {
      const updatedTaxCodes = [...prev];
      updatedTaxCodes[index] = newValue;
      return updatedTaxCodes;
    });
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>
          <h4>Add Category</h4>
        </ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <Row className="mb-3">
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Category</Label>
                <FormGroup>
                  <Input
                    placeholder="Enter Category"
                    value={categoryName}
                    onChange={handleNameChange}
                  />
                </FormGroup>
              </Col>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Tax Codes</Label>
                {taxCodes?.map((item, index) => {
                  return (
                    <>
                      <FormGroup className="mb-2">
                        <div className="d-flex justify-content-around align-items-center">
                          <Input
                            value={item}
                            className="w-100"
                            type="text"
                            placeholder="Tax Code"
                            onChange={(e) =>
                              handleTaxCodeChange(index, e.target.value)
                            }
                          ></Input>
                        </div>
                      </FormGroup>
                      {index === taxCodes.length - 1 && (
                        <Button
                          color="primary"
                          onClick={addTaxField}
                          className="ml-2 mt-1 mb-3"
                        >
                          +
                        </Button>
                      )}
                      {index !== 0 && (
                        <Button
                          type="button"
                          color="danger"
                          className="ml-2 mt-1 mb-3"
                          onClick={() => removeField(index)}
                        >
                          -
                        </Button>
                      )}
                    </>
                  );
                })}
              </Col>
              <Col md="8" className="m-auto">
                <Label className="font-weight-bold">Category Image</Label>
                {imagePreview ? (
                  <div className="position-relative">
                    <button
                      color="tranparent"
                      className="my-5 position-absolute border-0 bg-transparent"
                      style={{ top: "-50px", right: "143px" }}
                      onClick={handleDeleteImage}
                    >
                      <i className="fas fa-times text-danger"></i>
                    </button>
                    <img
                      src={imagePreview}
                      alt="Category Preview"
                      style={{ maxWidth: "50%" }}
                    />
                  </div>
                ) : (
                  <FormGroup>
                    <svg
                      className="ml-1 shadow-lg"
                      xmlns="http://www.w3.org/2000/svg"
                      height="60px"
                      viewBox="0 0 24 24"
                      width="60px"
                      fill="#008080"
                      onClick={handleIconClick}
                      style={{ cursor: "pointer" }}
                    >
                      <path d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 8c0 .55.45 1 1 1s1-.45 1-1V6h2c.55 0 1-.45 1-1s-.45-1-1-1H5V2c0-.55-.45-1-1-1s-1 .45-1 1v2H1c-.55 0-1 .45-1 1s.45 1 1 1h2v2z" />
                      <circle cx="13" cy="14" r="3" />
                      <path d="M21 6h-3.17l-1.24-1.35c-.37-.41-.91-.65-1.47-.65h-6.4c.17.3.28.63.28 1 0 1.1-.9 2-2 2H6v1c0 1.1-.9 2-2 2-.37 0-.7-.11-1-.28V20c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2V8c0-1.1-.9-2-2-2zm-8 13c-2.76 0-5-2.24-5-5s2.24-5 5-5 5 2.24 5 5-2.24 5-5 5z" />
                    </svg>
                    <input
                      ref={fileInputRef}
                      type="file"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleImageChange}
                      style={{ display: "none" }}
                    />
                  </FormGroup>
                )}
              </Col>
            </Row>
            <div className="d-flex justify-content-end align-items-center">
              <Button
                color="primary"
                type="submit"
                disabled={addCategoryLoader}
              >
                {addCategoryLoader ? <Spinner size="sm" /> : "Add"}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CreateCategoryModal;

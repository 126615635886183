const initState = {
  allBlogs: [],
  singleBlog: {},
  addBlogLoader: false,
  deleteBlogLoader: false,
  updateBlogLoader: false,
  getBlogsLoader: false,
};
const blogsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_BLOGS_LOADER":
      return {
        ...state,
        getBlogsLoader: payload,
      };
    case "GET_BLOGS":
      return {
        ...state,
        allBlogs: payload.blogs,
        totalPages: payload.totalPages,
        totalBlogs: payload.totalBlogs,
      };
    case "GET_BLOG_SEARCH_BY_Title":
      return {
        ...state,
        allBlogs: payload.blog,
        totalPages: payload?.totalPages,
      };
    case "GET_SINGLE_BLOG": {
      return {
        ...state,
        singleBlog: payload,
      };
    }
    case "ADD_BLOG_LOADER":
      return {
        ...state,
        addBlogLoader: payload,
      };
    case "DELETE_BLOG_LOADER":
      return {
        ...state,
        deleteBlogLoader: payload,
      };
    case "UPDATE_BLOG_LOADER":
      return {
        ...state,
        updateBlogLoader: payload,
      };
    case "BLOG_DELETED":
      return {
        ...state,
        allBlogs: state.allBlogs?.filter((blog) => blog.id !== payload),
      };

    default:
      return {
        ...state,
      };
  }
};
export default blogsReducer;

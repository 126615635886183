import { Image } from "react-bootstrap";
import Img from "../../assets/img/brand/favicon.png";
import React, { useEffect } from "react";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import dayjs from "dayjs";
import pdfIcon from "../../assets/img/pdfIcon.png";
import zipIcon from "../../assets/img/zipIcon.png";
import txtIcon from "../../assets/img/txtIcon.png";
import wordIcon from "../../assets/img/wordIcon.png";

import Attachment from "../../assets/img/Attachment.png";
import { useSelector } from "react-redux";

const ChatBox = ({ message, index, activeConversation }) => {
  const { scrolling } = useSelector((state) => state.chats);
  const getIconSrc = (fileType) => {
    console.log(fileType, "fileType");
    switch (fileType) {
      case "pdf":
        return pdfIcon;
      case "x-zip-compressed":
      case "zip":
        return zipIcon;
      case "plain":
        return txtIcon;
      case "vnd.openxmlformats-officedocument.wordprocessingml.document":
      case "doc":
      case "docx":
        return wordIcon;
      default:
        return Attachment; // Default icon for other types
    }
  };
  useEffect(() => {
    if (scrolling == true) {
      const element = document.getElementById(message?.createdAt);
      if (element) {
        element.scrollIntoView({
          behavior: "smooth",
          block: "end",
          inline: "nearest",
        });
      }
    }
  }, [message]);
  return (
    <div
      id={message?.createdAt}
      className={`row mt-4 ${
        message?.isSent ? "justify-content-end" : "justify-content-start"
      }`}
    >
      <div className={`aligned-class `}>
        <div
          className={`d-flex ${
            message?.isSent ? "pr-3 justify-content-end" : "pl-3"
          }`}
        >
          {message?.senderId?.profileImage ? (
            <img
              alt="Profile"
              src={message?.senderId?.profileImage}
              className={`rounded-circle profile-image ${
                message.isSent ? "ml-3 order-2" : "mr-3 order-1"
              }`}
              height={50}
              width={50}
            />
          ) : (
            <img
              alt="dumy"
              src={Img}
              className={`rounded-circle profile-image  ${
                message?.isSent ? "ml-3 order-2" : "mr-3 order-1"
              }`}
            />
          )}
          {message?.messageType == "video" ? (
            <div
              className={`video-message ${
                message.isSent ? " ml-2 order-1" : "mr-2 order-2"
              }`}
            >
              <h4
                className={`message-sender ${
                  message.isSent ? "text-right" : "text-left"
                }`}
              >
                {message.senderId.firstName + " " + message.senderId.lastName}
              </h4>
              <div className="video-container">
                <img alt="zoom logo" src={Img} height={120} width={120} />
              </div>
              <p
                className={`message-content ${
                  message.isSent
                    ? "text-center pl-0 md-pl-10"
                    : "text-center pr-0 md-pr-10"
                }`}
              >
                {message.message.split("https://").map((part, index) => {
                  if (index === 0) {
                    return <span key={index}>{part}</span>;
                  } else {
                    const url = "https://" + part.split(" ")[0];
                    const text = part.slice(part.indexOf(" ") + 1);
                    return (
                      <React.Fragment key={index}>
                        <br />
                        <a
                          href={url}
                          target="_blank"
                          rel="noopener noreferrer"
                          className="link"
                        >
                          {url}
                        </a>
                      </React.Fragment>
                    );
                  }
                })}
              </p>
              <div className="time-container">
                <small>{dayjs(message.createdAt).format("HH:mm")}</small>
              </div>
            </div>
          ) : (
            <div
              className={`text-message ${
                message?.isSent ? "  ml-2 order-1" : "mr-2 order-2"
              }`}
            >
              <h4
                className={`message-sender ${
                  message?.isSent ? "text-right" : "text-left"
                }`}
              >
                {message?.senderId?.firstName +
                  " " +
                  message?.senderId?.lastName}
              </h4>
              <p
                className={`message-content ${
                  message?.isSent ? "text-left pl-0" : "text-left pr-0"
                }`}
              >
                {message?.message}
              </p>
              {message?.attachment && (
                <>
                  {message?.type == "jpg" ||
                  message?.type == "jpeg" ||
                  message?.type == "png" ||
                  message?.type == "gif" ||
                  message?.type == "svg" ||
                  message?.type == "tiff" ? (
                    <div className="attachment-container">
                      <Image
                        alt="dumy"
                        src={message?.attachment}
                        className="image_attachment"
                      />
                    </div>
                  ) : message?.type == "mp4" ||
                    message?.type == "webm" ||
                    message?.type == "3gpp" ? (
                    <div className="video_container">
                      <video controls width="100%">
                        <source src={message?.attachment} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  ) : message?.type == "pdf" ||
                    message?.type == "x-zip-compressed" ||
                    message?.type == "plain" ||
                    message?.type == "docx" ||
                    message?.type ==
                      "vnd.openxmlformats-officedocument.wordprocessingml.document" ? (
                    <>
                      <a
                        className="cursor-pointer attachment_w"
                        href={message?.attachment}
                        target="_blank"
                      >
                        <div className="d-flex flex-column align-items-center align-center">
                          <Image
                            alt="FooterImage"
                            className="message_file_icon mt-1 mr-2"
                            src={getIconSrc(message.type)}
                          />
                          <p
                            className="ml-4 text-lg mt-2"
                            title={message?.fileName}
                          >
                            {message.fileName
                              ? `${message.fileName
                                  ?.split(".")[0]
                                  ?.slice(0, 20)}.${
                                  message.fileName?.split(".")[1]
                                }`
                              : "Attachment"}
                          </p>
                        </div>
                      </a>
                    </>
                  ) : (
                    <>
                      <a
                        className="attachment-link"
                        href={message?.attachment}
                        target="_blank"
                      >
                        <div className="attachment">
                          <img
                            alt="FooterImage"
                            className="footer-image"
                            src={Attachment}
                          />
                          <span className="attachment-text">attachment</span>
                        </div>
                      </a>
                    </>
                  )}
                </>
              )}
              <div className="time-container">
                <small>{dayjs(message?.createdAt).format("HH:mm")}</small>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default ChatBox;

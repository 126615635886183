import Repository from "./Repository";
const GET_ALL_REPORTS = "global/report";
const DELETE_REPORT = "global/report/reject";
const GET_SINGLE_REPORT = "/global/report";
const GET_SEARCH_BY_ID = "global/report/search";

export default {
  getReports(page, limit) {
    return Repository.get(`${GET_ALL_REPORTS}?page=${page}&limit=${limit}`);
  },
  deleteReport(id) {
    return Repository.delete(`${DELETE_REPORT}/${id}`);
  },
  reportSearchByName(name) {
    const requestBody = {
      reportedBy: name,
    };
    return Repository.post(`${GET_SEARCH_BY_ID}`, requestBody);
  },
  getSingleReport(id) {
    return Repository.get(`${GET_SINGLE_REPORT}/${id}`);
  },
};

import { formatDate } from "Contants";
import React, { useEffect, useState } from "react";
import { Badge, Button } from "reactstrap";
const ChatOfferCard = ({ message }) => {
  // console.log({ message });
  const [buttonComponentIdx, setButtonComponentIdx] = useState(0);
  const [days, setDays] = useState("");
  // let componentMapping = [
  //   () => (
  //     // <button className="bg-primary col-start-2 rounded-xl py-3 px-8 text-white font-semibold">
  //     //   Offer Sent
  //     // </button>
  //     <Button color="primary" className="mx-2">
  //       Offer Sent
  //     </Button>
  //   ),
  //   () => (
  //     <>
  //       {/* <button className="bg-primary text-sm md:text-xl min-w-fit w-full rounded-xl py-3 text-white font-light md:font-semibold">
  //         Accept Offer
  //       </button>
  //       <button className="bg-primary text-sm md:text-xl min-w-fit w-full rounded-xl py-3 text-white font-light  md:font-semibold">
  //         Reject Offer
  //       </button> */}
  //       <Button color="primary" className="mx-2">
  //         Accept Offer
  //       </Button>
  //       <Button color="primary"> Reject Offer</Button>
  //     </>
  //   ),
  //   () => (
  //     <Button color="primary">Offer Rejected</Button>
  //     // <button className="bg-primary col-start-2 rounded-3xl flex items-center p-2 text-white font-semibold">
  //     //   <span className="bg-green-500 mr-4 inline-flex items-center justify-center w-7 h-7 rounded-full text-white">
  //     //     {/* <FontAwesomeIcon icon={faCheck} /> */}
  //     //   </span>
  //     //   <span className="pr-4">Offer Accepted</span>
  //     // </button>
  //   ),
  //   () => (
  //     // <button className="bg-primary col-start-2 rounded-3xl flex items-center p-2 text-white font-semibold">
  //     //   <span className="bg-red-500 mr-4 inline-flex items-center justify-center w-7 h-7 rounded-full text-white">
  //     //     {/* <FontAwesomeIcon icon={faTimes} /> */}
  //     //   </span>
  //     //   <span className="pr-4"></span>
  //     // </button>
  //     <Button color="primary">Offer Accepted</Button>
  //   ),
  // ];
  useEffect(() => {
    const offerStatus = message.offer.status;
    // console.log({ offerStatus });
    if (message && message.isSent && offerStatus === "pending") {
      setButtonComponentIdx(0);
    } else {
      if (offerStatus === "pending") {
        setButtonComponentIdx(1);
      } else if (offerStatus == "accepted") {
        setButtonComponentIdx(2);
      } else if (offerStatus === "rejected") {
        setButtonComponentIdx(3);
      } else {
        setButtonComponentIdx(1);
      }
    }

    const startTimestamp = message?.offer?.startTime;
    const endTimestamp = message?.offer?.endTime;
    if (startTimestamp && endTimestamp) {
      const startDate = new Date(startTimestamp);
      const endDate = new Date(endTimestamp);
      const differenceInMs = endDate.getTime() - startDate.getTime();
      const daysDifference = differenceInMs / (1000 * 60 * 60 * 24);
      setDays(Math.floor(daysDifference));
    }
  }, [message]);
  return (
    <div>
      <>
        <div className="w-full">
          {message.isSent == false ? (
            <h2 className="text-md text-primary font-bold mb-4 text-center">
              You have received an offer from {message.senderType}
            </h2>
          ) : (
            ""
          )}
          <div className="w-full flex flex-wrap mb-5 justify-content-center">
            <div className="border border-gray-400 mt-1 mx-1 rounded-3xl p-3 px-4 text-primary text-xs font-semibold">
              {message?.offer?.service?.title}
            </div>
            <div className="border border-gray-400 mt-1 mx-1 rounded-3xl p-3 px-4 text-primary text-xs font-semibold">
              {message?.offer?.hourlyRate} $/hr for {days} days
            </div>
            <div className="border border-gray-400 mt-1 mx-1 rounded-3xl p-3 px-4 text-primary text-xs font-semibold">
              Total Price: ${message.offer.totalPrice}
            </div>
          </div>
          <div className="bg-gray-400 rounded-md bg-opacity-10  w-full p-3 d-flex flex-column align-items-center">
            <h4 className="font-semibold">Offer Description</h4>
            <p className="mt-2 text-sm">{message.offer.description}</p>
          </div>
          <div className="mt-3 d-flex justify-content-center">
            {/* {componentMapping[buttonComponentIdx]()} */}
            {message?.offer?.status == "pending" ||
            message?.offer?.status == "accepted" ||
            message?.offer?.status == "rejected" ? (
              <div className="flex justify-center mt-4">
                <Badge color="primary" className="mx-2 text-capitalize">
                  {message?.offer?.status === "pending"
                    ? "Offer Sent"
                    : message?.offer?.status}
                </Badge>
              </div>
            ) : (
              <div className="flex justify-center mt-4">
                <Badge color="success" className="mx-2">
                  Accept Offer
                </Badge>
                <Badge color="danger"> Reject Offer</Badge>
              </div>
            )}
          </div>
        </div>
        <div className="mt-4 float-right">
          <small>{formatDate(message?.createdAt)}</small>
        </div>
      </>
    </div>
  );
};
export default ChatOfferCard;

import { useDispatch, useSelector } from "react-redux";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { getSubCategoriesAction } from "store/actions/categoriesAction";
import { deleteSubCategory } from "store/actions/categoriesAction";

const DeleteSubCategoryModal = ({ modal, toggle, id, categoryId }) => {
  const dispatch = useDispatch();
  const { deleteSubCategoryLoader } = useSelector((state) => state.category);

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader toggle={toggle}>Delete Sub Category</ModalHeader>
        <ModalBody>Are you sure you want to delete ?</ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={() => toggle()}>
            Cancel
          </Button>
          <Button
            color="danger"
            disabled={deleteSubCategoryLoader}
            onClick={() => {
              dispatch(
                deleteSubCategory(id, () => {
                  dispatch(getSubCategoriesAction(categoryId));
                  toggle();
                })
              );
            }}
          >
            Delete
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default DeleteSubCategoryModal;

import {
  Button,
  Card,
  CardBody,
  Modal,
  ModalBody,
  ModalHeader,
} from "reactstrap";

const CheckrReportDocumentsModal = ({ modal, toggle, data }) => {
  const handleViewDocument = (url) => {
    window.open(url, "_blank");
  };

  return (
    <div>
      <Modal isOpen={modal} toggle={toggle} centered>
        <ModalHeader tag="h4" toggle={toggle}>
          Checkr Report Documents List
        </ModalHeader>
        <ModalBody>
          {data && data.length > 0 ? (
            data?.map((document, index) => (
              <Card key={index} outline className="my-2" color="primary">
                <CardBody>
                  <div className="d-flex align-items-center justify-content-between">
                    <h3 className="m-0">Document {index + 1}</h3>
                    <Button
                      size="sm"
                      color="primary"
                      onClick={() => handleViewDocument(document)}
                    >
                      View Document
                    </Button>
                  </div>
                </CardBody>
              </Card>
            ))
          ) : (
            <Card>
              <CardBody>
                <div className="d-flex align-items-center justify-content-center">
                  <p className="m-0">No documents found</p>
                </div>
              </CardBody>
            </Card>
          )}
        </ModalBody>
      </Modal>
    </div>
  );
};

export default CheckrReportDocumentsModal;

import OnlyHeader from "components/Headers/OnlyHeader";
import CreateAdminModal from "components/Modals/adminsModals/CreateAdminModal";
import UpdateAdminModal from "components/Modals/adminsModals/UpdateAdminModal";
import { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import {
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { adminSearchByName } from "store/actions/adminsAction";
import { updateAdmin } from "store/actions/adminsAction";
import { getAdmins } from "store/actions/adminsAction";
const Admins = () => {
  const dispatch = useDispatch();
  const { user } = useSelector((state) => state.auth);
  const { admins, totalPages, getAdminLoader } = useSelector(
    (state) => state.admins
  );
  //ADD MODAL
  const [addModal, setAddModal] = useState(false);
  //UPDATE MODAL
  const [updateModal, setUpdateModal] = useState(false);
  const updateAdminModal = () => setUpdateModal(!updateModal);
  const [editData, setEditData] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [adminByName, setAdminByName] = useState("");

  const itemsPerPage = 10;

  const handleOnSumbit = () => {
    dispatch(adminSearchByName(adminByName, currentPage));
  };

  const handleClearSearch = () => {
    setAdminByName("");
    dispatch(getAdmins(currentPage, itemsPerPage));
  };

  const addAdminModal = () => {
    setAddModal(!addModal);
  };

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalPages) {
      setCurrentPage(newPage);
    }
  };

  // format the date and time
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };

  const handleStatus = (status, id) => {
    const user = {
      status: `${!status}`,
    };
    dispatch(
      updateAdmin(id, user, () => {
        toast.success(!status ? "Admin Enabled!" : "Admin Disabled!");
        dispatch(getAdmins(currentPage, itemsPerPage));
      })
    );
  };

  useEffect(() => {
    dispatch(getAdmins(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <>
      <CreateAdminModal
        modal={addModal}
        toggle={addAdminModal}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />

      <UpdateAdminModal
        modal={updateModal}
        toggle={updateAdminModal}
        data={editData}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
      />
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Admins</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="AdminSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={adminByName}
                          onChange={(e) => setAdminByName(e.target.value)}
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Name
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        disabled={getAdminLoader}
                        onClick={handleOnSumbit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        disabled={getAdminLoader}
                        onClick={handleClearSearch}
                        size="sm"
                      >
                        Clear Search
                      </Button>

                      {user?.role === "resolutionTeam" ? (
                        ""
                      ) : (
                        <Button
                          color="primary"
                          className="p-2 mx-2 my-2 my-lg-0"
                          size="sm"
                          onClick={addAdminModal}
                        >
                          Create
                        </Button>
                      )}
                    </div>
                  </Col>
                </Row>
              </CardHeader>

              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">First Name</th>
                    <th scope="col">Last Name</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Updated At</th>
                    <th scope="col">Email</th>
                    {user?.role === "resolutionTeam" ? (
                      ""
                    ) : (
                      <th scope="col">Actions</th>
                    )}
                  </tr>
                </thead>

                {getAdminLoader === false ? (
                  <tbody>
                    {admins && admins.length > 0 ? (
                      admins?.map((admin, index) => (
                        <tr key={index}>
                          <td>{admin?.firstName}</td>
                          <td>{admin?.lastName}</td>
                          <td>{formatTimestamp(admin?.createdAt)}</td>
                          <td>{formatTimestamp(admin?.updatedAt)}</td>
                          <td>{admin?.email}</td>
                          {user?.role === "resolutionTeam" ? (
                            ""
                          ) : (
                            <td>
                              <Button color="primary" size="sm">
                                <i
                                  className="fa fa-edit"
                                  onClick={() => {
                                    updateAdminModal();
                                    setEditData(admin);
                                  }}
                                ></i>
                              </Button>
                              <Button
                                color={admin?.status ? "success" : "danger"}
                                size="sm"
                                onClick={() =>
                                  handleStatus(admin?.status, admin?.id)
                                }
                              >
                                {admin?.status ? "Enabled" : "Disabled"}{" "}
                                <i
                                  className={`fa fa-${
                                    admin?.status
                                      ? "check-circle"
                                      : "times-circle"
                                  }`}
                                />
                              </Button>
                            </td>
                          )}
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <p className="mb-0 text-sm">No Admin Found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>

              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <ReactPaginate
                      previousLabel={"<"}
                      nextLabel={">"}
                      breakLabel={"..."}
                      breakClassName={"break-me"}
                      pageCount={totalPages}
                      marginPagesDisplayed={1}
                      pageRangeDisplayed={2}
                      onPageChange={handlePageChange}
                      containerClassName={
                        "pagination px-1 justify-content-end mb-0"
                      }
                      activeClassName={"active"}
                      pageClassName={"page-item"}
                      pageLinkClassName={"page-link"}
                      previousClassName={"page-item"}
                      nextClassName={"page-item"}
                      previousLinkClassName={"page-link"}
                      nextLinkClassName={"page-link"}
                    />
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
};

export default Admins;

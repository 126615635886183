const initState = {
  allCertification: [],
  allCovidCertification: [],
  getCertificationLoader: false,
  totalPages: null,
};

const certificationReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_CERTIFICATION":
      return {
        ...state,
        allCertification: payload.data,
      };
    case "GET_COVID_CERTIFICATION":
      return {
        ...state,
        allCovidCertification: payload.data,
        totalPages: payload.totalPages,
      };
    case "COVID_CERTIFICATION_SEARCH": {
      return {
        ...state,
        allCovidCertification: payload?.data || [],
        totalPages: payload?.totalPages || 1,
      };
    }
    case "GET_CERTIFICATION_LOADER":
      return {
        ...state,
        getCertificationLoader: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default certificationReducer;

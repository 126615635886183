import Repository from "./Repository";
const SEND_MESSAGE = "/chat/send-admin-message";
const GET_CONVERSATION = "/chat/admin-conversations";
const GET_CHAT_MESSAGES = "/chat/admin-messages";
export default {
  sendMessage(payload) {
    return Repository.post(`${SEND_MESSAGE}`, payload);
  },
  getConversation() {
    return Repository.get(`${GET_CONVERSATION}`);
  },
  getChatMessages(detail) {
    console.log("det", detail);
    return Repository.get(
      `${GET_CHAT_MESSAGES}/${detail.id}?limit=10&page=${detail.page}`
    );
  },
  unreadMessages(detail) {
    return Repository.get(`${GET_CONVERSATION}/${detail.id}`);
  },
};

import { baseURL } from "repository/Repository";
import { io } from "socket.io-client";

export let socket = io(baseURL, {
  autoConnect: false,
});
export function addUserSocket(userId, role) {
  console.log(userId, role, "addUserConnection");
  socket.auth = { userId, role };
  socket.connect();
  socket.emit("addUserSocket", userId, role);
}
export function sendMessageSocket(payload) {
  console.log("send message??>>>>>>", payload);
  socket.emit("sendMessage", payload);
}
export function pageRefresh(userId, role) {
  console.log("pageReferech");
  socket.auth = { userId, role };
  socket.connect();
  socket.emit("pageRefresh", userId, role);
}
export function getMessage() {
  console.log("in get message socket");
  socket.on("getMessage", function (msg) {
    console.log(msg);
  });
}
export function disconnectSocket() {
  socket.emit("disconnectSocket");
}

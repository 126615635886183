import Repository from "./Repository";
const GET_ALL_HELP_ARTICLES = "/article";
const GET_SINGLE_HELP_ARTICLE = "/article/";
const ADD_ALL_HELP_ARTICLES = "/article/";
const UPDATE_ALL_HELP_ARTICLES = "/article/";
const DELETE_ALL_HELP_ARTICLES = "/article/";
const SEARCH_HELP_ARTICLES_BY_TITLE = "/article/search";

export default {
  getHelpArticles(page, limit) {
    return Repository.get(
      `${GET_ALL_HELP_ARTICLES}?page=${page}&limit=${limit}`
    );
  },
  getSingleHelpArticle(id) {
    return Repository.get(`${GET_SINGLE_HELP_ARTICLE}/${id}`);
  },
  addHelpArticle(formData) {
    return Repository.post(`${ADD_ALL_HELP_ARTICLES}`, formData);
  },
  updateHelpArticle(id, formData) {
    console.log(formData, id);
    return Repository.put(`${UPDATE_ALL_HELP_ARTICLES}/${id}`, formData);
  },
  deleteHelpArticle(id) {
    return Repository.delete(`${DELETE_ALL_HELP_ARTICLES}/${id}`);
  },
  searchHelpArticleByTitle(title, page) {
    return Repository.post(
      `${SEARCH_HELP_ARTICLES_BY_TITLE}?page=${page}&limit=10`,
      {
        text: title,
      }
    );
  },
};

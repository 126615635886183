const initState = {
  stats: [],
  demographicInfo: [],
  countryRevenue: [],
  getCountryRevenueLoader: false,
  getDemographicInfoLoader: false,
  getStatsLoader: false,
  publicMessage: null,
  userEarnings: {},
  chartStats: {},
  userOrders: [],
  totalOrderPages: 0,
  monthOrders: [],
  monthTotalPages: 0,
  paymentLogs: [],
  totalLogsPages: 0,
};
const statsReducer = (state = initState, { type, payload }) => {
  switch (type) {
    case "GET_STATS":
      return {
        ...state,
        stats: payload,
      };
    case "GET_STATS_LOADER":
      return {
        ...state,
        getStatsLoader: payload,
      };
    case "GET_DEMOGRAPHIC_INFO":
      return {
        ...state,
        demographicInfo: payload,
      };
    case "GET_DEMOGRAPHIC_LOADER":
      return {
        ...state,
        getDemographicInfoLoader: payload,
      };
    case "GET_REVENUE_INFO":
      return {
        ...state,
        countryRevenue: payload,
      };
    case "GET_REVENUE_LOADER":
      return {
        ...state,
        getCountryRevenueLoader: payload,
      };
    case "PUBLIC_MESSAGE":
      return {
        ...state,
        publicMessage: payload,
      };
    case "DELETE_MESSAGE":
      return {
        ...state,
        publicMessage: null,
      };
    case "EARNINGS_STATS":
      return {
        ...state,
        userEarnings: payload,
      };
    case "CHARTS_STATS":
      return {
        ...state,
        chartStats: payload,
      };
    case "USER_ORDERS":
      return {
        ...state,
        userOrders: payload?.orders,
        totalOrderPages: payload?.totalPages,
      };
    case "CURRENT_MONTH_SALES":
      return {
        ...state,
        monthOrders: payload?.orders,
        monthTotalPages: payload?.totalPages,
      };
    case "PAYMENT_LOGS":
      return {
        ...state,
        paymentLogs: payload?.paymentLogs,
        totalLogsPages: payload?.totalPages,
      };
    case "SEARCH_PAYMENT_LOGS":
      return {
        ...state,
        paymentLogs: payload?.paymentLogs,
        totalLogsPages: payload?.totalPages,
      };
    case "PUBLIC_LOADER":
      return {
        ...state,
        pubicLoader: payload,
      };
    default:
      return {
        ...state,
      };
  }
};
export default statsReducer;

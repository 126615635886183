import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Badge,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  Pagination,
  PaginationItem,
  PaginationLink,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { JobSearchByNameAction } from "store/actions/deletedJobsAction";
import { getAllDeletedJobs } from "store/actions/deletedJobsAction";

const JobDeletion = () => {
  const dispatch = useDispatch();
  const { allJobs, totalPages, deleteJobLoader } = useSelector(
    (state) => state.deletedJobs
  );

  const [jobByName, setJobByName] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [searchExecuted, setSearchExecuted] = useState(false);
  const [singleJob, setSingleJob] = useState(null);
  const [modal, setModal] = useState(false);
  const itemsPerPage = 10;

  const handleOnSumbit = () => {
    dispatch(JobSearchByNameAction(jobByName));
    setSearchExecuted(true);
  };

  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };
  const handleClearSearch = () => {
    setJobByName("");
    dispatch(getAllDeletedJobs(currentPage, itemsPerPage));
    setSearchExecuted(false);
  };

  // format the date and time
  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);

    const formattedDate = date.toLocaleString("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
      timeZone: "UTC",
    });

    return formattedDate;
  };
  const toggleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    dispatch(getAllDeletedJobs(currentPage, itemsPerPage));
  }, [dispatch, currentPage, itemsPerPage]);

  return (
    <>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Deleted Jobs</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="ResolutionSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={jobByName}
                          onChange={(e) => setJobByName(e.target.value)}
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Email & Reason
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        onClick={handleOnSumbit}
                        disabled={deleteJobLoader || !jobByName.trim()}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        onClick={handleClearSearch}
                        disabled={deleteJobLoader || !searchExecuted}
                        size="sm"
                      >
                        Clear Search
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <Table className="align-items-center table-flush" responsive>
                <thead className="thead-light">
                  <tr>
                    <th scope="col">Email</th>
                    <th scope="col">Job Title</th>
                    <th scope="col">Created At</th>
                    <th scope="col">Deleted At</th>
                    <th scope="col">Reason</th>
                    <th scope="col">Detail</th>
                  </tr>
                </thead>
                {!deleteJobLoader ? (
                  <tbody>
                    {allJobs && allJobs.length > 0 ? (
                      allJobs?.map((job, index) => (
                        <tr key={index}>
                          <td>{job?.email}</td>
                          <td className="truncate">{job?.title}</td>
                          <td>
                            {job?.jobCreatedAt
                              ? formatTimestamp(job?.jobCreatedAt)
                              : ""}
                          </td>
                          <td>{formatTimestamp(job?.createdAt)}</td>
                          <td>
                            <div className="truncate">{job?.reason}</div>
                          </td>
                          <td>
                            <Button
                              size="sm"
                              color="primary"
                              onClick={() => {
                                toggleModal();
                                setSingleJob(job);
                              }}
                            >
                              View
                            </Button>
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="6" style={{ textAlign: "center" }}>
                          <p className="mb-0 text-sm">No deleted job found.</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan="12" style={{ textAlign: "center" }}>
                        <Spinner
                          size="lg"
                          style={{ display: "block", margin: "0 auto" }}
                        />
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
              {totalPages > 0 && (
                <CardFooter className="py-4">
                  <nav aria-label="...">
                    <Pagination className="pagination justify-content-end mb-0">
                      <div className="d-flex mb-0">
                        <PaginationItem>
                          <PaginationLink
                            previous
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                          />
                        </PaginationItem>
                        {Array.from(
                          {
                            length: totalPages,
                          },
                          (_, i) => (
                            <PaginationItem
                              key={i}
                              active={i + 1 === currentPage}
                            >
                              <PaginationLink
                                onClick={() => handlePageChange(i + 1)}
                              >
                                {i + 1}
                              </PaginationLink>
                            </PaginationItem>
                          )
                        )}
                        <PaginationItem>
                          <PaginationLink
                            next
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                          />
                        </PaginationItem>
                      </div>
                    </Pagination>
                  </nav>
                </CardFooter>
              )}
            </Card>
          </div>
        </Row>
      </Container>
      <Modal isOpen={modal} toggle={toggleModal} centered>
        <ModalHeader toggle={toggleModal}>
          <h4 className="mb-0">Detail</h4>
        </ModalHeader>
        <ModalBody>
          <Row>
            <Col md="8" className="m-auto">
              <Label className="font-weight-bold">Job Title</Label>
              <p>{singleJob?.title}</p>
            </Col>
            <Col md="8" className="m-auto">
              <Label className="font-weight-bold">Reason to delete</Label>
              <p>{singleJob?.reason}</p>
            </Col>
          </Row>
        </ModalBody>
      </Modal>
    </>
  );
};

export default JobDeletion;

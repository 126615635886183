import { toast } from "react-toastify";
import { RepositoryFactory } from "repository/RepositoryFactory";

const nameRequest = RepositoryFactory.get("nameRequests");

export const getAllNameRequests = (page, limit) => async (dispatch) => {
  try {
    dispatch({
      type: "GET_NAME_REQUEST_LOADER",
      payload: true,
    });
    const { data } = await nameRequest.getNameRequests(page, limit);
    dispatch({
      type: "GET_ALL_NAME_REQUESTS",
      payload: data,
    });
    dispatch({
      type: "GET_NAME_REQUEST_LOADER",
      payload: false,
    });
  } catch (err) {
    dispatch({
      type: "GET_NAME_REQUEST_LOADER",
      payload: false,
    });
    toast.error(err.response.data.message);
  }
};

export const updateNameRequest =
  (id, status, onSuccess = () => {}) =>
  async (dispatch) => {
    dispatch({
      type: "UPDATE_NAME_REQUEST_LOADER",
      payload: true,
    });
    try {
      let payload = {
        status: status,
      };
      const { data } = await nameRequest.nameRequestDecision(id, payload);
      if (data?.success) {
        toast.success("Status updated successfully");
        dispatch({
          type: "UPDATE_NAME_REQUEST",
        });
      }
      dispatch({
        type: "UPDATE_NAME_REQUEST_LOADER",
        payload: false,
      });
      onSuccess();
    } catch (err) {
      dispatch({
        type: "UPDATE_NAME_REQUEST_LOADER",
        payload: false,
      });
      toast.error(err.response.data.message);
    }
  };

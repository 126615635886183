import { formatDate } from "Contants";
import OnlyHeader from "components/Headers/OnlyHeader";
import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Col,
  Container,
  FormGroup,
  Input,
  Row,
  Spinner,
  Table,
} from "reactstrap";
import { searchPaymentLogs } from "store/actions/dashboardActions";
import { paymentLogsAction } from "store/actions/dashboardActions";

const Payments = () => {
  const dispatch = useDispatch();
  const { paymentLogs, totalLogsPages, getStatsLoader } = useSelector(
    (state) => state.dashboard
  );
  const [currentPage, setCurrentPage] = useState(1);
  const [searchPayment, setSearchPayment] = useState("");
  const [searchExecuted, setSearchExecuted] = useState(false);

  const handlePageChange = ({ selected }) => {
    const newPage = selected + 1;
    if (newPage >= 1 && newPage <= totalLogsPages) {
      setCurrentPage(newPage);
    }
  };
  const handleClearSearch = () => {
    setSearchPayment("");
    setCurrentPage(1);
    dispatch(paymentLogsAction(currentPage));
    setSearchExecuted(false);
  };
  const handleOnSubmit = () => {
    setCurrentPage(1);
    dispatch(searchPaymentLogs(searchPayment, currentPage));
    setSearchExecuted(true);
  };
  useEffect(() => {
    dispatch(paymentLogsAction(currentPage));
  }, [dispatch, currentPage]);

  return (
    <div>
      <OnlyHeader />
      <Container className="mt--7" fluid>
        <Row className="mb-5">
          <div className="col">
            <Card className="shadow">
              <CardHeader className="border-0">
                <Row className="d-flex flex-column justify-content-between align-items-center flex-xl-row">
                  <Col className="col-12 col-lg-4">
                    <h3 className="text-center text-xl-left">Payment Logs</h3>
                  </Col>
                  <Col className="d-flex flex-column flex-lg-row justify-content-around align-items-center align-items-lg-start">
                    <div className="w-100 w-sm-50">
                      <FormGroup className="mb-0 w-100">
                        <Input
                          id="userSearch"
                          name="Search"
                          placeholder="Search Here..."
                          type="text"
                          value={searchPayment}
                          onChange={(e) => setSearchPayment(e.target.value)}
                          // bsSize="sm"
                          // className="py-3"
                        />
                      </FormGroup>
                      <small className="text-xs text-primary">
                        Search by Order ID & User ID
                      </small>
                    </div>
                    <div>
                      <Button
                        color="primary"
                        className="px-3 py-2 mx-2 my-2 my-lg-0"
                        disabled={getStatsLoader || !searchPayment.trim()}
                        onClick={handleOnSubmit}
                        size="sm"
                      >
                        Search
                      </Button>
                      <Button
                        color="primary"
                        className="p-2 mx-2 my-2 my-lg-0"
                        disabled={getStatsLoader || !searchExecuted}
                        onClick={handleClearSearch}
                        size="sm"
                      >
                        Clear Search
                      </Button>
                    </div>
                  </Col>
                </Row>
              </CardHeader>
              <CardBody className="py-0">
                {!getStatsLoader ? (
                  <Table borderless responsive>
                    <thead>
                      <tr>
                        <th>Date</th>
                        <th>Order ID</th>
                        <th>Buyer</th>
                        <th>Seller</th>
                        <th>Payment Type</th>
                        <th>Amount</th>
                      </tr>
                    </thead>
                    <tbody>
                      {paymentLogs && paymentLogs.length > 0 ? (
                        paymentLogs?.map((item, index) => {
                          return (
                            <tr key={index}>
                              <td>{formatDate(item?.createdAt)}</td>
                              <td>{item?.orderId?.orderId}</td>
                              <td>
                                {item?.buyer !== null
                                  ? item?.buyer?.firstName +
                                    " " +
                                    item?.buyer?.lastName
                                  : ""}
                              </td>
                              <td>
                                {item?.seller !== null
                                  ? item?.seller?.firstName +
                                    " " +
                                    item?.seller?.lastName
                                  : ""}
                              </td>
                              <td className="text-capitalize">{item?.type}</td>
                              <td>{item?.amount}</td>
                            </tr>
                          );
                        })
                      ) : (
                        <tr>
                          <td colSpan="6" style={{ textAlign: "center" }}>
                            <p className="mb-0 text-sm">No Payments Found.</p>
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                ) : (
                  <Row className="justify-content-center">
                    <Spinner size="sm" />
                  </Row>
                )}
              </CardBody>
              <CardFooter className="py-4">
                <nav aria-label="...">
                  <ReactPaginate
                    previousLabel={"<"}
                    nextLabel={">"}
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    pageCount={totalLogsPages}
                    marginPagesDisplayed={1}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageChange}
                    containerClassName={
                      "pagination px-1 justify-content-end mb-0"
                    }
                    activeClassName={"active"}
                    pageClassName={"page-item"}
                    pageLinkClassName={"page-link"}
                    previousClassName={"page-item"}
                    nextClassName={"page-item"}
                    previousLinkClassName={"page-link"}
                    nextLinkClassName={"page-link"}
                  />
                </nav>
              </CardFooter>
            </Card>
          </div>
        </Row>
      </Container>
    </div>
  );
};

export default Payments;
